import React, { useState, useEffect } from 'react';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Row, Col } from 'reactstrap'
import './ganttStyles.css'
import moment from 'moment'
import Select from 'react-select';
import { confs } from '../../../../bootstrap/config';
import { NotificationManager } from 'react-notifications';

let baseUrl = confs.url

const orderOptions = [
    {label:'Muokattu', value: 'modified'},
    {label:'Alku pvm', value: 'startDate'},
    {label:'Loppu pvm', value: 'endDate'},
    {label:'Nimi', value: 'name'},
]

// TODO - sort order projects by start or name

export const GanttBlock = ({ item,  planId, locals}) => {
    const [phaseList, setPhaseList] = useState([])
    const [phases, setPhases] = useState([])
    const [selectedView, setSelectedView] = useState(locals.ganttViewOptions[1])
    const [search, setSearch] = useState("")
    const [selectedOrder, setSelectedOrder] = useState({by:'start', ascending:false})
    const [calculatedHeight, setCalculatedHeight] = useState("500px")
    const [filteredPhases, setFilteredPhases] = useState([])
    const [visibility, setVisibility] = useState({})
    const [height, setHeight] = useState(calculatedHeight)
    const [showTags, setShowTags] = useState(false)

    useEffect(()=>{
        if(item.phases){
            setPhases([...item.phases])
        }
    },[item])

    useEffect(()=>{
       calculateHeight() 
    },[phases, filteredPhases])

    useEffect(()=>{
        if(phases.length > 0){
            handleData(phases)
        }
    },[phases])

    const handleData = (list) => {
        let array = []
        list.map((phase) => {
            let object = {
                ...phase,
                start: new Date(phase.start),
                end: new Date(phase.end)
            }
            // delete object.tasks
            array = [...array, object]

            if(phase && phase.tasks && phase.tasks.length > 0){
                let tasks = phase.tasks
                tasks.map((task)=>{
                    task = {
                        ...task,
                        start: moment(task.start).toDate(),
                        end: moment(task.end).toDate()
                    }
                    array = [...array, task]
                })
            }
        })
        setPhaseList([...array]);
    }

    let date = new Date()
    date.setDate(date.getDate() - 3);

    const handleFilter = (phase) => {
        const {tags, name} = phase

        if(name.toUpperCase().includes(search.toUpperCase())) return true

        if(tags !== undefined && tags.length > 0){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }
        
    }

    useEffect(()=>{
        if(phases){
            let visibilitySettings = visibility
            let array = [...phases]
            array.map((phase,key)=>{
                if(phase.type === 'project'){
                    visibilitySettings = {
                        ...visibilitySettings,
                        [phase.id]: false
                    }
                }
            })
            setVisibility(visibilitySettings)
        }
    },[])

    const filterVisibility = () => {       
        if(phaseList){
            let array = phaseList.filter((phase, index) =>{
                if(phase.type === "project" || (visibility[phase.project])){
                    return true
                } else {
                    return false
                }
            })
            setFilteredPhases([...array])
        }
    }

    useEffect(()=>{
        filterVisibility()
    },[phases, phaseList, visibility])

    const customTaskTooltip = (props) => {
        const {task} = props
        if(task){
            let start = moment(task.start).toDate()
            let end = moment(task.end).toDate()
            let startDate = start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
            let endDate = end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
            return(
                <div className="_3T42e">
                    <div className='_29NTg' style={{ fontSize:'1em'}} ><b>{task.name}</b></div>
                    <div className='_29NTg'>{task.targetBusiness}</div>
                    <div className='_29NTg'>{task.description}</div>
                    <div className='_29NTg' style={{ fontSize:'0.9em'}} ><b>{startDate +"-" + endDate}</b></div>
                    <div style={{ display:'flex' }}>
                        {task.tags && task.tags.length > 0 && task.tags.map((tag,key)=>{
                            return(
                                <p key={key} className="tag-display tag-text project-lighter-color">{tag.label}</p>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    const orderBy = (by) => {
        let array = [...phaseList]        
        if(selectedOrder.by === by){
            if(by === "name"){
                if(selectedOrder.ascending){
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA > nameB) {
                          return -1;
                        }
                        if (nameA < nameB) {
                          return 1;
                        }
                        return 0;
                    })
                } else {
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                    })
                }
            } else {
                if(selectedOrder.ascending){
                    array.sort((a,b) => a[by] - b[by])
                } else {
                    array.sort((a,b) => b[by] - a[by])
                }
            }
             setSelectedOrder({by: by, ascending: !selectedOrder.ascending})
        } else {
            if(by === "name"){               
                array.sort((a,b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                })
                setSelectedOrder({by: by, ascending: false})
            } else {
                array.sort((a,b) => b[by] - a[by])
                setSelectedOrder({by: by, ascending: true})
            }
            
        }
        // setPhaseList([...array])
    }

    const handleCheckItemChange = (task,index) => {
        let list = [...phases]
        let array = list.map((phase, index)=>{
            if(phase.id === task.project){
                let tasks = phase.tasks

                let taskList = tasks.map((obj)=>{
                    if(obj.id === task.id){
                        obj = {
                            ...obj,
                            checked:!obj.checked,
                            progress:!obj.checked === true? 100:0,
                            styles: { progressColor: !obj.checked === true?'#acd811' : '#aeb8c2', progressSelectedColor: !obj.checked === true?'#acd811' : '#aeb8c2', backgroundSelectedColor: !obj.checked === true?'#acd811' : '#aeb8c2' },
                        }
                    }
                    return obj
                })
                phase = {
                    ...phase,
                    tasks:[...taskList]
                }
                
               
            }
            return phase 
        })
        let progress = calculateProgress(task, [...array])
        saveCheckItemChange(progress)
    }

    const updatePhases = (list) => {
        setPhases([...list])
    }

    const toggleChildrenVisibility = (task, index) => {
        if(task && task.type){
            if(task.type==="project"){
                let obj = {
                    ...visibility,
                    [task.id]: !visibility[task.id]
                }
                setVisibility(obj)
            }
        }
    }

    const calculateProgress = (task, list) => {

        let array = [...list.map((phase)=>{
            if(phase.id === task.project){
                let progress = 0
                let count = 0
                let tasks = phase.tasks
                tasks.map((obj)=>{
                    progress += obj.progress
                    count++
                })
           
                phase = {
                    ...phase,
                    progress: (progress/count)
                } 
            }
            return phase
        })]
        setPhases([...array])
        return array
    }

    const tagsToMore = (tags, slicer) => {
        var tagList = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagList = tagList + "  " + t.label.toString())
            return(tagList)
        }
    }
 
    const customTable = (props) => {
        const {headerHeight, fontFamily, fontSize, rowHeight, tasks} = props
        const weekDayFin = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
        const weekDayEng = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        let weekDay = weekDayEng
        if(locals.getLanguage() === "fi"){
            weekDay = weekDayFin
        } else {
            weekDay = weekDayEng
        }
        return(
            <div style={{border:'1px solid #e6e4e4 ', marginRight:'0em', marginLeft:'-2px'}}>
                {tasks && tasks.map((task, index) => {
                    let oddEvenRow = index%2 === 0 ? 'even' : 'odd'
                    let start = moment(task.start).toDate()
                    let end = moment(task.end).toDate()
                    let startDate = weekDay[start.getDay()] + " " + start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
                    let endDate = weekDay[end.getDay()] + " " + end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
                    return(
                        <div key={index} className={'gantt-row-styles gantt-row-'+oddEvenRow} style={{ height: (rowHeight), display:'flex' , paddingLeft:'1.3em', cursor:'pointer'}} onClick={() => (task.type==="project"? toggleChildrenVisibility(task, index) : "")}>
                            {task.type !== 'project' && 
                                <>
                                    {(item.allowChecking !== undefined && item.allowChecking) ?                                   
                                        <div style={{width:'3em', fontSize:'0.95rem', marginLeft:'', display:'flex', flexDirection:'column', textOverflow:'ellipsis', overflow:'hidden', wordBreak:'keep-all', marginTop:'0.5em', whiteSpace:'nowrap'}} title={task.name}>
                                            <input type="checkbox" className="check-item-box" onTouchEnd={() => handleCheckItemChange(task, index)} onChange={() => handleCheckItemChange(task, index)} checked={task.checked}/>
                                        </div>
                                        :
                                        <div style={{width:'3em', fontSize:'0.95rem', marginLeft:'', display:'flex', flexDirection:'column', textOverflow:'ellipsis', overflow:'hidden', wordBreak:'keep-all', marginTop:'0.5em', whiteSpace:'nowrap'}} title={task.name}>
                                            <input type="checkbox" className="check-item-box" readOnly={true} checked={task.checked}/>
                                        </div>
                                    }
                                </>
                            }
                            {/* <div  style={{marginLeft:'-1.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={task.type !== 'project'?  {width: '10em', fontSize: fontSize , marginLeft:'0.5em', display:'flex', marginTop:'0.25em', fontWeight: 'normal' } : {width: '15em', fontSize: '1rem' , marginLeft:'0em', display:'flex',marginTop:'0.4em' , fontWeight: 'bold', marginRight:'-0.75em'}} title={task.name}>
                                <p style={{fontSize:'0.85rem', lineHeight: '20px', marginTop:(task.type !== 'project'? "0.25em":"")}}>{task.name}</p>
                                {task.type === 'project' && <div style={{marginRight:'2em', marginLeft:'auto', fontSize:'0.8rem', marginTop:'0.15em'}}>{"(" + task.tasks.length+ ")"}</div>}
                            </div>
                            {/* <div  style={{marginLeft:'-0.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={{width:'7em', fontSize:fontSize, marginLeft:(item.allowChecking !== undefined && item.allowChecking) ? "" : (task.type !== 'project'?'2.5em': '')  , display:'flex', marginTop:'0.3em'}}>
                            {startDate} - {endDate}
                            </div>
                            {/* <div  style={{marginLeft:'-0.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            {showTags &&
                                <div  style={{marginLeft:'0.5em', marginTop: '0.5em', marginBottom:'0.5em', display:'flex', width:'7em', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    {task.tags && task.tags.slice(0, 3).map((tag, i) => {                        
                                        return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><div key={i} className="tag-display tag-text" title={tag.label}>{tag.label}</div></div>)
                                    })}                                                       
                                    {task.tags && task.tags.length > 3 &&  
                                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                            <i className="material-icons" title={tagsToMore(task.tags,3)}>more_horiz</i>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    const customHeader = (props) => {
        const {headerHeight, fontFamily, fontSize, rowWidth} = props
        return(
            <div className='_3_ygE' style={{ height: (headerHeight-0.2), display:'flex', backgroundColor:'#fff', borderRadius:'10px 0 0 0', borderRight:'2px solid #f5f5f5', marginLeft:'-1px', marginTop:''}}>
                <div style={{width:'17em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'1.25em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("start")}>
                    {locals.ganttName}
                    {/* <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color: (selectedOrder && selectedOrder.by === "name" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "name" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div> */}
                </div>
                <div  style={{marginLeft:'-1em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("end")}>
                    {locals.ganttStartDate} - {locals.ganttEndDate}
                    {/* <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color:(selectedOrder && selectedOrder.by === "start" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "start" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div> */}
                </div>
                {showTags &&
                    <>
                        <div  style={{marginLeft:'0em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                        <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} >
                            {locals.tags}
                        </div>
                    </>
                }
            </div>
        )
    }

    const calculateHeight = () => {
        let value = "500px"
        if(phases){ 
            if(filteredPhases && filteredPhases.length > 0 && ((filteredPhases.length * 50) <= 500)){ 
                value = (filteredPhases.length * 50) + "px"
            } 
        }
        setCalculatedHeight(value)
    }

    const saveCheckItemChange = async(phaseList) => {
        let payload = {
            planId: planId,
            item:{
                ...item,
                phases: phaseList
            }
        }
        try {
            const responseJson = await fetch(baseUrl + 'ganttItemCheck', {
                method: 'POST',
                body: JSON.stringify(payload)
            })

            if(responseJson) {
                const response = await responseJson.json()
                if (response.success){
                    NotificationManager.success(locals.planSaved,locals.success,5000)
                } else if(response.error && response.error === "No email error"){
                    NotificationManager.error(locals.noEmailError ,locals.error,10000)
                } else if(response.error && response.error === "notAllowed"){
                    NotificationManager.error(locals.notAllowedError ,locals.error,5000)
                }
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
      

    }

    return(
        <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <Col xs="12" style={{}} draggable={false}>
                <div style={{ display: 'flex', width:'100%', marginBottom:'0.25em'}}>                    
                    <div style={{marginLeft:'0em', marginTop:'0.5em'}}>
                        <form id="search-bar" className="gantt-search" style={{width:'12em'}}>
                            <input type="search" className="search-bar-input gantt-search" placeholder={locals.search} value={search} onChange={(event)=> setSearch(event.target.value)} />
                        </form>
                    </div>    
                    <div style={{ marginLeft:'auto', marginRight:'0', display:'flex'}}>
                        <div style={{marginRight:'0', marginLeft:'auto'}}>
                            <button className={"small-white-button " } style={{ padding: '0.2em 0.5em' }} title={locals.showHideTags} onClick={()=>setShowTags(!showTags)} ><i className="fas fa-tags"></i></button>
                        </div>
                        <div style={{marginRight:'0.5em', marginLeft:'auto'}}>
                            {height !== "85vh" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight("85vh")}><i className="fas fa-expand"></i></button>}
                            {height === "85vh" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight(calculatedHeight)}><i className="fas fa-compress"></i></button>}
                        </div>
                        <div style={{ width:'8em', marginTop:'-0.25em', marginLeft:'auto', marginRight:'0em'}}>
                            <Select
                                options={locals.ganttViewOptions}
                                onChange={(val)=> setSelectedView(val)}
                                value={selectedView}
                                isDisabled={false}
                                noOptionsMessage={() => { return "Ei asetuksia" }}
                                className="survey-selector gantt-select"
                                classNamePrefix="select"  
                            />
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor:'#fff', borderRadius:'10px', padding: '0 0.25em 0 0', border:'1px solid #e6e4e4 '}} >
                    {phases && filteredPhases.filter( task => handleFilter(task)).length > 0 &&
                        <Gantt 
                            tasks={filteredPhases.filter(task => handleFilter(task))}
                            viewMode={selectedView.value}
                            viewDate={date}
                            locale={locals.getLanguage() === 'en'? 'eng' : 'fin'}
                            ganttHeight={height === "85vh" ? height : calculatedHeight}
                            preStepsCount={2}
                            TooltipContent={customTaskTooltip}
                            todayColor={"#D811AC20"} 
                            projectBackgroundSelectedColor={'#D811AC'}  
                            TaskListHeader={customHeader} 
                            TaskListTable={customTable} 
                        />
                    }
                </div>
            </Col>
        </Row>
    )
}