import { setIndex } from '@amcharts/amcharts4/.internal/core/utils/Array';
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Label, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Annotation } from './Annotation';
import './annotation.css';
import { confs } from '../../../../bootstrap/config';
import { NotificationManager } from 'react-notifications';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';

let baseUrl = confs.url

const INITIAL_ANNOTATION = {
    color: "#ff6e9f",
    text: ""
}

const colorOptions = [
    "#ff6e9f",
    "#BA68C8",
    "#FFD54F",
    "#4FC3F7",
    "#B5E413"
]

export const AnnotationTool = ({annotationList = [], planId, locals, addModalOpen, setAddModalOpen}) => {
    // This tool will create annotations and give them all the required data / info
    const [annotations, setAnnotations] = useState(annotationList)
    const [newAnnotation, setNewAnnotation] = useState(INITIAL_ANNOTATION)
    const [activeColor, setActiveColor] = useState("#ff6e9f")
    const [deleteModalOpen, setDeleteModalOpen] = useState()
    const [selectedAnnotation, setSelectedAnnotation] = useState({})
    const [nameTag, setNameTag] = useState("")

    const handleChange = (change) => {
        const {id, value} = change
        setNewAnnotation(prevData => ({
            ...prevData,
            [id]:value
        }))
    }

    const addAnnotation = async() => {
        if(newAnnotation.text && newAnnotation.text.length > 0){
            let payload = {
                newAnnotation: newAnnotation,
                planId: planId,
                nameTag: nameTag
            }

            try {
                const responseJson = await fetch(baseUrl + 'newAnnotation', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                })
        
                if(responseJson) {
                    const response = await responseJson.json()
                               
                    if(response.annotations){
                        setAnnotations(response.annotations)
                        handleChange({id:"text", value:""})
                    }
                    if(response.notAllowed){
                        NotificationManager.error("Kommentointi ei sallittu / Commenting not allowed!", "" , 3000)
                    }
                } else {
                    return false;
                }
            } catch(error) {
                return Promise.reject(error);
            }
        }
    }

    const handleLocation = () => {

    }

    const handleDelete = (annotation) => {
        setSelectedAnnotation(annotation)
        setDeleteModalOpen(true)
    }

    const confirmDelete = async() => {
        let payload = {
            annotationId: selectedAnnotation.annotationId,
            planId:planId
        }

        try {
            const responseJson = await fetch(baseUrl + 'removeAnnotation', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                           
                if(response.annotations){
                    setAnnotations(response.annotations)
                    setDeleteModalOpen(false)
                }
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    return (
        <>
            <Modal isOpen={deleteModalOpen}>
                <ModalHeader>
                    {locals.removingCommentBanner}
                </ModalHeader>
                <ModalBody>
                    {locals.removingComment}
                </ModalBody>
                <ModalFooter>
                    <div style={{ minWidth: '100%' }}>
                        <Row>
                            <Col>
                                <button className="small-white-button" style={{ width: '100%', marginRight: '0.25rem' }} onClick={() => setDeleteModalOpen(false)}>{locals.back}</button>
                            </Col> 
                            <Col>
                                <button className="small-white-button" style={{ width: '100%', marginLeft: '0.25rem', backgroundColor: '#ed5652', color: '#fff' }} onClick={() => confirmDelete()}>{locals.delete}</button>
                            </Col>
                        </Row>
                    </div>
                </ModalFooter>
            </Modal>
            <div className="annotation-tool-icon"  onClick={() => setAddModalOpen(!addModalOpen)}>
                <i className="far fa-comment-dots " title="Lisää kommentti"></i>
            </div>
            {addModalOpen && 
                <div className="annotation-container">
                    <div className="editor-container">
                        <Col>
                            <Row>
                                <Col >
                                    <textarea 
                                        placeholder={locals.commentHere}
                                        rows={3}
                                        className="annotation-textarea"
                                        value={newAnnotation.text}
                                        onChange={(e) => handleChange({id:"text", value: e.target.value})}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5em'}}>
                                <Label style={{fontSize: '0.9rem', marginRight:'0.5em', marginTop:'0.5em'}}>{locals.nameTag + ": "}</Label>
                                <input type={"text"} style={{ fontSize:'0.9rem', height: '2em', marginTop:'0.25em' }} value={nameTag} onChange={(e)=> setNameTag(e.target.value)}/>
                            </Row>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col xs="8">
                                    <div className="color-picker">
                                        {colorOptions.map((option,key) => {
                                            return(
                                                <div key={key} className={"color-dot " + (activeColor === option? "active-color" : "")} onClick={() => (handleChange({id:"color", value: option}), setActiveColor(option))} style={{backgroundColor: option}}>{activeColor===option? <i className="fas fa-check"></i> : ""}</div>
                                            )
                                        })}
                                    </div> 
                                </Col>
                                <Col xs="4">
                                    <button className="small-white-button add-annotation-button" onClick={() => addAnnotation()}>{locals.addCommentButton}</button>
                                </Col>
                            </Row> 
                        </Col>
                    </div>
    
                    <div className="comment-section" style={{ marginTop: '1em'}}>
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: "30em", paddingBottom: '7em'}}
                            className="scrollbars-container"
                        >
                            <div className="scrollbar-inner-container" style={{marginBottom:'19px'}}>
                                {annotations.length > 0 && annotations.sort((a,b) => moment(b.created) - moment(a.created)).map((annotation, key) => {
                                    return(
                                        <Fragment key={key}>
                                            <Annotation 
                                                item={annotation}
                                                handleLocation={handleLocation}
                                                handleDelete={handleDelete}
                                            />
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </Scrollbars>
                    </div>   
                </div>    
            } 
        </>
    )
}