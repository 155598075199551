
// _light => dark bg, light text color
// _dark => light bg, dark text color

export const getTrelloColor = (val) => {
    switch(val){
        case "green_light":
            return {color: "#fff", backgroundColor: '#164b35'}
        case "green":
            return {color: "#fff", backgroundColor: '#216e4e'}
        case "green_dark":
            return {color: "#404040", backgroundColor: '#4bce97'}
        case "yellow_light":
            return {color: "#fff", backgroundColor: '#533f04'}
        case "yellow":
            return {color: "#fff", backgroundColor: '#7f5f01'}
        case "yellow_dark":
            return {color: "#404040", backgroundColor: '#e2b203'}
        case "orange_light":
            return {color: "#fff", backgroundColor: '#702e00'}
        case "orange":
            return {color: "#fff", backgroundColor: '#a54800'}
        case "orange_dark":
            return {color: "#404040", backgroundColor: '#fea362'}
        case "red_light":
            return {color: "#fff", backgroundColor: '#5d1f1a'}
        case "red":
            return {color: "#fff", backgroundColor: '#ae2e24'}
        case "red_dark":
            return {color: "#404040", backgroundColor: '#f87168'}
        case "purple_light":
            return {color: "#fff", backgroundColor: '#352c63'}
        case "purple":
            return {color: "#fff", backgroundColor: '#5e4db2'}
        case "purple_dark":
            return {color: "#404040", backgroundColor: '#9f8fef'}
        case "blue_light":
            return {color: "#fff", backgroundColor: '#09326c'}
        case "blue":
            return {color: "#fff", backgroundColor: '#0055cc'}
        case "blue_dark":
            return {color: "#404040", backgroundColor: '#579dff'}
        case "sky_light":
            return {color: "#fff", backgroundColor: '#164555'}
        case "sky":
            return {color: "#fff", backgroundColor: '#206a83'}
        case "sky_dark":
            return {color: "#404040", backgroundColor: '#6cc3e0'}
        case "lime_light":
            return {color: "#fff", backgroundColor: '#37471f'}
        case "lime":
            return {color: "#fff", backgroundColor: '#4c6b1f'}
        case "lime_dark":
            return {color: "#404040", backgroundColor: '#94c748'}
        case "pink_light":
            return {color: "#fff", backgroundColor: '#50253f'}
        case "pink":
            return {color: "#fff", backgroundColor: '#943d73'}
        case "pink_dark":
            return {color: "#404040", backgroundColor: '#e774bb'}
        case "black_light":
            return {color: "#fff", backgroundColor: '#454f59'}
        case "black":
            return {color: "#fff", backgroundColor: '#596773'}
        case "black_dark":
            return {color: "#404040", backgroundColor: '#8c9bab'}
        default:
            return {color: '#fff', backgroundColor: '#596773'}
    }
}