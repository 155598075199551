import React, {useState, useEffect} from 'react';
import {Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input, FormGroup, FormFeedback} from 'reactstrap';
import moment from 'moment';
import CodeInput from '../../../modules/EmailCode/CodeInput';
import { NotificationManager } from 'react-notifications';
import { confs } from '../../../../bootstrap/config';
import locals from '../../../../locals/locals';

let baseUrl = confs.url
let frontPage = confs.frontPage

export const SignatureBlock = ({item, planId}) => {
    const [signatures, setSignatures] = useState(item.signatures? item.signatures: [])
    const [signer, setSigner] = useState({})
    const [signModalOpen, setSignModalOpen] = useState(false)
    const [codeVerificationInProgress, setCodeVerificationInProgress] = useState(false)
    const [emailInputHasChanged, setEmailInputHasChanged] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [verifiedUserData, setVerifiedUserData] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    

    useEffect(()=> {
        if(item && item.signatures){
            setSignatures(item.signatures)
        }
    },[item])

    const startToSignPlan = (signer, index) => {
        setSigner(signer)
        setSelectedIndex(index)
        setSignModalOpen(true)
    }

    const signDocument = async(code) => {
        setCodeVerificationInProgress(true)
        let payload = {
            code: code,
            signer:signer,
            signatureId: item.signatureId,
            planId: planId,
            language: locals.language
        }
        
        try {
            const responseJson = await fetch(baseUrl + 'verifyCode', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                if(response.verified && response.signature){
                    signatures.splice(selectedIndex, 1, response.signature)
                    setCodeVerificationInProgress(false)
                    setSignModalOpen(false)
                    setVerifiedUserData(false)
                } else if(response.codeExpired) {
                    NotificationManager.error(locals.codeExpired,locals.error,5000)
                    setCodeVerificationInProgress(false)
                } else {
                    NotificationManager.error(locals.wrongVerificationCode,locals.error,10000)
                    setCodeVerificationInProgress(false)
                }
            } else {
                setVerifiedUserData(false)
                setCodeVerificationInProgress(false)
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }

        
    }

    const validateSigner = async(sendBackupCode = false) => {
       
        if(signer.name && signer.signerId){
            let payload = {
                signer: signer,
                planId: planId,
                signatureId: item.signatureId,
                title: locals.emailSignatureTitle,
                text: locals.emailSignatureTopic,
                sendBackupCode: sendBackupCode
            }

            try {
                const responseJson = await fetch(baseUrl + 'sendCode', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                })

                if(responseJson) {
                    const response = await responseJson.json()
                    if (response.success){
                        setVerifiedUserData(true)
                        NotificationManager.success(locals.codeSentToEmail,locals.success,5000)
                    } else if(response.error && response.error === "No email error"){
                        NotificationManager.error(locals.noEmailError ,locals.error,10000)
                    }
                } else {
                    return false;
                }
            } catch(error) {
                return Promise.reject(error);
            }
          
            
        }
    }

    const handleChanges = (target) => {
        const {id, value} = target

        setSigner(prevData => ({
            ...prevData,
            [id]:value
        }))
    }

    const handleEmailChange = (target) => {
        const email = target.value
        handleChanges(target)
        setEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let emailIsValid = false

        if (emailRegex.test(email)) {
            emailIsValid = true
        } else if (email === '') {
            setEmailInputHasChanged(false)
        }
        setEmailIsValid(emailIsValid)
    }

    let emailInputProps = emailInputHasChanged ? { valid: emailIsValid, invalid: !emailIsValid } : {}

    return (
        Object.keys(item).length > 0 ?
            <>
                <Modal size="lg" isOpen={signModalOpen} toggle={() => {return}}>
                    <ModalHeader toggle={() => (setSignModalOpen(!signModalOpen), setVerifiedUserData(false))}>
                        {locals.digitalSignature}
                    </ModalHeader>
                    <ModalBody>
                        {verifiedUserData ?
                            <div>
                                <p style={{ textAlign: 'center', paddingBottom: '1em' }}>{locals.signGuide}</p>
                                <CodeInput onSubmit={signDocument} codeVerificationInProgress={codeVerificationInProgress}/>
                                {!codeVerificationInProgress &&
                                    <>
                                        <p style={{ textAlign: 'center', paddingTop: '2em', fontSize: '0.8rem' }}>{locals.waitingForCode}</p>
                                        <p style={{ textAlign: 'center', paddingTop: '1em', cursor: 'pointer', fontWeight: 'bold', textDecoration:'underline' }} onClick={() => validateSigner(true)}>{locals.planCodeDidntArrive}</p>
                                    </>
                                } 
                           </div>
                            :
                            <Row>
                                <Col>
                                    <p style={{ textAlign: 'center' }}>{locals.checkInfo}</p>
                                    <div style={{ marginTop: '1em' }}>
                                        <Label>{locals.name + ":"}</Label>
                                        <p>{signer.name}</p>
                                    </div>
                                    <div style={{ marginTop: '1em' }}>
                                        <Label>{locals.business + ":"}</Label>
                                        <p>{signer.business}</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter style={{ flexWrap: 'nowrap'}}>
                        <button className="small-white-button lighter-text wide-button" style={{ width: '100%' }} onClick={() => (setSignModalOpen(false), setVerifiedUserData(false), setCodeVerificationInProgress(false))}>{locals.cancel}</button>
                        {!verifiedUserData &&
                            <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%' }} onClick={() => validateSigner()}>{locals.signInfoCorrect}</button>
                        }
                    </ModalFooter>
                </Modal>
                <Row className="plan-object" id={item.id}>
                    <Col style={{paddingTop: '0.8em'}} className="plan-text-col"> 
                        <Row id="signature-row">
                            {signatures.map((signer,key) => {
                                return(
                                    <Col sm="12" md="6" key={key} id={"signature-col"} style={{paddingBottom: '1em'}}>
                                        <Row >
                                            <Col>
                                                <p style={{ marginBottom: 0 }}>
                                                    {locals.date + ": " + (signer.signedDate ? moment.utc(signer.signedDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY'): "-")}
                                                </p>
                                                <p style={{ marginTop:'0.25em', marginBottom: 0 }}>{signer.business || ""}</p>
                                            </Col>
                                        </Row>
                                        <Row  className="sign-row">
                                            <Col>
                                                {signer.verified? 
                                                    <div className="signature-container underLine">
                                                        <p className="signed-name" style={{ marginBottom: 0 }}>{signer.name}</p>
                                                    </div>
                                                    :
                                                    <div className="signature-container">
                                                        <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%', fontSize: '1.05rem' }} onClick={() => startToSignPlan(signer, key)}>{locals.signPlan}</button>
                                                    </div> 
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '0.5em' }}>
                                            <Col>
                                                <p style={{ marginBottom: 0 }}>{signer.name}</p>
                                                <p style={{ marginBottom: 0 }}>{signer.email}</p>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '0.5em' }}>
                                            <Col>
                                                {signer.verified? 
                                                    <div style={{justifySelf:'flex-end'}}>
                                                        <p style={{margin:0}}>{locals.digitalSignature}</p>
                                                        {signer.signerCode}
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col>
                                <p>{locals.signatureIdentifier + ": " + item.signatureId }</p>
                                <p>{locals.signatureVerificationText} <span><a href={`${frontPage}verify/signature/${item.signatureId}`} target="_blank">{`${frontPage}verify/signature/${item.signatureId}`}</a></span></p> 
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        :
        null
    )

}