import {NotificationManager} from 'react-notifications';
import * as ActionTypes from './actionTypes';
import {confs} from '../../../bootstrap/config';

export const taskStatus = payload => {
    return {
        type: ActionTypes.TASK_STATUS,
        payload: payload
    }
};

const handleErrors = response => {
    if(!response.ok) {
        throw Error(response.statusText);
    }

    return response;
}

export const checkTaskStatus = payload =>  dispatch => {
    return fetch(confs.url + 'checkTask', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
      .then(handleErrors)
      .then(res => res.json())
      .then(data => {
        if(data.status === false) {
          dispatch(taskStatus(data.status));
        } else {
             dispatch(taskStatus(data.status));
        }
      }).catch(error => {
          NotificationManager.error("Valitettavasti tapahtumaa ei voitu suorittaa, yritä hetken päästä uudelleen", "", 2500);
        })
}

export const changeTaskStatus = payload => dispatch => {
    return fetch(confs.url + 'taskHandler', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
      .then(handleErrors)
      .then(res => res.json())
      .then(data => {
        if(data.status === false) {
          dispatch(taskStatus(data.status));
        } else {
            dispatch(taskStatus(data.status));
        }
      }).catch(error => {
        NotificationManager.error("Valitettavasti tapahtumaa ei voitu suorittaa, yritä hetken päästä uudelleen", "", 2500);
      })
}