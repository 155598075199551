import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import SummaryRow from './SummaryRow';
import {motion, AnimatePresence} from 'framer-motion';

const Summary = ({planItems, startScrolling, locals}) => {
    const [summaryOpen, setSummaryOpen] = useState(true);

    return (
        <Col xl="12" className="plan-sub-row summary" style={{paddingLeft: 0, paddingTop: '1.5em', marginBottom: '2em'}}>
            <h4 style={{cursor: 'pointer'}} className="summaryToggler" onClick={() => setSummaryOpen(!summaryOpen)}>{locals.summary} <i className={"ellipsis-style " + (summaryOpen ? "fas fa-ellipsis-h" : "fas fa-ellipsis-v")}></i></h4>
                
                {summaryOpen && planItems.map((item, index) =>
                    <SummaryRow item={item} index={index} key={item.id} summaryOpen={summaryOpen} startScrolling={startScrolling}/>
                )}
                
        </Col>
    )
}

export default Summary;