import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import * as ActionTypes from '../../store'
import './firstlogin.css'


class FirstLogin extends Component {
  constructor(props) {
    super(props)

    let toLogin = false

    if(props.match.params.userToken) {
      props.checkFirstLoginToken(props.match.params.userToken).then(() => {
        toLogin = !props.firstLoginTokenIsValid
      })
    }

    this.state = {
      email: '',
      password: '',
      passwordConf: '',
      toLogin: false,
      firstLoginTokenIsValid: toLogin
    }
  }

  onSubmit = () => {
    if(this.formIsValid) {
      const formData = {
        email: this.state.email,
        password: this.state.password,
        passwordConf: this.state.passwordConf,
        token: this.props.match.params.userToken
      }
      this.props.onSubmitFirstLogin(formData).then(resp => {
        if (resp.success === true){
          this.setState({toLogin: true})
        }
      })
    }
  }

  formIsValid() {
    //TODO email check here
    if(this.state.email === '') {
      NotificationManager.error(this.props.locals.emailRequired, this.props.locals.error, 3000)
      return false;
    }
    if(this.state.password !== this.state.passwordConf) { 
      NotificationManager.error(this.props.locals.passwordNotMatching, this.props.locals.error, 3000) 
      return false;
    }

  }

  render() {
    const {locals} = this.props
    return ( 
      <Container className="h-100" >
        <Row className="justify-content-center align-items-center h-100">
          <div className="col-md-6 login-box">
            <div className="login-inner-container">
              {this.state.toLogin && <Redirect to="/login" />}
              <Row className="justify-content-center align-items-center h-100">
                <Col xs="12" style={{paddingTop:''}}>
                  <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                  <h4>{locals.welcome}</h4>
                  <p style={{lineHeight: 1}}>{locals.confirmEmailPass}</p>
                </Col>
                <Col xs="12">
                  <div style={{marginTop:'0.5em'}}>
                    <label>{locals.email}</label>
                    <Input onChange={(e) => this.setState({email: e.target.value})} className="form-control form-control-lg" name="email" value={this.state.email} type="text"></Input>
                  </div>
                  <div style={{marginTop:'1em'}}>
                    <label>{locals.newPassword}</label>
                    <Input onChange={(e) => this.setState({password: e.target.value})} className="form-control form-control-lg" value={this.state.password} type="password"></Input>
                  </div>
                  <div style={{marginTop:'1em'}}>
                    <label>{locals.confirmPassword}</label>
                    <Input onChange={(e) => this.setState({passwordConf: e.target.value})} className="form-control form-control-lg" value={this.state.passwordConf} type="password"></Input>
                  </div>
                </Col> 
                <Col xs="12" style={{marginTop:'2em'}}>
                  <button onClick={this.onSubmit} className="small-white-button confirm-button" style={{ width:'100%' }}>{locals.logIn}</button>
                </Col>   
                
              
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    firstLoginTokenIsValid: state.user.firstLoginTokenIsValid,
    firstLoginSuccessful: state.user.firstLoginSuccessful
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmitFirstLogin: (formData) => dispatch(ActionTypes.firstLogin(formData)),
    checkFirstLoginToken: (token) => dispatch(ActionTypes.checkFirstLoginToken(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLogin)