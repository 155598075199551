import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import './signatureVerification.css';
import defaultLogo from '../../../../../../src/assets/images/qibbie_logo.png';
import { confs } from '../../../../../bootstrap/config';
import moment from 'moment';

let baseUrl = confs.url

const EBLogo = `url(${defaultLogo})`

export const SignatureVerification = (props) => {
    const {locals} = props
    const [signatures, setSignatures] = useState([])
    const [timestamp, setTimestamp] = useState(null)
    const [planName, setPlanName] = useState("")

    useEffect(()=>{
        if(props.match.params.id !== undefined ){
            const signatureId = props.match.params.id
            getSignatureData(signatureId)
        }
    },[])

    const getSignatureData = async(signatureId) => {
        let payload = {
            signatureId: signatureId
        }
        try {
            const responseJson = await fetch(baseUrl + 'signatureVerification', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                if(response.signatures && response.timestamp && response.planName){
                   setSignatures([...response.signatures])
                   setTimestamp(response.timestamp)
                   setPlanName(response.planName)
                }
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    return(
        <>
            <Container fluid className='h-100'>
                <Row>
                    <Col id="plan-col" xs={{ size: 12 }} xl={{ size: 8, offset: 2 }} style={{paddingLeft: 0, paddingRight: 0}}>
                        <div id='plan-container' className="plan-cover-container">
                            <Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                                <div style={{ width: '100%', wordWrap: 'break-word'}}>
                                    <h4>{locals.signatureVerification + ': '}<span><b>{planName}</b></span></h4>
                                </div>
                            </Row>
                            <Row style={{ marginBottom: '1em' }}>
                                <Col>
                                    <Row>
                                        <p>{locals.signatureIdentifier + ': ' + props.match.params.id}</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='signature-verification-container'>
                                {signatures && signatures.length > 0 && signatures.map((signature,key) => {
                                    return(
                                        <Col xs="12" key={key} style={{ }}>
                                            <Row>
                                                <div className='signature-verification-block' style={(signature.signedDate && signature.signerCode) ? { borderLeft: '3px solid #acd811' } : { borderLeft: '3px solid #ff0000' }}>
                                                    <p style={{ marginBottom: 0, marginRight: '0.25em' }}><b>{signature.signedDate? locals.signed : locals.notSigned }</b> {(signature.signedDate? moment.utc(signature.signedDate,"MMM D, YYYY HH:mm:ss A", "en").local().format('DD.MM.YYYY  HH:mm:ss') : '')  +' ' + signature.name + ', ' + signature.email}</p>
                                                    {signature.signerCode && <p style={{ fontWeight: 'bold', marginBottom: 0, marginTop:'0.5em' }}>{signature.signerCode}</p>}
                                                </div>
                                            </Row>
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Row>
                                <div className='verified-by'>
                                    {locals.verifiedByEB}
                                    <div className="" style={{ backgroundImage: EBLogo , minWidth:'30px', height:'30px', backgroundSize: 'contain', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', marginLeft:'0.5em'}}></div>
                                    <p style={{ marginBottom: 0, marginRight:'7em', marginLeft: 'auto' }}>{moment.utc(timestamp,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY  HH:mm:ss')}</p>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}