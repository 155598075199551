import React, { useState, useRef } from 'react'
import {
  Row,
  Col
} from 'reactstrap'

import {confs} from '../../../../bootstrap/config';
import {NotificationManager} from 'react-notifications';

import ViewCardModal from './ViewCardModal';

import {ResizedScrollBar} from './hocs/ResizedScrollBar';

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status" style={{alignSelf: 'center'}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const emptyCard = {
    name: '',
    desc: '',
    labels: [],
    memberList: []
}


const TrelloBlock = ({item, itemId, businessId, planId}) => {
    const isCancelled = useRef(false);
    const [ data, setData] = useState({});
    const [ isLoading, setIsLoading ] = useState( false )
    const [ cardModalIsOpen, setCardModalIsOpen ] = useState( false )
    const [ selectedCard, setSelectedCard ] = useState( emptyCard )
    const [size, setSize] = useState(item.size);

    React.useEffect(() => {
        let payload = {
            planId: planId,
            target: itemId,
            type: 'trello'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    
    }, []);

    const fetchData = async payload => {
        
        try {
            const response = await fetch( confs.url + 'embedItem', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                if(!isCancelled.current)
                    setData(responseJson);
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current)
            setIsLoading(false);
    }

    const toggleCardmodal = () => {
        setCardModalIsOpen( !cardModalIsOpen )
    }

    const openCardModal = card => {
        setSelectedCard( card )
        toggleCardmodal()
        return;
    }

    const checkListNumbers = (checklists) => {
        let numberOfItems = 0;
        let completed = 0;
    
        if(checklists !== undefined) {
          const lists = checklists.map(checklist => {
            const {checkItems} = checklist
    
            checkItems.map(checkItem => {
              if(checkItem.state !== 'incomplete') {
                completed++;
              }
              return checkItem
            })
    
            numberOfItems += checkItems.length
            return checklist;
          })
    
          return {
            numberOfItems: numberOfItems,
            completed: completed
          }
        }
    
        return;
      }

    return (
        <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className="plan-object" id={item.id}>
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
            {cardModalIsOpen && <ViewCardModal isOpen={ cardModalIsOpen } toggle={ toggleCardmodal } card={ selectedCard } businessId={ businessId } />}
                <div className="dashboard-medium-box" style={{marginTop: 0, ...size}}>
                    {isLoading ? 
                        <div style={{height: '100%', width: '100%'}}>
                            <MegaLoaderSpinner />
                        </div>
                    : <ResizedScrollBar size={size} title={data.name}>
                            { data.cards && data.cards.filter( card => !card.closed ).map( ( card, index ) => {

                            let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'
                            let cardNumbers = checkListNumbers(card.checklists);
                            return (
                            <div key={ index } className={ "selectable-row " + oddEvenClass } style={ { padding: '1em' } } onClick={ () => openCardModal( card ) }>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                { card.labels && card.labels.map( ( label, index ) => {
                                                return (
                                                    <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                                                        <span className='trello-label-text'>{ label.name }</span>
                                                    </span>
                                                )
                                                } ) }
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                { card.name }
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                            <div className="member-row">                   
                                                <div>
                                                    {card.checklists && 
                                                    <>
                                                        <i className="far fa-check-square"></i>
                                                        <span className="checklist-numbers">{`${cardNumbers.completed}/${cardNumbers.numberOfItems}`}</span>
                                                    </>
                                                    }
                                                </div>
                                                <div>
                                                { card.memberList.map( ( member, index ) => {
                                                    return <span key={ index } title={ member.fullName } className='trello-member'>{ member.initials }</span>
                                                } ) }
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            )
                            } ) }
                        </ResizedScrollBar>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default TrelloBlock

