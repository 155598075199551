import * as ActionTypes from '../actions/userActions/actionTypes';

const initialState = {
  registered: [],
  loginEmail: '',
  redirectUrl: '',
  codeLoginInProgress: false,
  userId: ""
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.REGISTER:
      return {
        ...state,
        registered: action.payload
      }
    case ActionTypes.LOGIN:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.CODE_LOGIN:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.CODE_LOGIN_PROGRESS:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.FIRST_LOGIN_ISVALID:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
}