import React, { useEffect, useState } from 'react';
import { Collapse, Button, Col, Row } from 'reactstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars';

export const SheetNav = ({navPoints, pointClicked, locals, answerSession, hasEmptyOptionAnswer, businessIdAnswered}) => {
    const [navOpen, setNavOpen] = useState(false)
    let number = 0
    return(
        <>
            <div className={navOpen? '' : 'sheet-nav-peek'}>
                <div className={"sheet-nav-container " + (navOpen? 'sheet-nav-container-open' : 'sheet-nav-container-closed')}>
                    {navOpen ?  
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '89vh'}}
                            className="scrollbars-container"
                        >
                            {navPoints && navPoints.map((group, index) => {
                                return(
                                    <div key={index} className="nav-group-div" style={{ padding:'1em' }}>
                                        <p className="nav-group-header" title={group.groupTitle} style={{fontSize: '1rem', marginRight: '1em', fontWeight: 'bold', borderBottom: '2px solid '+ group.groupColor}}>{group.groupTitle}</p>
                                        {group.navPoints && group.navPoints.map((point, key) => {
                                            let splitted = point.id.split('-')
                                            let groupIndicator = parseInt(splitted[0]) 
                                            let question = splitted[1]
                                            if(groupIndicator === index){
                                                return(
                                                    <motion.div  
                                                        key={"sheetSideNavItem-"+ key} 
                                                        title={point.title? point.title: locals.comment} 
                                                        onClick={()=> pointClicked(point.id)} className="sheet-nav-item" 
                                                        style={{border: '2px solid ' + group.groupColor, backgroundColor: group.groupColor+'20'}}
                                                        initial={{ opacity: 0, x: -100 }}
                                                        animate={{ opacity: 1, x: 0 }}
                                                        transition={{ ease: 'easeIn', duration: 0.4 }}
                                                        exit={{ opacity: 0, x: -50 }}    
                                                    >
                                                        <div className="sheet-nav-question-title">
                                                            {point.title?
                                                                point.title
                                                                :
                                                                locals.comment
                                                            }
                                                            {answerSession[index][key] && answerSession[index][key].data && (!answerSession[index][key].data.isComment || answerSession[index][key].data.isComment === false) && 
                                                                <>
                                                                    {(hasEmptyOptionAnswer && answerSession[index][key] && answerSession[index][key].isMultiSelection && (!answerSession[index][key].answer || !answerSession[index][key].answer.id )) ? 
                                                                        <div className="sideNav-not-answered-icon">
                                                                            <i className="fas fa-times"></i>
                                                                        </div>
                                                                        : 
                                                                        ((answerSession[index][key].answer && answerSession[index][key].answer.id)?
                                                                            <div className="sideNav-not-answered-icon">
                                                                                <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                            </div>
                                                                            :
                                                                            ((answerSession[index][key].answer && answerSession[index][key].answer.blocks && answerSession[index][key].answer.blocks.length > 1 || answerSession[index][key].answer && answerSession[index][key].answer.blocks && answerSession[index][key].answer.blocks[0].text.length > 0)? 
                                                                                <div className="sideNav-not-answered-icon">
                                                                                    <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                </div>
                                                                                :
                                                                                ((answerSession[index][key].answer && answerSession[index][key].answer.date && answerSession[index][key].answer.date !== '' )?
                                                                                    <div className="sideNav-not-answered-icon">
                                                                                        <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                    </div>
                                                                                    :
                                                                                    ((answerSession[index][key].answer && answerSession[index][key].answer.npsNumber && answerSession[index][key].answer.npsNumber !== 0 )?
                                                                                        <div className="sideNav-not-answered-icon">
                                                                                            <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                        </div>
                                                                                        :
                                                                                        (answerSession[index][key].data && answerSession[index][key].data.isBusinessId === true && businessIdAnswered?
                                                                                            <div className="sideNav-not-answered-icon">
                                                                                                <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                            </div>
                                                                                            :
                                                                                            ((answerSession[index][key].answer && answerSession[index][key].answer.length > 0 && answerSession[index][key].answer[0].fileId) ?
                                                                                                <div className="sideNav-not-answered-icon">
                                                                                                    <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                                </div>
                                                                                                :
                                                                                                ""
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                       
                                                    </motion.div>
                                                )
                                            }
                                        })} 
                                    </div> 
                                )
                            })}
                        </Scrollbars>
                        :
                        <div className={"sheet-nav-small"}>
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '89vh' }}
                                className="scrollbars-container"
                            >
                                {navPoints && navPoints.map((group, index) => {
                                    
                                    return(
                                        <div key={index} style={index === (navPoints.length-1)? {paddingBottom:'1.5em'} : {}}>
                                            {group.navPoints && group.navPoints.map((point, key) => {
                                                let splitted = point.id.split('-')
                                                let groupIndicator = parseInt(splitted[0]) 
                                                let question = splitted[1]
                                                if(groupIndicator === index){
                                                    number+=1
                                                    return(
                                                        <div key={key} 
                                                            className={(hasEmptyOptionAnswer && answerSession[index][key] && answerSession[index][key].isMultiSelection && (!answerSession[index][key].answer || !answerSession[index][key].answer.id )) ? "empty-answer" : ""}
                                                            style={{margin: '0.1em', marginLeft: '0.3em', cursor: 'pointer', border: '2px solid ' + group.groupColor, backgroundColor: group.groupColor+'20', height: '2em', width: '1.5em', borderRadius: '5px'}} 
                                                            onClick={()=> pointClicked(point.id)}
                                                        >
                                                            {number}.
                                                            {answerSession[index][key] && answerSession[index][key].data && (!answerSession[index][key].data.isComment || answerSession[index][key].data.isComment === false) && 
                                                                <>
                                                                    {(hasEmptyOptionAnswer && answerSession[index][key] && answerSession[index][key].isMultiSelection && (!answerSession[index][key].answer || !answerSession[index][key].answer.id )) ? 
                                                                        <div className="nav-not-answered-icon">
                                                                            <i className="fas fa-times"></i>
                                                                        </div>
                                                                        : 
                                                                        ((answerSession[index][key].answer && answerSession[index][key].answer.id)?
                                                                            <div className="nav-not-answered-icon">
                                                                                <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                            </div>
                                                                            :
                                                                            ((answerSession[index][key].answer && answerSession[index][key].answer.blocks && answerSession[index][key].answer.blocks.length > 1 || answerSession[index][key].answer && answerSession[index][key].answer.blocks && answerSession[index][key].answer.blocks[0].text.length > 0)? 
                                                                                <div className="nav-not-answered-icon">
                                                                                    <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                </div>
                                                                                :
                                                                                ((answerSession[index][key].answer && answerSession[index][key].answer.date && answerSession[index][key].answer.date !== '' )?
                                                                                    <div className="nav-not-answered-icon">
                                                                                        <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                    </div>
                                                                                    :
                                                                                    ((answerSession[index][key].answer && answerSession[index][key].answer.npsNumber && answerSession[index][key].answer.npsNumber !== 0 )?
                                                                                        <div className="nav-not-answered-icon">
                                                                                            <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                        </div>
                                                                                        :
                                                                                        (answerSession[index][key].data && answerSession[index][key].data.isBusinessId === true && businessIdAnswered?
                                                                                            <div className="nav-not-answered-icon">
                                                                                                <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                            </div>
                                                                                            :
                                                                                            ((answerSession[index][key].answer && answerSession[index][key].answer.length > 0 && answerSession[index][key].answer[0].fileId) ?
                                                                                                <div className="nav-not-answered-icon">
                                                                                                    <i className="fas fa-check" style={{ color: '#82d300' }}></i>
                                                                                                </div>
                                                                                                :
                                                                                                ""
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })} 
                                        </div> 
                                    )
                                })}
                            </Scrollbars>
                        </div>
                    }
                </div>
                <button className={"sheet-nav-button " + (navOpen? 'sheet-nav-open' : 'sheet-nav-closed')} onClick={()=> setNavOpen(!navOpen)} style={{width:'100%', color: '#fefefe'}}>
                    {navOpen?
                        <i className="fas fa-angle-left"></i>
                    :
                        <i className="fas fa-angle-right"></i>
                    }
                </button>
            </div>
            
        </>
    )
}