import React, { Component, useState } from 'react'
import './addImageStyles.css'
import { confs } from '../../../bootstrap/config';
import { ImageButton } from '../Dropzone/ImageButton';

const styles = {
  addImage: 'addImage',
  addImagePopover: 'addImagePopover',
  addImageClosedPopover: 'addImageClosedPopover',
  addImageButton: 'addImageButton ',
  addImagePressedButton: 'addImagePressedButton',
  addImageBottomGradient: 'addImageBottomGradient',
  addImageInput: 'addImageInput',
  addImageConfirmButton: 'addImageConfirmButton'
}

export default class ImageAdd extends Component {
  // Start the popover closed
  state = {
    url: '',
    open: false,
  }

  setFile = (file) => {
    this.handleDropzoneFiles(file)
  }

  handleUmlauts = (name) => {
        
    if(name){
        let newName = name
        newName = newName.replace(/ä/g, 'a');
        newName = newName.replace(/ö/g, 'o');
        newName = newName.replace(/å/g, 'o');
        newName = newName.replace(/Ä/g, 'A');
        newName = newName.replace(/Ö/g, 'O');
        newName = newName.replace(/Å/g, 'O');

        return newName
    }
}

  async handleDropzoneFiles( file ) { 
    const { editorState, onChange, isSheet, enterpriseId, documentId } = this.props

    if(!!file) {
      const formData = new FormData()

      let blob = file.slice(0, file.size, file.type); 

      let newFile = new File([blob],this.handleUmlauts(file.name), {type:file.type})

      formData.append( "file", newFile, newFile.name )
      formData.append( "identifier", "survey")
      formData.append( "type", "draft-js-image" )
      formData.append("enterpriseId", enterpriseId)
      formData.append( "fileName", newFile.name)
      formData.append( "connectionType", "surveyAnswers" )
      formData.append("documentId", documentId)
      const resultJson = await fetch(confs.url + 'saveTextEditorImage', {
          method: 'POST',
          body: formData
      })
      const result = await resultJson.json();
      if (result.fileUrl) {
        onChange(this.props.modifier(editorState, result.fileUrl))
      } 
    }
  }

  render() {
    const buttonClassName = this.state.open ?
      styles.addImagePressedButton :
      styles.addImageButton + (this.props.isSheet?"sheet-addImage-button":"")

    return (
      <div className={styles.addImage}>
          <ImageButton 
            setFile={this.setFile}
            buttonClassName={buttonClassName}
          />
      </div>
    )
  }
}