import { NotificationManager } from 'react-notifications';
import * as ActionTypes from './actionTypes';
import { confs } from '../../../bootstrap/config';

const saveSurvey = surveys => {
  return {
    type: ActionTypes.GET_SURVEY,
    payload: surveys
  }
}

export const getSurvey = (code) => dispatch => {
  return fetch(confs.url + 'getSurvey', {
    method: 'POST',
    body: JSON.stringify(code)
  })
  .then(res => res.json())
  .then(data => {
    if(data.success === true) {
      dispatch(saveSurvey(data))
      if(data.customStyles){
        setCustomStyles(data.customStyles)
      }
    } else {
      NotificationManager.error('Koodilla ei löytynyt kartoitusta. Tarkista, että syöttämäsi koodi on oikein ja että kartoitus on vielä voimassa.', 'Ilmoitus', 5000);
    }
  })
}

const setCustomStyles = (styles) => {
  if(styles.css){
    Object.keys(styles.css).map(key => {
        document.documentElement.style.setProperty(key, styles.css[key])
    })
    
  } 
  if(styles.backgroundImage){
    document.documentElement.style.setProperty('--enterprise-background-image',`url(${confs.host}/api/getCustomBackground/${styles.backgroundImage})`)
  }

  if(styles.plan && styles.plan.hidePlanNav === true){
    let navbar = document.getElementById('frontpageHeader')
    navbar.setAttribute("style", "display:none")
  }
}

export const onSurveyAnswer = (data) => dispatch => {
  return fetch(confs.url + 'surveyAnswer', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(response => {
    if(response.success) {
    
      if(response.sessionId){
        dispatch(setSession(response.sessionId))
      }
      if(response.newSentSurveyId){
        let ansSession = JSON.parse(localStorage.getItem(data.sentSurveyId))
        if(ansSession){
          ansSession['sentSurveyId'] = response.newSentSurveyId
          localStorage.setItem(data.sentSurveyId, JSON.stringify(ansSession))
        }
        dispatch(setNewSentSurveyId(response.newSentSurveyId))
      }
    }
  })
}

const setSession = id => {
  return {
    type: ActionTypes.SET_NEW_ID,
    payload: id
  }
}

const setNewSentSurveyId = id => {
  return {
    type: ActionTypes.SET_NEW_SURVEY_ID,
    payload: id
  }
}

export const fetchTargetSurveyAfterAnswer = (data) => dispatch => {
  return fetch(confs.url + 'targetSurvey', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(response => {
    if(response.surveys) {
      dispatch(saveSurvey(response))
    }
  }).catch(error => {
    console.error("Error: ", error)
  }) 
}

const endSurvey = {
  type: ActionTypes.END_SURVEY,
  payload: {
    userDialog: null,
    answerSessionId: null
  }
}

export const endUserSurvey = dispatch => {
  dispatch(endSurvey)
}

export const getFeedbackParameterValues = (params) => dispatch => {
  return new Promise( async function( resolve ) {
    const responseJson = await fetch( confs.url + 'getFeedbackParameterValues', { method: 'POST', body: JSON.stringify( params ) } )
    const response = await responseJson.json()

    if(response) {
      resolve( response )
    }
  })
}

export const handleAutomation = (params) => dispatch => {
  return new Promise( async function( resolve ) {
    const responseJson = await fetch( confs.url + 'handleOnAnswerAutomation', { method: 'POST', body: JSON.stringify( params ) } )
    const response = await responseJson.json()

    if(response) {      
      resolve( response )
    }
  })
}

export const getEndTextParameterValues = ( params ) => dispatch => {
  return new Promise( async ( resolve ) => {
    const responseJson = await fetch( confs.url + 'getEndTextParameterValues', { method: 'POST', body: JSON.stringify( params ) } )
    const response = await responseJson.json()
    
    if( response ) {
      resolve( response )
    }
  })
}