import React, { useState, useRef } from 'react'
import {
  Row,
  Col
} from 'reactstrap'

import {confs} from '../../../../bootstrap/config';
import {NotificationManager} from 'react-notifications';

import {ResizedScrollBar} from './hocs/ResizedScrollBar';
import BarGraph from './BarGraph';


const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem', alignSelf: 'center' }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}


const ExcelBlock = ({item, itemId, planId}) => {
    const isCancelled = useRef(false);
    const [ data, setData] = useState({});
    const [ showChart, setShowChart ] = useState(false);
    const [ isLoading, setIsLoading ] = useState( false );
    const [showLegend, toggleShowLegend] = useState(false);
    const [size, setSize] = useState(item.size || {height: 500, width: '100%'});

    React.useEffect(() => {
        let payload = {
            planId: planId,
            target: itemId,
            type: 'excel'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    }, []);

    const fetchData = async payload => {
        try {
            const response = await fetch( confs.url + 'embedItem', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                if(!isCancelled.current) {
                    setData(responseJson);
                }
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current) {
            setIsLoading(false);
        }
    }

    const legendToggler = () => {
        toggleShowLegend(!showLegend);
    }

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0"
    };

    return (
        <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className="plan-object" id={item.id}>
            <Col className="col-sm-12 col-md-3 offset-md-1 plan-dropdown-col">
                <button 
                className={showChart ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} 
                onClick={() => setShowChart(!showChart)} id={`${item.id}-saveButton`}>{showChart ? "Piilota" : "Näytä"}</button>
            </Col>
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
                {showChart &&
                    <>
                        {isLoading ? 
                            <div style={{height: '100%', width: '100%'}}>
                                <MegaLoaderSpinner />
                            </div>
                        :   
                            <div className="dashboard-medium-box" style={{marginTop: 0, ...size}} >
                                <ResizedScrollBar size={size} title={data.name} toggleLegend={legendToggler}>
                                    <BarGraph 
                                        data={data.data} 
                                        barNames={data.barNames} 
                                        title={data.title} 
                                        i={item.id} 
                                        toggledLegend={showLegend} 
                                        yTitle={data.yTitle} 
                                        xTitle={data.xTitle} />
                                </ResizedScrollBar>
                            </div>
                        }
                    </>
                }    
            </Col>
        </Row>
    )
}

export default ExcelBlock

