import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  ModalHeader
} from 'reactstrap'
import moment from 'moment'

import TextEditor from '../../modules/TextEditor/texteditor'

const TaskModal = props => {
  const { task, toggle, isOpen, locals } = props

  return (
    <Modal isOpen={ isOpen } size='lg' toggle={ toggle }>
      { task &&
        <>
          <ModalHeader toggle={ toggle }>
            {locals.taskEvaluationSummary}
          </ModalHeader>

          <ModalBody>

            <Row style={ { marginBottom: '1em' } }>
              <Col>
                <h5>{locals.title}</h5>
                <TextEditor content={ task.taskTitle } readOnly={ true } onTextChange={ () => { return } } />
              </Col>
            </Row>

            <Row style={ { marginBottom: '1em' } }>
              <Col>
                <h5>{locals.explanation}</h5>
                <TextEditor content={ task.styledTextTask } readOnly={ true } onTextChange={ () => { return } } />
              </Col>
            </Row>

            <hr style={ { clear: 'both' } } />

            <Row>

              <Col xl='6'>
                <h5 style={ { marginBottom: 0 } }>Deadline</h5>
                <p>{ moment( new Date( task.deadline ) ).format( 'DD.MM.YYYY' ) }</p>
              </Col>

              <Col xl='6'>
                <h5 style={ { marginBottom: 0 } }>{locals.reminderDate}</h5>
                <p>{ moment( new Date( task.notificationDate ) ).format( 'DD.MM.YYYY' ) }</p>
              </Col>

            </Row>

            <Row>

              <Col xl='6'>
                <h5 style={ { marginBottom: 0, marginTop: '1rem' } }>{locals.responsible}</h5>
                { task.notificationList.map( ( target, index ) => {
                  return <p key={ index }>{ target.label }</p>
                } ) }
              </Col>

            </Row>

          </ModalBody>

          <ModalFooter>
            <Button onClick={ toggle }>{locals.close}</Button>
          </ModalFooter>
        </>
      }

    </Modal>
  )
}

export default TaskModal