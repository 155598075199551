import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {connect} from 'react-redux';
import * as ActionCreator from '../../store/actions/taskActions/actionCreator';

import {Row, Col} from 'reactstrap';
import './task.css';
class TaskHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            checkingStatus: true,
            taskHandled: false,
            target: ""
        }
    }

    componentDidMount() {
        if(typeof this.props.match.params.id !== 'undefined')  {
            let target = this.props.match.params.id;

           if(target.length === 24) {
               this.props.checkTask({target: target});
               this.setState({target: target});
           } else {
               window.location.href = "/"
           }
        }
    }

    componentWillUnmount() {
        this.props.onTaskNotValid(null);
    }

    componentDidUpdate(prevProps, nextProps) {
        if(prevProps.taskStatus !== this.props.taskStatus) {

            if(this.props.taskStatus === false) {
                setTimeout(() => this.setState({taskHandled: true}), 2000);
                setTimeout(() => window.location.href = '/', 3000);
            }
            
            if(this.props.taskStatus !== false && this.props.taskStatus === 'ok') {
                setTimeout(() => {this.setState({checkingStatus: false}); this.props.onTaskOk({target: this.state.target})}, 2500);
            }

            if((typeof this.props.taskStatus.state !== 'undefined' && this.props.taskStatus.state === 'done') || this.props.taskStatus.state === 'cancelled') {
                setTimeout(() => this.setState({taskHandled: true}), 1000);
                setTimeout(() => window.location.href = '/', 3000);
            }

            if((typeof this.props.taskStatus.state !== 'undefined' && this.props.taskStatus.state === "already done") || this.props.taskStatus.state === 'already cancelled') {
                setTimeout(() => this.setState({taskHandled: true}), 1000);
                setTimeout(() => window.location.href = '/', 3000);
            }
        }
    }

    returnStatus = status => {  
        if(status.state === 'done') {
            return this.props.locals.taskStatusDone;
        } else if(status.state === 'already cancelled') {
            return this.props.locals.taskStatusCancelled;
        } else if(status.state === "already done") {
            return this.props.locals.taskAlreadyDone;
        } else if(status === false) {
            return this.props.locals.requestErrorTask;
        }
    }

    render() {
        const {locals} = this.props
        return (
            <div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
              <ModalBody style={{textAlign: 'center', height: 200}}>
                {!this.state.taskHandled && <div className="spinner-border text-success" role="status" style={{width: 50, height: 50, marginTop: 30}}>
                    <span className="sr-only">Loading...</span>
                </div>
                }
                <Row style={{marginTop: 50}}>
                    <Col>
                       {!this.state.taskHandled ? (
                           this.state.checkingStatus ?  locals.checkRequest : locals.requestFound
                        ) : (
                            <h4>{this.returnStatus(this.props.taskStatus)}</h4>
                        )}
                    </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        taskStatus: state.task.taskStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkTask: (params) => dispatch(ActionCreator.checkTaskStatus(params)),
        onTaskNotValid: (params) => dispatch(ActionCreator.taskStatus(params)),
        onTaskOk: (params) => dispatch(ActionCreator.changeTaskStatus(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskHandler);

