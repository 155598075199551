import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

export const ResizedScrollBar = ({title, size, data, children, toggleLegend}) => {
    const ref = React.useRef();
    const [listHeight, setListHeight] = React.useState(size.height);

    const calculateListHeight = (size, ref) => {
        let sizeAsString = size.height + "";
        let calculateThis = size.height;
    
        if(sizeAsString.includes('px'))
            calculateThis = parseInt(calculateThis.replace("px", ""));
    
        if(ref.current) {
            return calculateThis - ref.current.clientHeight - 16;
        }
        return size.height;
    }

    React.useEffect(() => {
        setListHeight(calculateListHeight(size, ref))
    }, [size.height])

    return (
        <>
        <div className="box-header bigger-text" ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
            <p>
                { title }
            </p>
            {toggleLegend && <i className="far fa-chart-bar" onClick={() => toggleLegend()} style={{ marginRight: '1em', marginLeft: 'auto', cursor: 'pointer' }}></i>}
        </div>
        <Scrollbars
            renderTrackHorizontal={ props => <div { ...props } className="track-horizontal" style={ { display: "none" } } /> }
            renderThumbHorizontal={ props => <div { ...props } className="thumb-horizontal" style={ { display: "none" } } /> }
            style={ { width: "100%", height: listHeight } }
        >
            {children}
        </Scrollbars>
        </>
    )
}