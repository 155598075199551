import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {connect} from 'react-redux';
import * as ActionCreator from '../../store/actions/taskActions/actionCreator';
import {confs} from '../../bootstrap/config';

import {Row, Col} from 'reactstrap';
import '../Tasks/task.css';
class LinkHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            checkingStatus: true,
            taskHandled: false,
            target: ""
        }
    }

    componentDidMount() {
        if(typeof this.props.match.params.id !== 'undefined')  {
            let target = this.props.match.params.id;
            let payload = {
                acceptId: target
            }

           if(target.length === 24)  {
               fetch(confs.url + 'checkEvent', {
                   method: 'POST',
                   body: JSON.stringify(payload)
               }).then(response => response.json())
               .then(responseJson => {
                   if(responseJson.status === 'ok') {
                        setTimeout(() => {this.setState({checkingStatus: false, status: responseJson.status}); this.onTaskOk(payload)}, 2500);
                   }
               })
           } else {
               window.location.href = "/"
           }
        }
    }

    onTaskOk = async payload => {
        const response = await fetch(confs.url + 'eventHandler', {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        const responseJson = await response.json()

        if(responseJson.status === 'handled' || responseJson.status === 'not found') {
            setTimeout(() => {
                this.setState({taskHandled: true, status: responseJson.status})
                setTimeout(() => window.location.href = '/', 3000);
            }, 2500);
        }
    }

    componentWillUnmount() {
        this.props.onTaskNotValid(null);
    }

    /*componentDidUpdate(prevProps, nextProps) {
        if(prevProps.taskStatus !== this.props.taskStatus) {
            console.log(this.props.taskStatus)

            if(this.props.taskStatus === false) {
                setTimeout(() => this.setState({taskHandled: true}), 2000);
                setTimeout(() => window.location.href = '/', 3000);
            }

            if(this.props.taskStatus !== false && this.props.taskStatus === 'ok') {
                setTimeout(() => {this.setState({checkingStatus: false}); this.props.onTaskOk({target: this.state.target})}, 2500);
            }

            if(typeof this.props.taskStatus.state !== 'undefined' && this.props.taskStatus.state === 'done' || this.props.taskStatus.state === 'cancelled') {
                setTimeout(() => this.setState({taskHandled: true}), 1000);
                setTimeout(() => window.location.href = '/', 3000);
            }

            if(typeof this.props.taskStatus.state !== 'undefined' && this.props.taskStatus.state === "already done" || this.props.taskStatus.state === 'already cancelled') {
                setTimeout(() => this.setState({taskHandled: true}), 1000);
                setTimeout(() => window.location.href = '/', 3000);
            }
        }
    }*/

    returnStatus = status => {  
        if(status === 'handled') {
            return "Tila vaihdettu onnistuneesti."
        } else if(status === 'already handled') {
            return "Tila on jo vaihdettu."
        } else if( status === 'not found' ) {
            return "Tilan vaihtaminen epäonnistui. Kutsu on vanhentunut tai se on peruutettu. Pyydä kutsun lähettäjältä uusi kutsu."
        }
    }

    render() {
        return (
            <div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
              <ModalBody style={{textAlign: 'center', height: 200}}>
                {!this.state.taskHandled && <div className="spinner-border text-success" role="status" style={{width: 50, height: 50, marginTop: 30}}>
                    <span className="sr-only">Loading...</span>
                </div>
                }
                <Row style={{marginTop: 50}}>
                    <Col>
                       {!this.state.taskHandled ? (
                           this.state.checkingStatus ?  "Tarkastetaan tapahtumaa..." : "Tapahtuma löydetty, vaihdetaan tilaa..." 
                        ) : (
                            <h4>{this.returnStatus(this.state.status)}</h4>
                        )}
                    </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        taskStatus: state.task.taskStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkTask: (params) => dispatch(ActionCreator.checkTaskStatus(params)),
        onTaskNotValid: (params) => dispatch(ActionCreator.taskStatus(params)),
        onTaskOk: (params) => dispatch(ActionCreator.changeTaskStatus(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkHandler);

