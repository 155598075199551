import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import UserReducer from './store/reducers/userReducer';
import SurveyReducer from './store/reducers/surveyReducer';
import TaskReducer from './store/reducers/taskReducer';
import PlanReducer from './store/reducers/planReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import 'react-notifications/lib/notifications.css';

smoothscroll.polyfill();

//Logger for showing what happens in redux in console
function logger({ getState }) {
  return next => action => {
    // console.log('will dispatch', action)
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)
    // console.log('state after dispatch', getState())
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
};

const rootReducer = combineReducers({
  user: UserReducer,
  surveys: SurveyReducer,
  task: TaskReducer,
  plan: PlanReducer
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
