import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import * as ActionCreator from '../../../store/actions/planActions/actionCreator'

import TextEditor from '../../modules/TextEditor/texteditor'
import TaskModal from './TaskModal'
import RiskModal from './RiskModal'
import Summary from './summary/Summary';
import ReportItem from '../modules/reports/ReportItem';
import {TableItem} from '../modules/tables/TableItems';
import {ProductBlock} from './Product/ProductBlock';
//Embeds
import ExcelBlock from './embeds/ExcelBlock';
import ListBlock from './embeds/ListBlock';
import TrelloBlock from './embeds/TrelloBlock';
import QlikIframe from './embeds/QlikIframe';

import { confs } from '../../../bootstrap/config'
import '../plan.css'
import { AttachmentBlock } from './attachment/AttachmentBlock'
import { SignatureBlock } from './Signature/SignatureBlock'
import { AnnotationTool } from './Annotation/AnnotationTool'
import { RiskBlock } from './risks/RiskBlock'
import { ChecklistBlock } from './Checklist/ChecklistBlock'
import { BusinessExcelBlock } from './BusinessExcel/BusinessExcelBlock'
import { GanttBlock } from './Gantt/GanttBlock'
import { TrelloTimelineBlock } from './TrelloTimeline/TrelloTimelineBlock'

let frontPage = confs.frontPage

const Plan = props => {
  const selectedPlan = useSelector(state => state.plan.selectedPlan.plan)
  const planRisks = useSelector(state => state.plan.selectedPlan.risksById)
  const planTasks = useSelector(state => state.plan.selectedPlan.tasksById)
  const isLocked = useSelector(state => state.plan.isLocked)
  const riskItems = useSelector(state => state.plan.riskItems)

  const [isLoading, setIsLoading] = useState(false)
  const [viewTasks, setViewTasks] = useState([])
  const [viewRisks, setViewRisks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [selectedRisk, setSelectedRisk] = useState(null)
  const [taskModalIsOpen, setTaskModalIsOpen] = useState(false)
  const [riskModalIsOpen, setRiskModalIsOpen] = useState(false)
  const [inCoverPage, setInCoverPage] = useState(true)
  const [annotationModalOpen, setAnnotationModalOpen] = useState(false)

  const dispatch = useDispatch();

  const openTask = task => {
    setSelectedTask(task)
    setTaskModalIsOpen(true)
  }

  const openRisk = risk => {
    setSelectedRisk(risk)
    setRiskModalIsOpen(true)
  }

  const toggleViewTasks = index => {
    const allViewed = [...viewTasks]
    const viewing = allViewed.indexOf(index)

    if (viewing !== -1) {
      allViewed.splice(viewing, 1)
    } else {
      allViewed.push(index)
    }

    setViewTasks(allViewed)
  }

  const toggleViewRisks = index => {
    const allViewed = [...viewRisks]
    const viewing = allViewed.indexOf(index)

    if (viewing !== -1) {
      allViewed.splice(viewing, 1)
    } else {
      allViewed.push(index)
    }

    setViewRisks(allViewed)
  }

  const startScrolling = chosenTopicId => {
    var element = document.getElementById(chosenTopicId);

    window.scrollTo({
      behavior: element ? "auto" : "auto",
      top: element ? element.offsetTop : 0
    });
  }

  const submitPassword = async (e, password) => {
    e.preventDefault()
    setIsLoading(true)
    const passwordCorrect = await props.submitPassword(password)

    setTimeout(() => {
      setIsLoading(false)
      if (!passwordCorrect) {
        NotificationManager.error(props.locals.wrongPassword, props.locals.notice, 3000)
      }
    }, 500)
  }
  
  const checkItemType = planItem => {
    switch (planItem.type) {
      case 'iFrame':
        return (
          <div className="plan-paragraph" style={{ display: 'flex', justifyContent: 'center' }}>
            {planItem.qlik ? <QlikIframe data={planItem} hostName={planItem.hostName} style={planItem.size} /> : <iframe src={planItem.hostName} frameBorder="0" style={planItem.size || { height: 650, width: '100%' }}></iframe>}
          </div>
        );
      case 'report':
        return (
          <ReportItem planId={selectedPlan._id} id={planItem.targetSurvey !== undefined ? planItem.targetSurvey.id : null} locals={props.locals} item={planItem} />
        )
      case 'Trello':
        return (
          <TrelloBlock
            item={planItem}
            itemId={planItem.targetId}
            businessId={selectedPlan.selectedBusiness.value}
            planId={selectedPlan._id}
          />
        )
      case 'TrelloTimeline':
        return (
          <TrelloTimelineBlock
            item={planItem}
          />
        )
      case 'tables':
          return (
            <TableItem table={planItem} />
          )
      case 'Excel':
        return (
          <ExcelBlock
            item={planItem}
            itemId={planItem.targetId}
            planId={selectedPlan._id}
          />
        )
      case 'list':
        return (
          <ListBlock item={planItem}
            itemId={planItem.targetId}
            planId={selectedPlan._id} />
        )
      case 'product':
        return(
            <ProductBlock
              item={planItem}
              planId={selectedPlan._id}
            />
        )
      case 'attachment':
        return(
            <AttachmentBlock
              item={planItem}
              planId={selectedPlan._id}
              locals={props.locals}
            />
        )
      case 'signature':
        return(
            <SignatureBlock
              item={planItem}
              planId={selectedPlan._id}
              locals={props.locals}
            />
        )
      case 'risks':
        return(
            <RiskBlock
              item={planItem}
              planId={selectedPlan._id}
              locals={props.locals}
              riskItems={riskItems}
            />
        )
      case 'checklist':
        return(
            <ChecklistBlock
              item={planItem}
              planId={selectedPlan._id}
              locals={props.locals}
              plan={selectedPlan}
            />
        )
      case 'businessExcel':
        return(
            <BusinessExcelBlock
              item={planItem}
              // locals={props.locals}
              // plan={selectedPlan}
            />
        )
        case 'gantt':
        return(
          <GanttBlock
            item={planItem}
            planId={selectedPlan._id}
            locals={props.locals}
          />
        )
      default:
        return (
          <TextEditor additionalClass='plan-paragraph' content={planItem.textContent} readOnly={true} onTextChange={() => { return }} />
        )
    }
  }

  
  window.onscroll = function() {scrollFunction()};
  
  const scrollFunction = () => {
    var scrollButton = document.getElementById("scroll-btn");
    if(scrollButton !== undefined && scrollButton !== null){
      if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
        scrollButton.style.display = "block";
      } else {
        scrollButton.style.display = "none";
      }
    }
  }

  const scrollToTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }

  const openPlanItem = (selected) => {
    let url = `${frontPage}viewPlanItem/${selectedPlan._id}/${selected.id}`
    window.open(url, "_blank")
  }

  if (isLocked) {
    return (
      <PasswordInput submitPassword={submitPassword} isLoading={isLoading} locals={props.locals} />
    )
  } else {
    const { clientInfo, planTitle, planName, planItems, createdBy, lastEdited, modified, allowAnnotation = false, layoutOptions = false} = selectedPlan

    return (

      <>
        {selectedPlan && selectedPlan.coverOptions && selectedPlan.coverOptions.showCoverPage && inCoverPage ? 
          <Row>
            <Col id="plan-col" xs={{ size: 12 }} xl={{ size: 8, offset: 2 }} style={{paddingLeft: 0, paddingRight: 0}}>
              <div id='plan-container' className="plan-cover-container">
                <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                  <img style={{ maxHeight: '70px'}} src= {`${confs.host}/api/homepage/getLogo/${createdBy}`}></img>
                </div>
                <div id='plan-title-text' className="plan-item-container"style={{ marginBottom: '2em' }}>
                  <TextEditor additionalClass='plan-cover-heading' content={ selectedPlan.coverOptions.topicContent} readOnly={true} onTextChange={() => { return }} />
                </div>

                <div className="plan-item-container"style={{ marginBottom: '2em' }}>
                  <TextEditor additionalClass='' content={selectedPlan.coverOptions.textContent} readOnly={true} onTextChange={() => { return }} />
                </div>
              </div>
            </Col>
            <div className="go-to-plan-from-cover" onClick={() => setInCoverPage(false)}>
              <i className="fas fa-caret-right"></i>
            </div>
          </Row>
          :
          <Row>
            <TaskModal toggle={() => setTaskModalIsOpen(false)} isOpen={taskModalIsOpen} task={selectedTask} locals={props.locals} />
            <RiskModal toggle={() => setRiskModalIsOpen(false)} isOpen={riskModalIsOpen} risk={selectedRisk} locals={props.locals} />

            <Col id="plan-col" xs={{ size: 12 }} xl={ annotationModalOpen? { size: 8, offset: 0 }:{ size: 8, offset: 2 }} style={annotationModalOpen? {paddingLeft: 0, paddingRight: 0, marginLeft: '2em' }: {paddingLeft: 0, paddingRight: 0}}>
              <div id='plan-container'>
                {selectedPlan && selectedPlan.trial && <div className="trial-watermark">{"EverBetter trial"}</div>}
                <button id="scroll-btn" className="scroll-to-top-button" title={props.locals.scrollToTop} onClick={()=> scrollToTop()}><i className="fas fa-arrow-up"></i></button>
                <Row  style={{ marginBottom: '1em' }}>
                  <Col >
                    <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                      <img style={{ maxHeight: '70px'}} src= {`${confs.host}/api/homepage/getLogo/${createdBy}`}></img>
                    </div>
                  </Col>
                  <Col>
                    <Row>
                      <Col xs="12">
                        <i onClick={() => window.print()} title={props.locals.printPdfTitle} id="printButton" className="fas fa-print" style={{float: 'right' }}></i>
                      </Col>
                      <Col>
                        <p  style={{ float: 'right', fontSize:'0.7rem'}}>{props.locals.planLastSaved + " "}{moment.utc(lastEdited? lastEdited : modified , "MMM D, YYYY, HH:mm:ss A", "en" ).format( 'D.M.YYYY' ) }</p>
                      </Col>
                    </Row>
                  </Col>
                </Row> 
                {/* plan title */}
                <Row id='plan-title' className={planName.length > 50 ? 'long-plan-title' : ''} style={{ marginBottom: '2em' }}>
                  <Col>
                    <h3 id='plan-title-text' className={planName.length > 50 ? 'long-plan-title' : ''} title={planName} style={{ margin: 'auto', alignItems:'center' }}>
                      {planName}
                    </h3>
                  </Col>
                </Row>
                {/* client info */}
                {clientInfo && selectedPlan && !selectedPlan.hideClient &&
                  <div id='plan-client-info' style={{ marginBottom: '1em' }}>
                    <p className='plan-header'>{selectedPlan.customTargetName? props.locals.recipient : props.locals.client}</p> 
                    <TextEditor additionalClass='plan-paragraph' content={clientInfo.businessName} readOnly={true} onTextChange={() => { return }} />
                    {clientInfo && clientInfo.address && <TextEditor additionalClass='plan-paragraph' content={clientInfo.address} readOnly={true} onTextChange={() => { return }} />}
                    {clientInfo && clientInfo.postCode && <TextEditor additionalClass='plan-paragraph' content={`${clientInfo.postCode} ${clientInfo.postOffice}`} readOnly={true} onTextChange={() => { return }} />}
                    {clientInfo && clientInfo.businessID && <TextEditor additionalClass='plan-paragraph' content={clientInfo.businessID} readOnly={true} onTextChange={() => { return }} />}
                  </div>
                }
                {layoutOptions && layoutOptions.layoutType === 'custom'?
                  <Row style={{marginTop:'2em'}}>
                    <Col xs="12">    
                      {layoutOptions.layoutMap && layoutOptions.layoutMap.map((item,index)=>{
                        return(
                          <Row  key={index}>
                              {item.map((col, key)=>{
                                return(
                                  <Col xs={col.w*2} key={key}>
                                    {col.rows.map((row,i)=>{
                                      let planItms = planItems
                                      if(planItms.filter((planItm) => planItm.id === row.i).length > 0){
                                        let planItem = planItems.filter((planItm) => planItm.id === row.i)[0]
                                        return(
                                          <div className="plan-item-container" key={i} style={{ marginBottom: '2em' }} id={planItem.id}>
                                              <div style={{ display: 'flex' }}>
                                                <TextEditor additionalClass='plan-header custom-plan-heading' content={planItem.topicContent} readOnly={true} onTextChange={() => { return }} />
                                                <div id="planItemPreview" style={{marginLeft:'auto', cursor:'pointer'}} title={props.locals.openInNewWindow} onClick={()=> openPlanItem(planItem)}><i className="fas fa-external-link-alt"></i></div>
                                              </div>

                                              {checkItemType(planItem)}
                                            
                                            
                                          </div>
                                        )
                                      }
                                    })}
                                  </Col>
                                )
                              })}
                          </Row>
                        )})
                      }          
                    </Col>
                  </Row>
                  :
                  <>
                    {(planItems !== undefined && planItems.length > 0) && (selectedPlan && (selectedPlan.showTableOfContents || selectedPlan.showTableOfContents === undefined) ) && <Summary planItems={planItems} startScrolling={startScrolling} locals={props.locals} />}
                    {/* plan items */}
                    {planItems && planItems.map((planItem, index) => {
                      const planItemTasks = typeof planTasks[planItem.id] !== 'undefined' ? planTasks[planItem.id] : []
                      const planItemRisks = typeof planRisks[planItem.id] !== 'undefined' ? planRisks[planItem.id] : []

                      return (
                        <div className="plan-item-container" key={index} style={{ marginBottom: '2em' }} id={planItem.id}>
                      
                      
                
                          <div style={{ display: 'flex' }}>
                            <div className='plan-header custom-plan-heading' style={{ marginRight: '0.5em' }}>{index+1}</div> 
                            <TextEditor additionalClass='plan-header custom-plan-heading' content={planItem.topicContent} readOnly={true} onTextChange={() => { return }} />
                            {planItem.answered && <div className="plan-header custom-plan-heading">{planItem.answered}</div>}     
                            <div style={{marginLeft:'auto', cursor:'pointer'}} title={props.locals.openInNewWindow} onClick={()=> openPlanItem(planItem)}><i className="fas fa-external-link-alt"></i></div>
                          </div>
                    
                          {checkItemType(planItem)}
                        
                          {/* risks and tasks of one plan item */}
                          <div className='icon-container'>
                            {planItemTasks.length > 0 && <i className={'fas fa-tasks view-content-icon ' + (viewTasks.indexOf(index) !== -1 ? 'active' : '')} onClick={() => toggleViewTasks(index)} ></i>}
                            {planItemRisks.length > 0 && <i className={'fas fa-exclamation view-content-icon ' + (viewRisks.indexOf(index) !== -1 ? 'active' : '')} onClick={() => toggleViewRisks(index)} ></i>}
                          </div>

                          <div className='additional-content'>
                            {viewTasks.indexOf(index) !== -1 && (
                              planItemTasks.map((task, index) => {
                                return (
                                  <div className='additional-content-row' key={index} onClick={() => openTask(task)}>
                                    <div>
                                      <i className="fas fa-tasks deactive-view-content-icon"></i>
                                    </div>
                                    <div>
                                      <p style={{ paddingLeft: '2em', marginBottom: 0 }}>{task.taskTitle}<br /><b>{moment(new Date(task.deadline)).format('DD.MM.YYYY')} - {task.notificationList[0].label}</b></p>
                                    </div>
                                  </div>
                                )
                              })
                            )}

                            {viewRisks.indexOf(index) !== -1 && (
                              planItemRisks.map((risk, index) => {
                                return (
                                  <div className='additional-content-row' key={index} onClick={() => openRisk(risk)}>
                                    <div>
                                      <i className="fas fa-exclamation deactive-view-content-icon"></i>
                                    </div>
                                    <div>
                                      <p style={{ paddingLeft: '2em', marginBottom: 0 }}>{risk.riskName? <b>{risk.riskName}</b> : risk.explanationPlainContent}<br />{moment(new Date(risk.created)).format('DD.MM.YYYY')} - {risk.riskClassification.label}</p>
                                    </div>
                                  </div>
                                )
                              })
                            )} 
                            
                          </div>

                        </div>
                      )
                    })}
                  </>
                }
              </div>
            </Col>
            {allowAnnotation &&
                <Col>
                  <AnnotationTool
                    annotationList={selectedPlan.annotations}
                    planId={selectedPlan._id}
                    locals={props.locals}
                    addModalOpen={annotationModalOpen}
                    setAddModalOpen={setAnnotationModalOpen}
                  />
              </Col>
            }
          </Row>
        }  
      </>
    )
  }


}

const PasswordInput = props => {
  const [password, setPassword] = useState('')
  let passwordInput = React.createRef()

  useEffect(() => {
    passwordInput.current.focus()
  })

  return (
    <Row style={{ height: '100%', alignItems: 'center' }}>
      <Col xs={{ size: 12 }} sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }} >
        <div id='password-container' className='greybox0 loginbox' style={{ padding: '1em' }}>

          <Form className='white_input'>

            <FormGroup>
              <label>{props.locals.password}</label>
              <Input innerRef={passwordInput} value={password} onChange={(e) => setPassword(e.target.value)} className='form-control form-control-lg' placeholder='' type='password'></Input>
            </FormGroup>

            <FormGroup>
              <Button disabled={props.isLoading} onClick={(e) => props.submitPassword(e, password)} type='submit' className='btn btn-lg login-button'>
                {props.isLoading ? (
                  <div className='spinner-border text-success' role='status' style={{ width: 20, height: 20 }}>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                    props.locals.planPasswordButton
                  )}
              </Button>
            </FormGroup>

          </Form>

        </div>
      </Col>
    </Row >
  )
}

export default Plan