import React, { useEffect, useState } from 'react';
import { Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import locals from '../../../../locals/locals';
import { confs } from '../../../../bootstrap/config'
import { Dropzone } from '../../../modules/Dropzone/Dropzone';

let baseUrl = confs.url

export const Attachment = (props) => {
    const {onAnswer, sentSurveyId, dialog, isSheet=false, enterpriseId} = props
    const [fileName, setFileName] = useState("")
    const [errorMessage, setErrorMessage] = useState(false)
    const data = new FormData()
    const [fileChanged, setFileChanged] = useState()
    const [newFile, setNewFile] = useState()
    const [fileList, setFileList] = useState([])
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedFile, setSelectedFile] = useState({})

    const handleFileChange = (e) => {
        setFileChanged(e)
        let name = e.name.split(".")
        name.pop()
        let finalName = name.join(".")
        if(!fileName || (fileName && fileName.length === 0)){
            setFileName(finalName)
        }
    }

    useEffect(()=>{
        if(newFile){
            handleFileChange(newFile)
        }
    },[newFile, setNewFile])

    const handleUmlauts = (name) => {
        
        if(name){
            let newName = name
            newName = newName.replace(/ä/g, 'a');
            newName = newName.replace(/ö/g, 'o');
            newName = newName.replace(/å/g, 'o');
            newName = newName.replace(/Ä/g, 'A');
            newName = newName.replace(/Ö/g, 'O');
            newName = newName.replace(/Å/g, 'O');

            return newName
        }
    }
   
    const saveFile = async(e) => {
       
        let blob = newFile.slice(0, newFile.size, newFile.type); 

        let file = new File([blob],handleUmlauts(newFile.name), {type:newFile.type})

        data.set("name", fileName)
        data.set("jwtToken", window.sessionStorage.getItem("token"))
        data.set("name",fileName)
        data.set("file", file, file.name)
        data.set("type", "file")
        let tempName = file.name.split(".")
        data.set("fileEnd", tempName[tempName.length-1])
        data.set("sentSurveyId", sentSurveyId)
        data.set("connectionType", "surveyAnswers")
        data.set("documentId", sentSurveyId)

        try {
            const responseJson = await fetch(baseUrl + 'handleFileSave', {
                method: 'POST',
                body: data
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                setFileList([...fileList, response])
                closeAddModal()
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    const [fileOk, setFileOk] = useState(false)
    const [nameOk, setNameOk] = useState(false)
    const [showSave, setShowSave] = useState(false)
    useEffect(()=>{
        if(fileName){
            checkName()
        }
        if(fileChanged){
            checkDataType(fileChanged)
        }
        if(fileOk && nameOk){
            setShowSave(true)
        } else {
            setShowSave(false)
        
        }
    },[fileName, fileChanged, fileOk, nameOk])

    const checkDataType = (file) => {
        let regex = new RegExp(/([a-zA-Z0-9ÄäÖöÅå\s_\\.\-\(\):])+(.doc|.docx|.pdf|.xls|.xlsx|.xlsm|.xlsb|.xltx|.txt|.jpg|.jpeg|.svg|.png|.bmp|.ppt|.pptx|.pptm)$/)
        if(file && file.name){  
            if(regex.exec(file.name.toLowerCase()) !== null){        
                setFileOk(true)
            } else { 
                setFileOk(false)
            }
        } else {
            setFileOk(false)
        }    
    }

    const checkName = () => {
        let regex = new RegExp(/^[a-zA-Z0-9ÄäÖöÅå\p{L}\s_\\.\-\(\):]+$/)
        if(fileName && fileName.length > 0 ){
            if(regex.exec(fileName) != null){
                let nameColour = document.getElementById("name")
                nameColour.setAttribute("style", "color:default;")     
                setNameOk(true)
            }  else {
                let nameColour = document.getElementById("name")
                nameColour.setAttribute("style", "color:red;")
                setNameOk(false)
            }
        } else {
            setNameOk(false)
        }
    }

    const closeAddModal = () => {
        setAddModalOpen(false)
        setFileName("")
        setNewFile()
    }
    const continueSurvey = (e) => {
        if(dialog){
            onAnswer(fileList, e) 
        }
        else{
            onAnswer(fileList)    
        }
    }

    useEffect(()=>{
        if(isSheet){
            props.handleAnswerSession(fileList)
        }
    },[fileList])

    const handleRemove = (index, file) => {
        setSelectedIndex(index)
        setSelectedFile(file)
        setRemoveModalOpen(true)
    }

    const removeSelection = (index) => {
        let tempFiles = fileList
        tempFiles.splice(index,1)
        setFileList(tempFiles)
        setRemoveModalOpen(false)
        setSelectedIndex(null)
        setSelectedFile({})
    }

    return(
        <>
            {selectedFile &&
                <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                    <ModalHeader toggle={() =>setRemoveModalOpen(!removeModalOpen)}>
                        <p>Olet poistamassa tiedostoa {selectedFile.fileName} lomakkeesta!</p>
                    </ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa tiedoston {selectedFile.fileName +"."+selectedFile.fileEnd}?</p>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => setRemoveModalOpen(!removeModalOpen)} className="small-white-button swb-hover" style={{ width: '100%' }}>Peruuta</button>
                        <button onClick={() => removeSelection(selectedIndex)} className="small-white-button swb-hover delete-plan" style={{ width: '100%', backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                    </ModalFooter>
                </Modal>
            }
            <Modal isOpen={addModalOpen} toggle={() => closeAddModal()}>
                <ModalHeader>{locals.selectAttachment}</ModalHeader>
                <ModalBody>
                    <Dropzone
                        setFile={setNewFile}
                    />
                    <Label>{locals.fileName}</Label>
                    <Input id="name" className="name-input" value={fileName} onChange={(e) => setFileName(e.target.value)}/>
                    {errorMessage&& 
                        <p style={{ marginTop: '2em', color: 'red' }}>{locals.typeErrorMessage}</p>
                    }
                    
                </ModalBody>
                <ModalFooter>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <button style={{ width: '100%' }} className="small-white-button lighter-text wide-button" onClick={(e) => closeAddModal()}>{locals.cancel}</button>
                        {fileOk && nameOk && 
                            <button style={{ width: '100%' }} className="small-white-button lighter-text wide-button" onClick={(e) => saveFile(e)}>{locals.save}</button>
                        }
                    </div>
                </ModalFooter>
            </Modal>
            <div style={{ padding: '1em' }}>  
                <div style={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Row >
                        <Col xs="12" style={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                            {fileList.length > 0 && fileList.map((file,key)=>{
                                return(
                                    <Row key={key} style={{ marginTop: '0.5em',  }}>
                                        <p className="modal-name-overflow-handler"><span style={{ fontWeight: 'bold' }}>{(key+1)+". "}</span>{file.name}</p>
                                        <div className="remove-planAttachment" onClick={(e) => (e.stopPropagation(), handleRemove(key, file))}>
                                                <i className="fas fa-times"></i>
                                        </div>
                                    </Row>
                                )
                            })}
                        </Col>
                    </Row>
                    <button className="small-white-button lighter-text wide-button" style={{marginTop:'2em', backgroundColor: "#acd811", color:"#fff", fontWeight: 'bold'}} onClick={() => setAddModalOpen(true)}>{locals.addAttachment}</button>
                </div>
                {!isSheet &&
                    <button style={{ marginTop:'4em' }} className="dialog-answer-button" onClick={(e) => continueSurvey(e)} >{locals.continue}</button>
                }
            </div>
        </>
    )
}