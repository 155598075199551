import * as ActionTypes from '../actions/surveyActions/actionTypes';
import { confs } from '../../bootstrap/config'

import defaultLogo from '../../assets/images/qibbie_logo.png'

const initialState = {
  userDialog: null,
  answerSessionId: null,
  activeLogo: `url(${defaultLogo})`
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.GET_SURVEY:
      return {
        ...state,
        userDialog: action.payload.surveys,
        answerSessionId: action.payload.sessionId,
        activeLogo: `url(${confs.host}/api/business/getSenderLogo/${action.payload.surveys.sentBy})`
      }
    case ActionTypes.END_SURVEY:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.SET_NEW_ID:
      return {
        ...state,
        answerSessionId: action.payload
      }
    case ActionTypes.SET_NEW_SURVEY_ID:
      return {
        ...state,
        userDialog: {
          ...state.userDialog,
          _id: action.payload
        }
      }
    
    default:
      return state;
  }
}