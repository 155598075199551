import React, {useState, useRef, useEffect} from 'react';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import './checklist.css';
import { confs } from '../../../../bootstrap/config';
import {NotificationManager} from 'react-notifications';

let baseUrl = confs.url

export const ChecklistBlock = ({item, planId, locals, plan}) => {

    const [checklist, setChecklist] = useState(item.checklist? item.checklist : [])

    const handleCheckItemChange = (props) => {
        const {id, value, index} = props
        if(plan && !plan.isSigned && !plan.allSigned){
            if(checklist !== undefined && checklist.length > 0){
                let list = checklist
                let updatedList = list.map((checkItem, key) => {
                    if(key === index){
                        let newContent = {
                            ...checkItem,
                            [id]: value
                        }
                    return newContent
                    }
                    return checkItem
                })  
                setChecklist([...updatedList])
                saveChecked(updatedList)
            }
        }
        else{
            NotificationManager.error(locals.cannotCheckItem, locals.error ,7000)
        }
    }

    const saveChecked = async(list) => {
        if(plan && !plan.isSigned && !plan.allSigned){
            let payload = {
                planItemId: item.id,
                checklist: list,
                planId: planId
            }

            try {
                const responseJson = await fetch(baseUrl + 'checklistUpdate', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                })
        
                if(responseJson) {
                    const response = await responseJson.json()
                            
                    if(response.success){
                        try{
                            NotificationManager.success(locals.planSaved , locals.notice, 2000);
                        } catch {}
                    } else {
                        if(response.checklist){
                            let list = response.checklist
                            setChecklist([...list])
                        }
                        NotificationManager.error(locals.cannotCheckItem, locals.error ,7000)
                    }

                } else {
                    return false;
                }
            } catch(error) {
                return Promise.reject(error);
            }
        } 
    }
    
    return (
        <>
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12" style={{paddingTop: '0.8em'}} className="plan-text-col">
                    {checklist !== undefined && checklist.length > 0 && checklist.map((checkItem, key)=>{
                        return(
                            <div key={key} style={{ marginTop: '1em' }}>
                                <div className="check-item-row">
                                    <Col xs="1" style={{ height: '100%' }}>
                                    {(item.allowChecking !== undefined && item.allowChecking) ?       
                                        <input type="checkbox" className="check-item-box" onTouchEnd={() => handleCheckItemChange({id:"checked", value: !checkItem.checked, index: key})} onChange={() =>  handleCheckItemChange({id:"checked", value: !checkItem.checked, index: key})} checked={checkItem.checked}/>
                                        :
                                        <input type="checkbox" className="check-item-box" readOnly={true} checked={checkItem.checked}/>
                                    }
                                    </Col>
                                    <Col xs="11" style={{ height: '100%', paddingLeft: '0' }}>
                                        <div style={{ display:'flex', alignItems:'center', minHeight:'1.75em' }}>
                                            <p style={{ marginBottom: '0rem' }}>{checkItem.title}</p>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </>
    )
}