import React from 'react';

import Select from 'react-select';
import {Row, Col} from 'reactstrap';
import locals from '../../../../../locals/locals';


const reportTypeList = [
    {
        value: 'barQuestions',
        label: locals.questionView
    },
    {
        value: 'bar',
        label: locals.groupView
    }
]

const ViewChanger = ({setReportType, reportType}) => {
    return (
        <Col xl={{size: 4}} xs={{size: 12}}>
            <Row>
                <Col xs={{size: 12}}>
                    <p>{locals.reportView}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={{size: 8}}>
                    <Select value={getReportTypeObject(reportType)}
                        onChange={item => setReportType(item.value)}
                        options={reportTypeList.filter(x => x.value !== reportType)}
                        placeholder="Valitse raporttityyppi"
                        isDisabled={false}
                        noOptionsMessage={() => {return "Raporttityyppejä ei löytynyt"}}
                        maxMenuHeight={200}
                        className='react-select-container'
                        classNamePrefix="react-select"
                    />
                </Col>
            </Row>
        </Col>
    )
}

export const MobileViewChanger = ({setReportType, reportType}) => {
    return (
        <div>
            <label htmlFor="selectType">{locals.reportView}</label>
            <div id="selectType">
                <Select value={getReportTypeObject(reportType)}
                        onChange={item => setReportType(item.value)}
                        options={reportTypeList.filter(x => x.value !== reportType)}
                        placeholder="Valitse raporttityyppi"
                        isDisabled={false}
                        noOptionsMessage={() => {return "Raporttityyppejä ei löytynyt"}}
                        maxMenuHeight={200}
                        className='react-select-container'
                        classNamePrefix="react-select"
                />
            </div>
        </div>
    )
}

export default MobileViewChanger;

const getReportTypeObject = reportType => {
    switch(reportType) {
        case 'barQuestions':
            return {
                value: 'barQuestions', 
                label: locals.questionView
            }
        case 'bar':
            return {
                value: 'bar', 
                label: locals.groupView
            }
        default:
            return {
                value: 'bar', 
                label: locals.questionView
            }
      }
}