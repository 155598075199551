import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Col,
  Label
} from 'reactstrap';
import CodeInput from '../modules/CodeInput';
import * as ActionCreators from '../../store';
import './login.css';
import QibbieRobot from '../modules/QibbieRobot';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      passwordForgotten: false,
      loginToBeta: false
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onCodeSubmit = this.onCodeSubmit.bind(this);
    this.onPasswordForgottenSubmit = this.onPasswordForgottenSubmit.bind(this);
  }

  componentDidUpdate() {
    if(this.props.user.redirectUrl) {
      if(this.props.user.userId) {
        localStorage.setItem('userId', this.props.user.userId);

        if(localStorage.getItem('userId') !== undefined) {
          return window.location.href = this.props.user.redirectUrl;
        }
      }
      return window.location.href = this.props.user.redirectUrl;
    }
  }

  componentWillUnmount() {
    //tässä vois tyhjentää storesta kirjautuneen henkilön?
  }

  onCodeSubmit(code) {
    this.setState({codeLoginInProgress: true});
    let obj = {
      code: code,
      email: this.props.user.loginEmail,
      clientType: this.props.user.clientType
    }

    this.props.onCodeLogin(obj);
    
  }

  onSubmit(e) {
    e.preventDefault();
    const loginData = {
      email: this.state.email,
      password: this.state.password,
      loginToBeta: this.state.loginToBeta,
      userId: localStorage.getItem("userId")
    }

    this.props.onLogin(loginData);
  }


  onPasswordForgottenSubmit(e) {
    e.preventDefault()
    if(this.state.email !== '') {
      this.props.onPasswordForgotten(this.state.email)
      this.setState({passwordForgotten: false})
    }
  }

  render() {
    const {locals} = this.props
    return (
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <div className="col-md-6 login-box">
              <div className="login-inner-container">
                {/* <div className="logo">
                  </div> */}
                {this.state.passwordForgotten ? (
                  <div>
                    <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                    <div className="login-box-header" style={{paddingTop:'1em'}}>{locals.restorePassword}</div>
                      <Row className="h-100">
                        <Col>
                          <Label>{locals.email}</Label>
                          <Input onChange={(e) => this.setState({email: e.target.value})} className="form-control form-control-lg" name="email" value={this.state.email} placeholder="" type="text"></Input>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'2em'}}>
                        <Col>
                          <button className="small-white-button confirm-button" style={{width:'100%'}}  onClick={this.onPasswordForgottenSubmit}>{locals.restorePassword}</button>
                        </Col>
                      </Row>

                    <p className="password-forgotten go-back-link" style={{ marginTop:'1.5em' }} onClick={() => this.setState({passwordForgotten: false})}>{locals.goBack}</p>
                  
                  </div>
                ) : (
                  this.props.user.loginSuccessful && this.props.user.loginEmail && this.props.user.clientType ? (
                    <div>
                      <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                      <h2 className="text-center" style={{color: ''}}>{locals.insertCode}</h2>
                      <CodeInput onSubmit={this.onCodeSubmit} email={this.props.user.loginEmail} clientType={this.props.user.clientType} codeLoginInProgress={this.props.user.codeLoginInProgress} locals={locals}/>
                    </div>
                  ) : (
                    <div>
                      <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                      <Row className="h-100">
                        <Col>
                          <div style={{marginTop:'0.5em'}}>
                            <Label>{locals.email}</Label>
                            <Input onChange={(e) => this.setState({email: e.target.value})} className="form-control form-control-lg" name="email" value={this.state.email} placeholder="" type="text"></Input>
                          </div>
                          <div style={{marginTop:'1em'}}>
                            <Label>{locals.password}</Label>
                            <Input onChange={(e) => this.setState({password: e.target.value})} className="form-control form-control-lg" placeholder="" type="password"></Input>
                          </div>
                        </Col>
                        {/* <div className="form-check mb-2">
                          <label className="form-check-label" htmlFor="loginToBeta">
                            Kirjaudu betaan
                            <input onChange={(e) => this.setState({loginToBeta: e.target.checked})} className="form-check-input" type="checkbox" name="loginToBeta" checked={this.state.loginToBeta} />
                          </label>
                        </div> */}
                        {/* <FormGroup>
                          <Button onClick={(e) => this.onSubmit(e)} type="submit" className="btn btn-lg login-button">{locals.logIn}</Button>
                        </FormGroup> */}
                      </Row>
                      <Row style={{marginTop:'2em'}}>
                        <Col>
                          <button className="small-white-button confirm-button" style={{width:'100%'}} onClick={(e) => this.onSubmit(e)}>{locals.logIn}</button>
                        </Col>
                      </Row>
                      <p className="password-forgotten go-back-link" onClick={() => this.setState({passwordForgotten: true})} style={{ cursor:'pointer', marginTop:'1.5em'}}>{locals.forgotPassword}</p>
                    </div>
                  )
                )}
              </div>
            </div>
          </Row>
        </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (user) => dispatch(ActionCreators.login(user)),
    onCodeLogin: (obj) => dispatch(ActionCreators.codeLogin(obj)),
    onPasswordForgotten: (email) => dispatch(ActionCreators.onPasswordForgotten(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);