import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Container, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import TextEditor from '../../modules/TextEditor/texteditor'
import ReportItem from '../modules/reports/ReportItem';
import {TableItem} from '../modules/tables/TableItems';
import {ProductBlock} from './Product/ProductBlock';
//Embeds
import ExcelBlock from './embeds/ExcelBlock';
import ListBlock from './embeds/ListBlock';
import TrelloBlock from './embeds/TrelloBlock';
import QlikIframe from './embeds/QlikIframe';

import '../plan.css'
import { AttachmentBlock } from './attachment/AttachmentBlock'
import { SignatureBlock } from './Signature/SignatureBlock'
import { RiskBlock } from './risks/RiskBlock'
import { ChecklistBlock } from './Checklist/ChecklistBlock'
import { BusinessExcelBlock } from './BusinessExcel/BusinessExcelBlock'
import { GanttBlock } from './Gantt/GanttBlock'

import { confs } from '../../../bootstrap/config'
import { setIndex } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { AnnotationTool } from './Annotation/AnnotationTool';
import { setCustomStyles } from '../../../store/actions/planActions/actionCreator';
import { TrelloTimelineBlock } from './TrelloTimeline/TrelloTimelineBlock';

export const BlockInWindow = (props) => {
    const [selectedPlan, setPlan] = useState(null)
    const [planItem, setPlanItem] = useState()
    const [riskItems, setRiskItems] = useState([])
    const [index, setIndex] = useState(null)
    const [blockTitle, setBlockTitle] = useState("")
    const [forceUpdate, setForceUpdate] = useState(true)
    const [annotationModal, setAnnotationModal] = useState(false)
    const itemId = props.match.params.id

    useEffect(()=>{
        if(!selectedPlan && props.match.params.id !== undefined ){
      
            const planId = props.match.params.plan
            getPlan(planId)
        }
      
    },[props])
    
    useEffect(()=>{
      if(planItem){
        let title = planItem.topicContent? planItem.topicContent : planItem.blockName
        setBlockTitle(title)
        // updateForceStatus(false)
      }
    },[planItem])
    const updateForceStatus = (status) => {
      setForceUpdate(status)
    }
    const getPlan = (planId) => {
       
        return new Promise(async (resolve) => {
            let params =   {
                planId: planId,
                initial: true
            }
        
            let getWithPassword = false
        
            const resultJson = await fetch(confs.url + 'getPlanForNewWindow', {
                method: 'POST',
                body: JSON.stringify(params)
            })
        
            const result = await resultJson.json()
        
            if (result.selectedPlan) {
                setPlan(result.selectedPlan.plan)
                if(result.riskItems){
                    setRiskItems(result.riskItems)
                }
                if(result.customStyles){
                  setCustomStyles(result.customStyles)
                }
          
                resolve(true)
            } else {
                resolve()
            }
        })
    }

    useEffect(()=>{
        if(!planItem){
            getPlanItem()
        }
    },[selectedPlan])

    const getPlanItem = () => {
        if(selectedPlan){
            selectedPlan.planItems.map((item, index) => {
                if(item.id === itemId){
                    setIndex(index)
                    setPlanItem(item)
                }
            })
        }
    }

    const handleIndexChange = (change) => {
      let newIndex = index+change
      if(newIndex <= 0){
        newIndex = 0
      }
      if(newIndex >= selectedPlan.planItems.length-1){
        newIndex = selectedPlan.planItems.length-1
      }
      setIndex(newIndex)
      let newItem = selectedPlan.planItems[newIndex]
      setPlanItem(newItem)
      setForceUpdate(true)
    }

    const checkItemType = planItem => {
        switch (planItem.type) {
          case 'iFrame':
            return (
              <div className="plan-paragraph" style={{ display: 'flex', justifyContent: 'center' }}>
                {planItem.qlik ? <QlikIframe data={planItem} hostName={planItem.hostName} style={planItem.size} /> : <iframe src={planItem.hostName} frameBorder="0" style={planItem.size || { height: 650, width: '100%' }}></iframe>}
              </div>
            );
          case 'report':
            return (
              <ReportItem planId={selectedPlan._id} id={planItem.targetSurvey !== undefined ? planItem.targetSurvey.id : null} locals={props.locals} item={planItem} />
            )
          case 'Trello':
            return (
              <TrelloBlock
                item={planItem}
                itemId={planItem.targetId}
                businessId={selectedPlan.selectedBusiness.value}
                planId={selectedPlan._id}
              />
            )
          case 'TrelloTimeline':
              return (
                <TrelloTimelineBlock
                  item={planItem}
                />
              )
          case 'tables':
              return (
                <TableItem table={planItem}  needToForceUpdate={forceUpdate}/>
              )
          case 'Excel':
            return (
              <ExcelBlock
                item={planItem}
                itemId={planItem.targetId}
                planId={selectedPlan._id}
              />
            )
          case 'list':
            return (
              <ListBlock item={planItem}
                itemId={planItem.targetId}
                planId={selectedPlan._id} />
            )
          case 'product':
            return(
                <ProductBlock
                  item={planItem}
                  planId={selectedPlan._id}
                />
            )
          case 'attachment':
            return(
                <AttachmentBlock
                  item={planItem}
                  planId={selectedPlan._id}
                  locals={props.locals}
                />
            )
          case 'signature':
            return(
                <SignatureBlock
                  item={planItem}
                  planId={selectedPlan._id}
                  locals={props.locals}
                />
            )
          case 'risks':
            return(
                <RiskBlock
                  item={planItem}
                  planId={selectedPlan._id}
                  locals={props.locals}
                //   riskItems={riskItems}
                />
            )
          case 'checklist':
            return(
                <ChecklistBlock
                  item={planItem}
                  planId={selectedPlan._id}
                  locals={props.locals}
                  plan={selectedPlan}
                />
            )
          case 'businessExcel':
            return(
                <BusinessExcelBlock
                  item={planItem}
                  // locals={props.locals}
                  // plan={selectedPlan}
                />
            )
            case 'gantt':
            return(
              <GanttBlock
                item={planItem}
                planId={selectedPlan._id}
                locals={props.locals}
              />
            )
          default:
            return (
              <TextEditor additionalClass='plan-paragraph' content={planItem.textContent} readOnly={true} onTextChange={() => { return }} needToForceUpdate={forceUpdate}/>
            )
        }
      }

    return(
      <>
        <Col xs={{size: annotationModal? 3:1}}>
          {selectedPlan && selectedPlan.allowAnnotation &&
            <AnnotationTool 
              annotationList={selectedPlan.annotations || []}
              planId={selectedPlan._id}
              addModalOpen={annotationModal}
              setAddModalOpen={setAnnotationModal}
              locals={props.locals}
            />
          }
        </Col>
        <Col id="plan-col" xs={{ size: (selectedPlan && selectedPlan.allowAnnotation) ? (annotationModal ? 9 : 11) : 12 }}>
            <div id="plan-container" style={{backgroundColor:'#fff', minHeight:( selectedPlan && selectedPlan.allowAnnotation)?'39em' : 0, padding:'1.5em', margin:'1em', borderRadius:'10px'}}>
               {selectedPlan && 
                  <div style={{ marginBottom: '-2em', width:'100%', marginRight: '1em', display:'flex', justifyContent:'end'}}>
                    <button className={'small-white-button wide-button ' + (index === 0 ? 'disable-button':'')} disabled={index === 0 ? true : false} style={{marginRight:'1em'}} onClick={()=> handleIndexChange(-1)}><i className="fas fa-arrow-left"></i> <span style={{ marginLeft:'0.5em'}}>{props.locals.previous}</span></button>
                    <button className={'small-white-button wide-button ' + (index === selectedPlan.planItems.length-1 ? 'disable-button':'')} disabled={(index === selectedPlan.planItems.length-1) ? true : false} onClick={()=> handleIndexChange(1)}><span style={{ marginRight:'0.5em'}} >{props.locals.next}</span><i className="fas fa-arrow-right"></i> </button>
                  </div>
                }
                {planItem &&
                    <div className="plan-item-container"  style={{ marginBottom: '2em' }}>
                        <div style={{ display: 'flex', marginBottom:'1em' }}>
                            { blockTitle &&
                              <TextEditor 
                                additionalClass='plan-header custom-plan-heading' 
                                content={blockTitle} 
                                readOnly={true} 
                                onTextChange={() => { return }}  
                                needToForceUpdate={forceUpdate}
                                setForceUpdate={setForceUpdate} 
                              />
                            }
                        </div>
                        {checkItemType(planItem)}
                    </div>
                }
            </div>
        </Col>
      </>
    )
}