import React from 'react'
import { NavLink } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem
} from 'reactstrap';
import { connect } from 'react-redux'

import './navbar.css';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleNavExpand = this.toggleNavExpand.bind(this);
  }

  toggleNavExpand() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  getLinkText = to => {
    switch(to) {
      case '/home':
       return this.props.locals.toSurvey
      case '/login':
        return this.props.locals.logIn
      case '/register':
        return this.props.locals.register
      default:
        return ""
    }
  }

  render() {
    return (
      <span>
        <Navbar expand="md" light>
          <div className="navbar-brand">
            <NavLink to='/'><div className="qibbie-logo" style={{ backgroundImage: this.props.activeLogo }}></div></NavLink>
          </div>
          <div className="" style={{ marginLeft: '3em', fontSize:'1.5rem', color:"#777777" }}>{'EverBetter'}</div>
          <NavbarToggler onClick={this.toggleNavExpand}>
            <i className="material-icons">menu</i>
          </NavbarToggler>
  
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar style={{backgroundColor: '#FFF'}}>
            <NavItem className="nav-item ml-auto" style={this.props.language === 'fi' ? {fontWeight: 700} : {cursor: 'pointer'}} onClick={() => {this.props.changeLanguage('fi'); this.toggleNavExpand();}}>
              <span className="nav-link">FI</span>
            </NavItem>
            <NavItem className="nav-item ml-auto" style={this.props.language === 'en' ? {fontWeight: 700} : {cursor: 'pointer'}} onClick={() => {this.props.changeLanguage('en'); this.toggleNavExpand();}}>
              <span className="nav-link">EN</span>
            </NavItem>
            <NavItem className="nav-item ml-auto"><a className="nav-link" href="https://www.everbetter.fi">{this.props.locals.aboutUs}</a></NavItem>
              {this.props.links && this.props.links.map((broLink, i) => {
                return <NavItem key={i} className="nav-item ml-auto" onClick={() => this.toggleNavExpand()}>
                          <NavLink className="nav-link" to={broLink.to} exact activeClassName='active-nav'>{this.getLinkText(broLink.to)}</NavLink>
                        </NavItem>
              })}
            </Nav>
          </Collapse>
  
        </Navbar>
      </span>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeLogo: state.surveys.activeLogo
  }
}

export default connect(mapStateToProps, null)(NavigationBar)