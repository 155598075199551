import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {NotificationManager} from 'react-notifications';
import locals from '../../../locals/locals'

export const Dropzone = ({setFile}) => {
    const {
        getRootProps, 
        getInputProps, 
        isDragActive,
        acceptedFiles,
        fileRejections
    } = useDropzone({ 
        maxFiles:1,
        maxSize: 16000000,
        accept: 'image/*, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .xlsb, .xltx, .txt, .ppt, .pptx, .pptm',
    })

    const acceptedFileItems = acceptedFiles.map(file => ( 
        <p key={file.path}>
            {file.path} <span style={{ fontSize: '0.8rem' }}>- {file.size} bytes</span>
        </p>
    ))
    
    useEffect(() => {
        if(acceptedFiles && acceptedFiles.length > 0){
            setFile(acceptedFiles[0])
            NotificationManager.success("Liite hyväksytty", "Hyväksytty", 2000);
        }
        if (fileRejections && fileRejections.length > 0) {
            let rejectedFile = fileRejections[0]
            rejectedFile.errors.map(e => {
                if(e.code === "file-too-large"){
                    NotificationManager.error("Tiedosto liian suuri", "Virhe", 4000);
                }
                if(e.code === "file-invalid-type"){
                    NotificationManager.error("Tiedostomuoto ei ole hyväksytty", "Virhe", 4000);
                }
            })
        }
    },[acceptedFiles, fileRejections])
    return (
        <div className="drop-container">
            <div {...getRootProps({className:"drop-zone"})}>
                <input {...getInputProps()} />
                
                {isDragActive ?
                    <>
                        <i style={{ fontSize: "3rem", opacity: '0.8' }}  className="far fa-folder-open"></i>
                        <p>{locals.dropzoneFileInfo}</p>
                    </>
                    :
                    (acceptedFiles && acceptedFileItems.length > 0?
                        <>
                            <i style={{ fontSize: "3rem", opacity: '0.8' }} className="far fa-file-alt"></i>
                            {acceptedFileItems}
                        </>
                        :
                        <>
                            <i style={{ fontSize: "3rem", opacity: '0.8' }} className="far fa-folder"></i>
                            <p>{locals.dropzoneFileInfo}</p>
                        </>
                    )
                    
                } 
                
            </div>
           {fileRejections && fileRejections[0] && fileRejections[0].errors.map(e => {
                if(e.code === "file-invalid-type"){
                    return(
                        <>
                            <p>Hyväksytyt tiedostomuodot:</p>
                            <p>{".png, .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .xlsb, .xltx, .txt, .ppt, .pptx, .pptm"}</p>
                        </>
                    )
                }
                if(e.code === "file-too-large"){
                    return(
                        <p>Tiedoston maksimi koko on 16Mb </p>
                    )
                }
            })}
        </div>
    )
}