import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    fi: {
        greetings:"Hei!",
        continue: 'Jatka',
        toContinue: 'Jatketaan',
        close: 'Sulje',
        back: 'Takaisin',
        goBack: 'Palaa takaisin',
        welcome: 'Tervetuloa käyttäjäksi!',
        start: 'Aloita palvelun käyttö',
        aboutUs: 'Tietoa meistä',
        toSurvey: 'Vastaa kartoitukseen',
        logIn: 'Kirjaudu sisään',
        register: 'Rekisteröidy',
        email: 'Sähköposti',
        password: 'Salasana',
        confirmPassword: 'Vahvista salasana',
        newPassword: 'Uusi salasana',
        changePassword: 'Vaihda salasana',
        logInButton: 'Kirjaudu',
        forgotPassword: 'Salasana unohtunut?',
        insertCode: 'Syötä koodi',
        companyInfo: 'Yritystiedot',
        businessName: 'Yrityksen nimi',
        businessAddress: 'Osoite',
        yTunnus: 'Y-Tunnus',
        businessPostCode: 'Postinumero',
        businessPostAddress: 'Postitoimipaikka',
        contactPerson: 'Yhteyshenkilö',
        phone: 'Puhelinnumero',
        agreeTerms: 'Hyväksyn käyttöehdot',
        cookieMessage: 'Verkkosivustollamme käytetään evästeitä käyttäjäkokemuksen parantamiseksi. Hyväksyt evästeiden käytön käyttämällä sivustoamme',
        confirmEmailPass: 'Vahvista sähköpostiosoitteesi ja valitse palvelussa käytettävä salasana.',
        registrationSuccess: 'Rekisteröityminen onnistui! Voit nyt kirjautua sisään.',
        notice: 'Ilmoitus',
        error: 'Virhe',
        missingFields: 'Täytä puuttuvat tai virheelliset kohdat',
        allFieldsRequired: 'Kaikki kentät ovat pakollisia!',
        emailRequired: 'Sähköposti on pakollinen kenttä',
        passwordNotMatching: 'Salasanat eivät täsmää',
        robotTitle: 'Tervetuloa vastaamaan kartoitukseen!',
        robotMessage: 'Syötä sinulle toimitettu koodi vieressä olevaan kenttään ja pääset täyttämään kartoitusta. Kiitos jo etukäteen vastaamisesta.',
        checkRequestTask: 'Tarkastetaan tapahtumaa...',
        requestFoundTask: 'Tapahtuma löydetty, vaihdetaan tilaa...',
        requestErrorTask: 'Tehtävää ei löydetty',
        taskStatusDone: 'Tehtävän tila muutettu suoritetuksi.',
        taskStatusCancelled: 'Tehtävä on peruttu onnistuneesti.',
        taskAlreadyDone: 'Tehtävä on jo suoritettu.',
        taskAlreadyCancelled: 'Tehtävä on jo peruttu.',
        loading: 'Ladataan...',
        becauseOfAnswerDialogDone: 'Vastauksen perusteella dialogi on päätetty.',
        becauseOfAnswerDialogChange: 'Vastauksen perusteella siirrytty toiseen dialogiin',
        becauseofAnswerSurveyChange: 'Kartoitus vaihdettu vastauksen perusteella',
        fetchPlan: 'Haetaan lomaketta...',
        noPlansFound: 'Lomaketta ei löytynyt',
        planRedirect: 'Sinut ohjataan etusivulle 5 sekunnin kuluttua',
        wrongPassword: 'Salasana väärin',
        inputPrompt: 'Syötä',
        planPasswordButton: 'Lähetä',
        client: 'Asiakas',
        recipient: 'Vastaanottaja',
        summary: 'Sisällysluettelo',
        riskEvaluationSummary: 'Riskiarvion yhteenveto',
        riskClassification: 'Luokittelu',
        riskManagement: 'Riskienhallintatoimi',
        explanation: 'Selite',
        priority: 'Prioriteetti',
        taskEvaluationSummary: 'Tehtävän yhteenveto',
        title: 'Otsikko',
        reminderDate: 'Muistutuspäivä',
        responsible: 'Vastuuhenkilöt',
        fetchResults: 'Haetaan tuloksia',
        noResults: 'Tuloksia ei vielä ole',
        reportView: 'Raporttinäkymä',
        groupView: 'Ryhmä',
        questionView: 'Kysymys',
        feedbackEnded: 'Kiitos vastauksesta.',
        siteRefresh: 'Sivusto päivitetään uudestaan hetken kuluttua',
        surveyThanks: 'Kiitos vastauksistanne! Voit nyt sulkea selaimen tai palata etusivulle allaolevasta napista.',
        clickForInfo: 'Klikkaa lukeaksesi lisätietoa kysymyksestä',
        surveyHomePageButton: 'Etusivulle',
        emailRequestMessage: 'Anna vielä sähköpostiosoitteesi, jotta voin lähettää sinulle henkilökohtaisen loppupalautteen keskustelustamme.',
        answerButton: 'Vastaa',
        confirmDate: 'Vahvista päivämäärä',
        selectDate: 'Valitse päivämäärä',
        slideBar: 'Vieritä palkkia',
        moreInfo: 'Lisätietoa',
        answerPlaceHolder: 'Kirjoita vastauksesi tähän',
        emailAnswerPlaceHolder: 'Kirjoita sähköpostiosoitteesi tähän',
        answerOptionText: 'Valitse sopivin vaihtoehto',
        noDateChosen: 'Ei valittua päivämäärää',
        welcomeBack: 'Tervetuloa takaisin. Jatketaanpa siitä mihin jäätiin.',
        dialogBotText: 'Kirjoittaa',
        askToContinue: 'Havaitsin selaimellasi kesken jääneen kartoituksen. Haluaisitko jatkaa siitä mihin jäit?',
        startNew: 'Aloita uusi',
        continueTitle: 'Kartoituksen jatkaminen',
        attachmentName: 'Tiedoston nimi',
        typeErrorMessage: 'Vastaanotettu tiedostomuoto ei ole sallittu tai tiedostonnimi sisältää sallimattomia merkkejä!',
        published: 'Luotu',
        dropzoneFileInfo:'Pudota tiedosto tänne tai avaa tiedoston valinta klikkaamalla',
        printPdfTitle:'Tulosta PDF',
        freetextPlaceholder: 'Voit kirjoittaa vastauksesi tähän',
        fileRegexError: 'Tiedoston nimessä on erityismerkkejä, joita ei hyväksytä liitteen nimeen. Hyväksyttyjä ovat ( ) _ .',
        metadataText:'EverBetter on asiakaskokemusten johtamisen verkkopalvelu, jonka avulla voit ylläpitää jatkuvaa keskusteluyhteyttä asiakkaisiin ja palveluntarjoajiin. Se kattaa monipuolisesti organisaatiosi sekä tärkeimpien sidosryhmien kohtaamispisteet ja niihin liittyvät toimenpiteet. Voit luoda jatkuvan, mitattavan ja läpinäkyvän suhteen niin asiakkaisiisi kuin palveluntarjoajiin.',   
        scrollToTop: 'Takaisin ylös',
        modifiedSuccesfully: 'Muokattu onnistuneesti!',
        codeSentToEmail: 'Koodi lähetetty sähköpostiin!',
        date:'Päiväys',
        signPlan: 'Allekirjoita',
        name: 'Nimi',
        business: 'Yritys',
        email: 'Sähköpostiosoite',
        invalidEmail: 'Syötä sähköpostiosoite oikeassa muodossa',
        checkInfo: 'Ole hyvä ja tarkista vielä, että tietosi ovat oiken.',
        signGuide: 'Saat kohta sähköpostiisi viisinumeroisen koodin. Saatuasi koodin, ole hyvä ja syötä saamasi koodi alla oleviin kenttiin.',
        planCodeDidntArrive: "Lähetä minulle uusi koodi!",
        waitingForCode: 'Huomioithan, että sähköpostin saapumisessa voi mennä hetki aikaa. Tarkistathan myös varmuuden vuoksi roskapostisi! Mikäli et ole saanut koodia ole hyvä ja paina alla olevaa linkkiä.',
        cancel: 'Peruuta',
        signInfoCorrect: 'Varmenna',
        emailSignatureTitle: 'Allekirjoituksen vahvistaminen.',
        emailSignatureTopic: 'Allekirjoittaaksesi lomakkeen ole hyvä ja kirjoita alla näkyvät viisi merkkiä lomakkeen allekirjoitusikkunaan. Mikäli et tilannut kirjautumiskoodia, olethan yhteydessä lomakkeen laatijaan.',
        digitalSignature: 'Digitaalinen allekirjoitus',
        wrongVerificationCode: 'Virhe varmistaessa koodia! Ole hyvä ja yritä uudelleen. Tarvittaessa ole hyvä ja ota yhteyttä lomakkeen lähettäjään.',
        codeExpired: 'Koodi on vanhentunut, ole hyvä ja tilaa uusi koodi painamalla "Lähetä minulle uusi koodi!"-linkkiä',
        language: 'fi',
        addAttachment: 'Lisää liite',
        save: 'Tallenna',
        fileName: 'Liitteen nimi',
        selectAttachment: 'Valitse liite',
        onFileSubmit: 'Liitteet vastaanotettu',
        planLastSaved: 'Tallennettu',
        businessIdRequired: 'Y-tunnus vaaditaan',
        surveySubmitButton: 'Lähetä vastaukset',
        comment: 'Välikommentti',
        confirmSubmission:'Varmista lähetys',
        errorOccurred: 'Tapahtui virhe!',
        sheetConfirmationText: "Varmista, että tahdot lähettää vastaukset. (Vastauksia ei voi muokata lähettämisen jälkeen)",
        sendFeedbackToEmail: 'Lähetä palaute sähköpostiin',
        sendFeedback: 'Lähetä sähköpostiin',
        emailSentSuccess: 'Sähköpostin lähetys onnistui',
        emailSentError: 'Virhe lähettäessä sähköpostia',
        linkCopied: 'Linkki kopioitu',
        copyLink: 'Kopioi linkki',
        optionRequired: 'Kaikkiin monivalintakysymyksiin ei ole vastattu. Jatkaaksesi ole hyvä ja vastaa jokaiseen monivalintakysymykseen.',
        download: 'Lataa',
        preview: 'Esikatsele',
        delete: 'Poista',
        removingComment: 'Olet poistamassa kommenttia! Tätä toimintoa ei voi perua!',
        removingCommentBanner:'Olet poistamassa kommenttia',
        commentHere: 'Kirjoita kommentti tähän...',
        nameTag:'Nimimerkki',
        addCommentButton: 'Lisää',
        planSaved: "Tallennettu onnistuneesti",
        cannotCheckItem: 'Tehtävän merkintää ei voida muuttaa, sillä lomakkeessa on jo vähintään yksi allekirjoitus.',
        verifiedByEB: 'Todennettu EverBetterin toimesta',
        signatureVerification: 'Allekirjoitusten todennus',
        signed: 'Allekirjoitettu',
        notSigned: 'Ei allekirjoitettu',
        signatureIdentifier: 'Allekirjoituksen tunniste',
        timestamp: "Aikaleima",
        here: 'tästä',
        signatureVerificationText: 'Voit varmentaa allekirjoitukset: ',
        noEmailError: 'Sähköpostia ei löytynyt ja täten allekirjoitusta ei voida vahvistaa. Ole hyvä ja ota yhteyttä lomakkeen luoneeseen tahoon.',
        notAllowedError: 'Toimintoa ei sallittu. Muutoksia ei tallenneta.',
        ganttName:'Nimi',
        ganttStartDate: 'Alku pvm',
        ganttEndDate: 'Tavoite pvm',
        search:'Haku',
        showHideTags:'Näytä tai piilota tagit',
        tags:'Tagit',
        surveyEndedEmailInfo: 'Tästä voit lähettää kartoituksen loppupalautteen itsellesi sähköpostiin.',
        emailSent:'Sähköposti on lähetetty',
        ganttViewOptions:[
            {label: 'Päivä', value: 'Day'},
            {label: 'Viikko', value: 'Week'},
            {label: 'Kuukausi', value: 'Month'},
            {label:'Vuosi', value:'Year'}
        ],
        openInNewWindow:'Avaa uuteen ikkunaan',
        enterpriseRegister: {
            privateUserButton:'Yksityishenkilö',
            businessUserButton: 'Yrityskäyttäjä',
            loginDetails: "Kirjautumistiedot",
            newEnterpriseDetails: "Yrityksen tiedot",
            privateUserDetails: "Käyttäjän tiedot",
            registerButton: "Rekisteröidy",
            newEnterpriseHeader: "Uusi enterprise",
            privateUserName:'Käyttäjänimi (tämä näytetään "yrityksen nimenä")',
            createError:'Virhe rekisteröinnissä',
            createSuccess: 'Rekisteröinti onnistui',
            businessExists: "Yritys on jo olemassa",
            userExists: "Käyttäjä on jo olemassa",
            termsOfUse1:"Hyväksyn ",
            termsOfUse2:"käyttöehdot",
            termsText: "Tässä ovat käyttöehdot... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        restorePassword:'Palauta salasana',
        next:'Seuraava',
        previous: 'Edellinen',
        Trello:{
            goToToday: 'Siirry tähän päivään',
            startDate: 'Alku pvm',
            endDate: 'Loppu pvm',
            resetDates: 'Tuhjennä',
            update: 'Päivitä',
            showAmount: 'Näytä näkymässä',
            locale: 'fi',
            closeModal: 'Sulje'
        }
    },
    en: {
        greetings:"Hi!",
        continue: 'Continue',
        toContinue: 'Continue',
        close: 'Close',
        back: 'Back',
        goBack: 'Go back',
        welcome: 'Welcome!',
        start: 'To service',
        aboutUs: 'About us',
        toSurvey: 'Surveys',
        logIn: 'Log in',
        register: 'Register',
        email: 'E-mail',
        password: 'Password',
        confirmPassword: 'Confirm password',
        newPassword: 'New password',
        changePassword: 'Change password',
        logInButton: 'Log in',
        forgotPassword: 'Forgot password?',
        insertCode: 'Insert code',
        companyInfo: 'Business Info',
        businessName: 'Company name',
        businessAddress: 'Address',
        yTunnus: 'Business ID',
        businessPostCode: 'Post code',
        businessPostAddress: 'Post office',
        contactPerson: 'Contact person',
        phone: 'Phone number',
        agreeTerms: 'I agree to terms of use',
        cookieMessage: 'We use cookies to improve user experience. You accept cookies if you continue using the site',
        confirmEmailPass: 'Confirm your e-mail and create password.',
        registrationSuccess: 'Registration succesful! You can now log in.',
        notice: 'Notice',
        error: 'Error',
        missingFields: 'Fields are missing or not valid',
        allFieldsRequired: 'All the fields are required!',
        emailRequired: 'E-mail is required',
        passwordNotMatching: 'Passwords do not match',
        robotTitle: "Welcome!",
        robotMessage: 'Insert survey code that you have received to the fields and you will be taken to the survey. Thank you in advance for your answers.',
        checkRequestTask: 'Checking request...',
        requestFoundTask: 'Task found, changing status...',
        requestErrorTask: 'Could not find requested task',
        taskStatusDone: 'Task status was changed to done.',
        taskStatusCancelled: 'Task was succesfully cancelled.',
        taskAlreadyDone: 'Task status is already done.',
        taskAlreadyCancelled: 'Task has already been cancelled.',
        loading: 'Loading...',
        becauseOfAnswerDialogDone: 'Selected answer ended the dialog.',
        becauseOfAnswerDialogChange: 'Changing dialog based on answer',
        becauseofAnswerSurveyChange: 'Changing survey based on answer',
        fetchPlan: 'Fetching form...',
        noPlansFound: 'Form was not found',
        planRedirect: 'You will be redirected back to homepage in 5 seconds',
        wrongPassword: 'Password is incorrect',
        inputPrompt: 'Input',
        planPasswordButton: 'Submit',
        client: 'Client',
        recipient: 'Recipient',
        summary: 'Table of contents',
        riskEvaluationSummary: 'Summary of risk analysis',
        riskClassification: 'Classification',
        riskManagement: 'Risk management',
        explanation: 'Explanation',
        priority: 'Priority',
        taskEvaluationSummary: 'Task summary',
        title: 'Title',
        reminderDate: 'Date of reminder',
        responsible: 'Persons in charge',
        fetchResults: 'Fetching results',
        noResults: 'No results yet',
        reportView: 'Report view',
        groupView: 'Group',
        questionView: 'Question',
        feedbackEnded: 'Thank you for the answer.',
        siteRefresh: 'Site will refresh in a moment',
        surveyThanks: 'Thank you for the answers!',
        endingDirections: 'You can now close the browser or go back to homepage by pressing the button below',
        clickForInfo: 'Click to read more about the question',
        surveyHomePageButton: 'Homepage',
        emailRequestMessage: 'Insert your e-mail, if you want personalized feedback about your answers and this survey.',
        answerButton: 'Answer',
        confirmDate: 'Confirm date',
        selectDate: 'Choose date',
        slideBar: 'Slide the bar',
        moreInfo: 'More info',
        answerPlaceHolder: 'Your answer',
        emailAnswerPlaceHolder: 'Write your e-mail here',
        answerOptionText: 'Select the most appropriate option',
        noDateChosen: 'No date chosen',
        welcomeBack: 'Welcome back. Lets continue where we were.',
        dialogBotText: 'Writing',
        askToContinue: 'We detected that you already started answering to this survey before. Would you like to continue where you were?',
        startNew: 'Start new',
        continueTitle: 'Resume survey', 
        attachmentName: 'File name' ,
        typeErrorMessage: 'Received file format is not allowed or the file name contains unallowed characters!',
        published: 'Published',
        dropzoneFileInfo: 'You may drop a file here or click to open the file manager',
        printPdfTitle: 'Print as PDF',
        freetextPlaceholder: 'You may write your answer here',
        fileRegexError: "The file contains special characters that are not allowed in file names. Allowed special characters are ( ) _ . ",
        metadataText: 'EverBetter is an online customer experience management service that allows you to stay in touch with customers and service providers. It covers a wide range of your organization, as well as key stakeholder meeting points and related activities. You can create a continuous, measurable, and transparent relationship with both your customers and service providers.',
        scrollToTop:'Scroll to top',
        modifiedSuccesfully: 'Modified successfully!',
        codeSentToEmail: "Code sent to email!",
        date:'Date',
        signPlan: 'Sign',
        name: 'Name',
        business: 'Business',
        email: 'Email',
        invalidEmail: 'Please insert Your email in correct form',
        checkInfo: 'Please check that Your information is correct.',
        signGuide: 'You will shortly receive five character code to Your email. Please insert received code to the fields below.',
        planCodeDidntArrive: "Send me a new code!",
        waitingForCode: "Please notice that it may take a while for the email to arrive. Please also check your spam folder just in case! If You didn'n receive the code to your email, please press the link below.",
        cancel: 'Cancel',
        signInfoCorrect: 'Verify',
        emailSignatureTitle: 'Verify signing the form.',
        emailSignatureTopic: "To sign the form please write the five character code (displayed below) to the form's code input. In case You didn't request this code please be in contact with the sender or creator of this form",
        digitalSignature: "Digital signature",
        wrongVerificationCode: "Couldn't verify the code! Please try again. If needed please be in contact with the sender of this form.",
        codeExpired: 'The code has expired, please request a new code by pressing the "Send me a new code!"-link',
        language: 'en',
        addAttachment: 'Add an attachment',
        save: 'Save',
        fileName: 'File name',
        selectAttachment: 'Select attachment',
        onFileSubmit: 'Files recveived',
        planLastSaved: 'Saved',
        businessIdRequired: 'Business-id (Y-tunnus) is required',
        surveySubmitButton: 'Submit answers' ,
        comment: 'Comment',
        confirmSubmission: 'Confirm submission',
        errorOccurred: 'Error occurred!',
        sheetConfirmationText: "Confirm you want to submit these answers. (After submission the answers can not be changed)",
        sendFeedbackToEmail: 'Send feedback to email',
        sendFeedback:'Send to email',
        emailSentSuccess: 'Email sent successfully',
        emailSentError: 'Error occurred while sending the email',
        linkCopied: 'Link copied',
        copyLink: 'Copy link',
        optionRequired: 'All of the multiple-choice questions have not been answered. Please be kind and answer to all of the multiple-choice questions to continue.',
        download: 'Download',
        preview: 'Preview',
        delete: 'Delete',
        removingComment: 'You are deleting a comment! This operation can not be undone.',
        removingCommentBanner: 'You are deleting a comment',
        commentHere: 'Write Your comment here...',
        nameTag: 'Name tag',
        addCommentButton: 'Add',
        planSaved: 'Successfully saved',
        cannotCheckItem: 'Item state can not be altered since at least one signature has been given.',
        verifiedByEB: 'Verified by EverBetter',
        signatureVerification: 'Signature verification',
        signed: 'Signed',
        notSigned: 'Not signed',
        signatureIdentifier: 'Signature identifier',
        timestamp: "Timestamp",
        here: 'here',
        signatureVerificationText: 'You may verify signatures from: ',
        noEmailError: "Email not found, therefore the signature could not be verified. Please be kind and contact the sender of this form.",
        notAllowedError: 'Action not allowed. Modifications will not be saved.',
        ganttName:'Name',
        ganttStartDate: 'Start date',
        ganttEndDate: 'Target date',
        search:'Search',
        showHideTags:'Show or hide tags',
        tags:'Tags',
        surveyEndedEmailInfo:'Here you may send the survey feedback to your email.',
        emailSent:'Email has been sent',
        ganttViewOptions:[
            {label: 'Day', value: 'Day'},
            {label: 'Week', value: 'Week'},
            {label: 'Month', value: 'Month'},
            {label:'Year', value:'Year'}
        ],
        openInNewWindow:'Open in a new window',
        enterpriseRegister: {
            privateUserButton:'Private user',
            businessUserButton: 'Business user',
            loginDetails: "Login details",
            newEnterpriseDetails: "Business details",
            privateUserDetails:"User details",
            privateUserName:'Username (this will be displayed as Your business name)',
            registerButton: "Register",
            newEnterpriseHeader: "New enterprise",
            createError:'Error in registeration',
            createSuccess: 'Successfully created',
            businessExists:'Business already exists',
            userExists: 'User already exists',
            termsOfUse1:"I agree to the ",
            termsOfUse2:"terms of use",
            termsText: "These are the terms of use... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        restorePassword:'Restore password',
        next:'Next',
        previous:'Previous',
        Trello:{
            goToToday: 'Go to today',
            startDate: 'Start date',
            endDate: 'End date',
            resetDates: 'Reset',
            update:'Update',
            showAmount:'Show in view',
            locale: "en",
            closeModal:'Close'
        }
    }
})

export default strings;