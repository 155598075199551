import { NotificationManager } from 'react-notifications'
import { detect } from 'detect-browser'
import * as ActionTypes from './actionTypes'
import { confs } from '../../../bootstrap/config'
import { CODE_LOGIN_PROGRESS } from '../userActions/actionTypes';

let browser = detect();

const storePlan = plan => {
  return {
    type: ActionTypes.GET_PLAN,
    payload: plan
  }
}

export const getPlan = params => dispatch => {
  return new Promise(async (resolve) => {
    let getWithPassword = false
    if (params.password !== undefined) { getWithPassword = true }

    params.browser = browser

    const resultJson = await fetch(confs.url + 'getPlan', {
      method: 'POST',
      body: JSON.stringify(params)
    })

    const result = await resultJson.json()

    if (result.selectedPlan) {
      dispatch(storePlan(result))
      
      if (getWithPassword && result.isLocked) {
        resolve(false)
      }

      if(result.customStyles){
        setCustomStyles(result.customStyles)
      }

      resolve(true)
    } else {
      resolve()
    }
  })
}

export const setSelectedPlan = (plan) => {
  return {
    type: ActionTypes.SET_SELECTED_PLAN,
    payload: plan
  }
}

export const setSelectedPlanItem = (planItem) => {
  return {
    type: ActionTypes.SET_SELECTED_PLAN_ITEM,
    payload: planItem
  }
}

export const setCustomStyles = (styles) => {
  if(styles.css){
    Object.keys(styles.css).map(key => {
        document.documentElement.style.setProperty(key, styles.css[key])
    })
    
  } 
  if(styles.backgroundImage){
    document.documentElement.style.setProperty('--enterprise-background-image',`url(${confs.host}/api/getCustomBackground/${styles.backgroundImage})`)
  }

  if(styles.plan && styles.plan.hidePlanNav === true){
    let navbar = document.getElementById('frontpageHeader')
    if(navbar){
      navbar.setAttribute("style", "display:none")
    }
  }
}

//Not in use atm 
export const getIp = () => {
  return new Promise(async (resolve) => {
    try {
      const resultJson = await fetch('https://api.ipify.org/?format=json', {
        method: 'GET'
      })

      const result = await resultJson.json()
      resolve(result.ip)
    } catch (error) {
      resolve()
    }

  })
}