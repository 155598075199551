import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import './modules.css'

const AskToContinueModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className}>
      <ModalHeader toggle={props.toggle}>{props.locals.continueTitle}</ModalHeader>
      <ModalBody>
        {props.locals.askToContinue}
      </ModalBody>
      <ModalFooter>
        <button className="modal-button modal-accept-button" onClick={() => props.continue()}>{props.locals.continue}</button>{' '}
        <button className="modal-button modal-new-button" onClick={() => props.startNew()}>{props.locals.startNew}</button>
      </ModalFooter>
    </Modal>
  )
}

export default AskToContinueModal