import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  ModalHeader
} from 'reactstrap'

import TextEditor from '../../modules/TextEditor/texteditor'

const RiskModal = props => {
  const { risk, toggle, isOpen, locals } = props

  return (
    <Modal isOpen={ isOpen } size='lg' toggle={ toggle }>
      { risk &&
        <>
          <ModalHeader toggle={ toggle }>
            {locals.riskEvaluationSummary}
          </ModalHeader>

          <ModalBody>

            <Row style={ { marginBottom: '1em' } }>
              <Col>
                <h5>{locals.riskClassification}</h5>
                <p>{ risk.riskClassification.label }</p>
              </Col>
            </Row>

            <Row style={ { marginBottom: '1em' } }>
              <Col>
                <h5>{locals.riskManagement}</h5>
                <p>{ risk.riskGroup.label }</p>
              </Col>
            </Row>

            <hr style={ { clear: 'both' } } />


            <Row style={ { marginBottom: '1em' } }>
              <Col>
                <h5>{locals.explanation}</h5>
                <TextEditor content={ risk.explanation } readOnly={ true } onTextChange={ () => { return } } />
              </Col>
            </Row>

            <Row>
              <Col>
                <h5>{locals.priority}</h5>
                <p>{ `${ risk.riskOdds.label } / ${ risk.riskLevel.label }` }</p>
              </Col>
            </Row>

          </ModalBody>

          <ModalFooter>
            <Button onClick={ toggle }>{locals.close}</Button>
          </ModalFooter>
        </>
      }

    </Modal>
  )
}

export default RiskModal