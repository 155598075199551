import React, {useState, useEffect} from 'react';
import { Row, Col, DropdownToggle, Dropdown, DropdownItem, DropdownMenu, Table } from 'reactstrap';
import TextEditor from '../../../modules/TextEditor/texteditor';


export const BusinessExcelBlock = ({item}) => {
    const [rowData, setRowData] = useState([])

    useEffect(() => {
        if (item && item.excelData && item.excelData.data && item.excelData.headers) {
            let rows = [];
            for (let i = 1; i <= item.excelData.rowCount; i++) {
                let row = []
                for(let j = 0; j < item.excelData.headers.length; j++){
                    row.push(item.excelData.data[j].values[i-1])
                }
                rows.push(row)
            }
            setRowData(rows)
        }
    }, [item])

    return (
        <>
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object "} id={item.id}>
                <Col xl="12" className="plan-text-col">            
                    <div style={{ maxHeight: '30em', overflow:'scroll' }}>
                        {item && item.excelData && item.excelData.data &&
                            <Table striped hover reflow>
                                <thead striped style={{position:"sticky", top:0, backgroundColor:"white"}}>
                                    <tr>
                                        {item.excelData.headers.map((head, i) => {
                                            return <th key={i}>{head || ''}</th>
                                        })}   
                                    </tr>
                                </thead>
                                <tbody >
                                    {rowData.map((row, ind) => {
                                        return <tr key={ind}>
                                            {row.map((r, rowInd) => {
                                                return <td key={rowInd} >
                                                    {r}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}
