import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button
} from 'reactstrap'

import {TrelloChecklistContainer} from './TrelloChecklistContainer'

import { fromArray } from '@amcharts/amcharts4/.internal/core/utils/Iterator'
const ViewCardModal = props => {
  const { toggle, isOpen, card } = props
  const description = card.desc === '' ? 'Ei kuvausta' : card.desc

  return (
    <Modal isOpen={ isOpen } toggle={ toggle } size="lg" centered>
      <ModalBody>
        <Row>
          <Col xs={ { size: 8 } }>
            
            <Row>
              <Col>
                <h2>{ card.name }</h2>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Jäsenet</h5>
                { card.memberList.map( ( member, index ) => {
                  return <span key={ index } title={ member.fullName } className='trello-member' style={{ float: 'left' }}>{ member.initials }</span>
                } ) }
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Nimilaput</h5>
                { card.labels && card.labels.map( ( label, index ) => {
                  return (
                    <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                      <span className='trello-label-text'>{ label.name }</span>
                    </span>
                  )
                } ) }
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Kuvaus</h5>
                <p>{ description }</p>
              </Col>
            </Row>
            {card.checklists && 
              <Row className="mt-5">
                <Col>
                  {card.checklists.map(checklist => {
                    return <TrelloChecklistContainer checklist={checklist} key={checklist.id} />
                  })}
                </Col>
              </Row>
            }
          </Col>
    
          <Col xs={ { size: 4 } } style={ { backgroundColor: 'rgba(200, 200, 200, 0.1)', marginTop: '-1rem', marginBottom: '-1rem', paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Row>
              <Col>
                {/*<Button onClick={ () => archive( card ) } style={{ width: '100%' }}>Arkistoi <i className="fas fa-archive"></i></Button>*/}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button onClick={ props.toggle }>Sulje</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewCardModal