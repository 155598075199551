import React, { Component } from 'react'
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap';
import TextEditor from '../../modules/TextEditor/texteditor'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { duration } from 'moment'
import 'moment/locale/fi';
import posed from 'react-pose'
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip';
import { confs } from '../../../bootstrap/config';
import 'react-dates/lib/css/_datepicker.css';
import { convertFromRaw, EditorState } from 'draft-js'
import locals from '../../../locals/locals';
import './modules.css'
import '../home.css'
import 'rc-slider/assets/index.css';
import { checkYTunnus } from '../../modules/yTunnusHelper'
import { Attachment } from './subComponents/Attachment';
import { detect } from 'detect-browser'
import { NotificationManager } from 'react-notifications'
let browser = detect();


moment.locale('fi');

const Handle = Slider.Handle;

const timeout = 400
export default class Survey extends Component {
  constructor(props) {
    super(props)

    this.state = {
      surveyId: null,
      sentSurveyId: null,
      groupIndex: 0,
      questionIndex: 0,
      currentGroup: null,
      infoMaximized: false,
      surveyEnded: false,
      askedSurveys: [],
      showGroupDescriptionText: true,
      groupColor: '#FFCC00',
      freetextAnswer: "",
      answerSessionId: null,
      previousAnswers: null,
      maxQuestionAnsweredIndex: 0,
      nextQuestionIndex: 0,
      date: null,
      askFinalFeedbackQuestion: false,
      finalFeedbackAnswer: '',
      finalFeedbackHasBeenAsked: false,
      modal: false,
      modalText: '',
      changingQuestion: false,
      surveyEndText: this.props.locals.surveyThanks,
      NPSval: undefined,
      npsNumber: 0,
      npsOld: 0,
      numberChanged: false,
      oldNpsColor: null,
      newNpsColor: null,
      automationFeedback: null,
      businessId: '',
      selectedFiles: null
    }

    this.infoBox = React.createRef()

    this.onAnswer = this.onAnswer.bind(this)
    this.toggleInfo = this.toggleInfo.bind(this)
    this.updateSurvey = this.updateSurvey.bind(this)
  }

  componentDidMount() {
    if (this.props.previousAnswerSession) {
      const answerSession = this.props.previousAnswerSession

      this.setState({
        surveyId: this.props.surveyObj._id,
        sentSurveyId: answerSession.sentSurveyId,
        groupIndex: answerSession.groupIndex,
        sentSurvey: this.props.sentSurvey,
        questionIndex: answerSession.questionIndex,
        currentGroup: this.props.surveyObj.questionGroups[answerSession.groupIndex],
        categoryCount: Object.keys(this.props.surveyObj.questionGroups).length,
        groupColor: this.props.surveyObj.questionGroups[answerSession.groupIndex].groupColor || '#FFCC00',
        answerSessionId: answerSession.answerSessionId,
        maxQuestionAnsweredIndex: answerSession.questionIndex
      }, () => this.nextQuestion())
    } else {
      this.setState({
        surveyId: this.props.surveyObj._id,
        sentSurveyId: this.props.sentSurvey._id,
        sentSurvey: this.props.sentSurvey,
        currentGroup: this.props.surveyObj.questionGroups[this.state.groupIndex],
        categoryCount: Object.keys(this.props.surveyObj.questionGroups).length,
        groupColor: this.props.surveyObj.questionGroups[this.state.groupIndex].groupColor || '#FFCC00',
        answerSessionId: this.props.answerSessionId
      })
    }
  }

  componentDidUpdate(next) {
    if (this.state.numberChanged) {
      this.setState({ numberChanged: false })
    }
    if (this.state.reloadSlider) {
      this.setState({ reloadSlider: false })
    }

    if(this.props.sentSurvey && this.props.sentSurvey._id !== this.state.sentSurvey._id){
      this.setState({ sentSurvey: this.props.sentSurvey})
      this.setState({sentSurveyId: this.props.sentSurvey._id})
    }
  }


  nextQuestion = () => {
    const { groupIndex, questionIndex, currentGroup, nextQuestionIndex } = this.state
    const { sentSurvey } = this.props
    let moveToQuestion = questionIndex + 1

    this.setState({ changingQuestion: true }, () => {
      setTimeout(async () => {
        if (nextQuestionIndex > questionIndex) {
          moveToQuestion = nextQuestionIndex
        }

        if (currentGroup.questions[moveToQuestion]) {
          this.setState({
            questionIndex: moveToQuestion,
            nextQuestionIndex: moveToQuestion
          })
        } else {
          if (this.props.surveyObj.questionGroups[groupIndex + 1]) {
            this.setState(prevState => ({
              groupIndex: groupIndex + 1,
              questionIndex: 0,
              currentGroup: this.props.surveyObj.questionGroups[groupIndex + 1],
              askedSurveys: [
                ...prevState.askedSurveys,
                groupIndex
              ],
              showGroupDescriptionText: true,
              groupColor: this.props.surveyObj.questionGroups[groupIndex + 1].groupColor || '#FFCC00',
              maxQuestionAnsweredIndex: 0,
              nextQuestionIndex: 0
            }))
          } else {
            if (typeof this.props.surveyObj.askFinalFeedbackEmail !== 'undefined' && this.props.surveyObj.askFinalFeedbackEmail && !this.state.finalFeedbackHasBeenAsked) {
              this.setState({ askFinalFeedbackQuestion: true })
            } else {
              if (this.props.getNextSurvey()) {
                this.updateSurvey()
              } else {
                // get survey end text and replace parameters with answer values
                const surveyEndText = await this.getSurveyEndedTextWithParameters()

                if (sentSurvey.automationSettings) {
                  let params = {
                    sentSurveyId: this.state.sentSurveyId,
                    answerSessionId: this.state.answerSessionId
                  }
                  if (!sentSurvey.automationSettings.showOnAnswer && !sentSurvey.automationSettings.sendOnAnswer) {
                    this.props.handleAutomation(params)
                  }
                  if (sentSurvey.automationSettings.showOnAnswer || sentSurvey.automationSettings.sendOnAnswer) {
                    let generated = await this.props.handleAutomation(params)

                    if (generated.published) {
                      let planUrl = confs.frontPage + 'plan/' + generated.publishedId
                      let text = sentSurvey.automationSettings.linkText || 'Voit katsoa automaattisesti luodun lomakkeen tästä'

                      this.setState({ automationFeedback: { url: planUrl, text: text } })
                    }
                  }

                }

                this.setState({
                  surveyEnded: true,
                  nextQuestionIndex: 0,
                  maxQuestionAnsweredIndex: 0,
                  surveyEndText: surveyEndText
                }, () => this.props.clearAnswerSession())
              }
            }
          }
        }
        this.setState({ changingQuestion: false, showQuestionFeedback: false })
      }, timeout)
    })
  }

  updateSurvey() {
    this.setState({
      surveyId: this.props.surveyObj._id,
      sentSurveyId: this.props.surveyObj.sentSurveyId,
      currentGroup: this.props.surveyObj.questionGroups[0],
      categoryCount: Object.keys(this.props.surveyObj.questionGroups).length,
      groupIndex: 0,
      questionIndex: 0,
      showGroupDescriptionText: true,
      maxQuestionAnsweredIndex: 0,
      nextQuestionIndex: 0
    })
  }

  onAnswer(ans) {
    const { currentGroup, questionIndex, groupIndex, answerSessionId, maxQuestionAnsweredIndex, selectedFiles } = this.state

    const isFinalFeedbackQuestion = this.state.askFinalFeedbackQuestion
    let targetSurvey = null;
    if (!browser) {
      browser = "not detectable"
    }

    if (isFinalFeedbackQuestion) {
      this.setState({ askFinalFeedbackQuestion: false, finalFeedbackHasBeenAsked: true }, () => {
        // tallennetaan annettu sähköposti ja lähetetään siihen finalfeedbacki
        let text = this.state.finalFeedbackAnswer
        const { finalFeedback } = this.props.surveyObj
        const entities = this.getEntitiesFromText(finalFeedback)
        const answerObj = {
          surveyId: this.state.surveyId,
          sentSurveyId: this.state.sentSurveyId,
          groupId: '',
          questionId: '',
          answerSessionId: this.props.answerSessionId,
          answer: {
            isFinalFeedbackQuestion: true,
            text: text,
            entities: entities
          },
          browser: browser
        }

        this.props.onSurveyAnswer(answerObj)
        this.nextQuestion()
      })
    } else {
      let maxQuestionAnsweredIndexCopy = maxQuestionAnsweredIndex
      const currentQuestion = currentGroup.questions[questionIndex];
      const answerIndex = ans
      let answer = currentQuestion.isFreetextQuestion ? { text: this.state.freetextAnswer, isFreetextQuestion: true } : currentQuestion.questionOptions[answerIndex]
      const isVariableQuestion = currentQuestion.isVariableQuestion

      if (currentQuestion.isNPS) {
        const npsval = this.state.npsNumber > 0 ? this.state.npsNumber : 1;
        answer = { isNpsQuestion: true, value: npsval }
        this.setState({
          NPSval: undefined,
          npsNumber: 0,
          npsOld: 0,
          numberChanged: false,
          oldNpsColor: null,
          newNpsColor: null,
          reloadSlider: true
        })
      }
      if (currentQuestion.isDateQuestion) {
        answer = { text: this.state.date ? this.state.date._d : "Ei päivämäärää", isDateQuestion: true }
        this.setState({ date: null });
      } else if (answer) {
        answer.isVariableQuestion = isVariableQuestion
      }
      if (currentQuestion.isBusinessId) {
        let businessId = this.state.businessId.trim()
        answer = { isBusinessId: true, value: businessId }
        this.setState({ businessId: '' })
      }
      if (currentQuestion.isAttachmentQuestion && ans ){
        answer = {isAttachmentQuestion: true, files:ans}
        
      }

      if ((questionIndex === 0 && maxQuestionAnsweredIndex === 0) || questionIndex > maxQuestionAnsweredIndex) {
        maxQuestionAnsweredIndexCopy = questionIndex

        this.updateAnswerSession(groupIndex, maxQuestionAnsweredIndexCopy, answerSessionId)
        this.setState({ maxQuestionAnsweredIndex: maxQuestionAnsweredIndexCopy })
      }

      const answerObj = {
        surveyId: this.state.surveyId,
        sentSurveyId: this.state.sentSurveyId,
        groupId: this.state.currentGroup.id,
        questionId: currentQuestion.id,
        answer: answer,
        answerSessionId: this.props.answerSessionId,
        browser: browser
      }

      this.props.onSurveyAnswer(answerObj).then(async () => {
        if (answerObj.answer.targetSurvey !== undefined) {
          targetSurvey = answerObj.answer.targetSurvey;
          if (targetSurvey.value === "endSurvey") {
            // get survey end text and replace parameters with answer values
            const surveyEndText = await this.getSurveyEndedTextWithParameters()

            this.setState({
              surveyEnded: true,
              nextQuestionIndex: 0,
              maxQuestionAnsweredIndex: 0,
              surveyEndText: surveyEndText
            }, () => this.props.clearAnswerSession())
          } else {
            this.props.onTargetSurveyChange({ targetSurvey: targetSurvey, sessionId: answerSessionId, sentSurveyId: this.state.sentSurveyId })
              .then(() => this.props.getNextSurvey())
              .then(() => this.updateSurvey())
              .then(() => NotificationManager.success(this.props.locals.becauseofAnswerSurveyChange, "", 2000))
          }
        } else {
          if (currentQuestion.isFreetextQuestion) {
            this.setState({
              freetextAnswer: ""
            }, () => {
              this.nextQuestion()
            })
          } else {
            if (answer.surveyFeedback !== undefined && answer.surveyPlaintextFeedback.trim() !== '') {
              this.toggleQuestionFeedback(answer.surveyFeedback)
            } else {
              this.nextQuestion()
            }
          }
        }
      })
    }
  }

  updateAnswerSession = (groupIndex, maxQuestionAnsweredIndex, answerSessionId) => {
    // store an answer session stack, so it's possible for the user to continue the survey they have left unfinished
    const answerSession = {
      groupIndex: groupIndex,
      questionIndex: maxQuestionAnsweredIndex,
      answerSessionId: answerSessionId,
      sentSurveyId: this.state.sentSurveyId
    }

    this.props.storeAnswerSession(answerSession)

  }

  toggleInfo() {
    this.setState(prevState => ({
      infoMaximized: !prevState.infoMaximized
    }))
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleSurveyTextModal = () => {
    this.setState(prevState => ({
      surveyTextModal: !prevState.surveyTextModal
    }));
  }

  async changeQuestion(qIndex) {
    const { nextQuestionIndex } = this.state

    if (qIndex <= nextQuestionIndex) {
      this.setState({ questionIndex: qIndex })
    }
  }

  onDateChange = date => this.setState({ date })

  isDayBlocked = (day) => {
    if (this.state.currentGroup.questions[this.state.questionIndex].allowWeekends !== 'undefined' && !this.state.currentGroup.questions[this.state.questionIndex].allowWeekends) {
      if (day.day() === 6 || day.day() === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  closeGroupDescription = () => {
    this.setState({ changingQuestion: true }, () => {
      setTimeout(() => {
        this.setState({ showGroupDescriptionText: false }, () => {
          this.setState({ changingQuestion: false })
        })
      }, timeout)
    })
  }

  toggleQuestionFeedback = (feedback = '') => {
    this.setState({ changingQuestion: true }, () => {
      setTimeout(() => {
        this.setState(state => ({
          showQuestionFeedback: !state.showQuestionFeedback,
          questionFeedback: feedback
        }), () => {
          this.setState({ changingQuestion: false })
        })
      }, timeout)
    })
  }

  getSurveyEndedTextWithParameters = async () => {
    const survey = this.props.surveyObj

    return new Promise(async (resolve) => {
      if (typeof survey.surveyEndedText === 'object') {
        let { surveyEndedText } = survey

        const entities = this.getEntitiesFromText(surveyEndedText)

        // send the list to server and get the answer values in response
        const params = {
          sentSurveyId: this.state.sentSurveyId,
          answerSessionId: this.state.answerSessionId,
          entities: entities,
          survey: survey,
          surveyEndedText: surveyEndedText
        }

        const parameterValues = await this.props.getEndTextParams(params)

        if (parameterValues && parameterValues.text) {
          resolve(parameterValues.text)
        }
      }
      resolve(this.state.surveyEndText)
    })
  }

  getEntitiesFromText = text => {
    if(text !== undefined){
      const editorState = EditorState.createWithContent(convertFromRaw(text))
      const contentState = editorState.getCurrentContent()
      const blocks = contentState.getBlocksAsArray()

      const entities = []

      // find all parameters tagged with @kysymys from the text and collect them to a list
      blocks.forEach((block) => {
        if (block.getText().indexOf('@kysymys') !== -1) {
          block.findEntityRanges(
            (char) => {
              if (char.getEntity() !== null) {
                const entity = contentState.getEntity(char.getEntity())
                let { mention } = entity.getData()

                entities.push(mention)
              }
            }
          )
        }
        return null
      })

      return entities
    } else {
      return null
    }
  }

  npsFormatter = (val) => {
    if (!isNaN(val)) {
      let oldval = this.state.npsNumber;
      let newval = Math.round(val / 10);

      if (newval === 0) {
        newval = 1;
      }
      if (oldval === 0) {
        oldval = newval;
      }
      if (this.state.npsNumber !== newval) {
        this.setState({ npsNumber: newval, npsOld: oldval });  //numberchanged here
      }
      this.setState({ oldNpsColor: this.state.newNpsColor })
      if (oldval < 7 && newval >= 7) {
        this.setState({ numberChanged: true })
      }
      else if ((oldval >= 7 && oldval < 8) && (newval < 7 || newval >= 8)) {
        this.setState({ numberChanged: true })
      }
      else if (oldval >= 8 && newval < 8) {
        this.setState({ numberChanged: true })
      }

      if (newval < 7) {
        this.setState({ newNpsColor: '#FF0000' })
      }
      else if (newval >= 7 && newval < 9) {
        this.setState({ newNpsColor: '#fc0' })
      }
      else if (newval >= 9) {
        this.setState({ newNpsColor: '#b5e413' })
      }
    }
  }

  colorLuminance = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  npsIcon = (val) => {
    if (val <= 6) {
      return 'far fa-frown'
    }
    if (val >= 7 && val <= 8) {
      return 'far fa-meh'
    }
    if (val >= 9) {
      return 'far fa-smile'
    }
  }

  handleFile = (files) => {
    this.setState({selectedFiles:files})
  }

  render() {
    // const shortDescriptionString = this.props.surveyObj.plaintextCategoryExplanation.length > 180 ? this.props.surveyObj.plaintextCategoryExplanation.substring(0, 180) + "..." : this.props.surveyObj.plaintextCategoryExplanation;
    // const logoUrl = `${confs.host}/api/business/getLogo/${this.props.surveySender}`
    const surveyEndedText = this.state.surveyEndText
    const btnColor = this.colorLuminance(this.state.groupColor, 30)

    let informationTextIsEmpty = true

    if (this.state.currentGroup && this.state.currentGroup.questions[this.state.questionIndex].information !== undefined) {
      let plaintextInfo = convertFromRaw(this.state.currentGroup.questions[this.state.questionIndex].information).getPlainText()

      informationTextIsEmpty = plaintextInfo.trim() === ''
    }


    const showQuestionInfo = this.state.currentGroup && !this.state.showGroupDescriptionText && this.state.currentGroup.questions[this.state.questionIndex].information !== undefined && !informationTextIsEmpty

    const hasLogo = this.props.activeLogo !== undefined

    return (
      <div className="survey-container col-12 col-xl-10 col-lg-11 col-md-12 col-sm-12">

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="infoModal">
          <ModalHeader toggle={this.toggleModal}>{locals.moreInfo}</ModalHeader>
          <ModalBody>
            {this.state.currentGroup && this.state.currentGroup.questions && this.state.currentGroup.questions[this.state.questionIndex].information &&
              <TextEditor content={this.state.currentGroup.questions[this.state.questionIndex].information} readOnly={true} onTextChange={() => { return }} />
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleModal}>{locals.close}</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.surveyTextModal} toggle={this.toggleSurveyTextModal} size="xl">
          <ModalHeader toggle={this.toggleSurveyTextModal}></ModalHeader>
          <ModalBody>
            <TextEditor content={this.props.surveyObj.text} readOnly={true} onTextChange={() => { return }} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleSurveyTextModal}>{locals.close}</Button>
          </ModalFooter>
        </Modal>

        {/* This would be the survey info box, that holds survey description, above the actual survey box */}
        <Row className="survey-info-box" onClick={this.toggleSurveyTextModal} style={{ cursor: 'pointer' }}>
          {/* colored ribbon in the top */}
          <div className="top-ribbon d-none-768" style={{ borderColor: this.state.groupColor }}></div>

          <Col xs={{ size: 10 }} sm={{ size: 11 }} className="info-box-general">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {this.props.surveyObj && this.props.surveyObj.enterpriseId &&
                <div id='plan-title-image' style={{ backgroundImage: `url(${confs.host}/api/homepage/getLogo/${this.props.surveyObj.enterpriseId})`, backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', width: '140px', backgroundRepeat: 'no-repeat' }}></div>
              }  
              <h4 style={{ marginBottom: '0', marginLeft: '1em' }}>{this.props.surveyObj.title}</h4>
            </div>
          </Col>
          <Col xs={{ size: 2 }} sm={{ size: 1 }} style={{ padding: '.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="infoblock" style={{ color: this.state.groupColor }}>
              <i className="fas fa-info"></i>
            </div>
          </Col>
        </Row>

        <Row>
          <TopRibbon className="top-ribbon" style={{ borderColor: this.state.groupColor }} pose={showQuestionInfo ? "visible" : "notVisible"}>
            <div onClick={this.toggleModal} style={{ display: 'flex', cursor: 'pointer' }}>
              <Col xs={{ size: 10 }} sm={{ size: 11 }}>
                <p style={{ marginTop: '1rem' }}>{locals.clickForInfo}</p>
              </Col>
              <Col xs={{ size: 2 }} sm={{ size: 1 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="small-infoblock" style={{ cursor: 'pointer', color: this.state.groupColor }}>
                  <i className="fas fa-info"></i>
                </div>
              </Col>
            </div>
          </TopRibbon>
        </Row>

        {this.state.currentGroup &&
          <div className="question-container" style={{ position: 'relative' }}>

            <InnerContainer pose={this.state.changingQuestion ? 'closed' : 'open'} className={"row h-100.mobile survey-inner-container" + (this.state.changingQuestion ? " make-padding-great-again" : "")} >
              <Col md={{ size: 12 }} className="text-center">
                {this.state.surveyEnded ? (
                  <SurveyContent pose="open" className="row" style={{ marginTop: "5em" }}>
                    <Col md={{ size: 12 }}>
                      <Row className="text-center">
                        <Col size='12'>
                          <h5>{locals.surveyThanks}</h5>
                          <TextEditor content={surveyEndedText} readOnly={true} onTextChange={() => { return }} textAlignment='center' />
                          {this.state.automationFeedback &&
                            <div>
                              <a href={this.state.automationFeedback.url} target="_blank" rel="noopener noreferrer">{this.state.automationFeedback.text}</a>
                            </div>
                          }
                          <span>{locals.endingDirections}</span>
                          <button style={{ marginTop: '5em', borderColor: this.state.groupColor }} onClick={this.props.endSurvey} className="survey-ended-button">{locals.surveyHomePageButton}</button>
                        </Col>
                      </Row>
                    </Col>
                  </SurveyContent>
                ) : (
                    this.state.showGroupDescriptionText ? (
                      <SurveyContent pose={this.state.changingQuestion ? 'closed' : 'open'}>
                        <Row>
                          <Col xs={{ size: 12 }} md={{ size: 12 }} className="text-center">
                            <h3 className="survey-group-title" style={{ width: '100%' }}>
                              {hasLogo && <span className="enterprise-logo" style={{ backgroundImage: this.props.activeLogo }} />}
                              {this.state.currentGroup.title}
                            </h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={{ size: 12 }}>
                            <TextEditor content={this.state.currentGroup.text} readOnly={true} onTextChange={() => { return }} additionalClass="mt-2" />
                          </Col>
                        </Row>
                        <Row className="answers-container">
                          <Col md={{ size: 12 }}>
                            <button onClick={this.closeGroupDescription} className="answer-button survey-answer-button" style={{ margin: 0, borderColor: this.state.groupColor }}>{locals.toContinue}</button>
                          </Col>
                        </Row>
                      </SurveyContent>
                    ) : (
                        this.state.askFinalFeedbackQuestion ? (
                          <SurveyContent pose={this.state.changingQuestion ? 'closed' : 'open'}>
                            <Row className="question-text">
                              <Col md={{ size: 12 }}>
                                <h4>{locals.emailRequestMessage}</h4>
                              </Col>
                            </Row>
                            <Row className="answers-container">
                              <Col>
                                <textarea className="freetext-answer" placeholder={locals.answerPlaceHolder} value={this.state.finalFeedbackAnswer} onChange={(e) => this.setState({ finalFeedbackAnswer: e.target.value })}></textarea>
                                <button onClick={() => this.onAnswer()} style={{ padding: "1rem", margin: "0", borderColor: this.state.groupColor }} className="answer-button survey-answer-button">{locals.answerButton}</button>
                              </Col>
                            </Row>
                          </SurveyContent>
                        ) : (
                            this.state.showQuestionFeedback ? (
                              <SurveyContent pose={this.state.changingQuestion ? 'closed' : 'open'}>
                                <Row>
                                  <Col md={{ size: 12 }}>
                                    <TextEditor content={this.state.questionFeedback} readOnly={true} onTextChange={() => { return }} additionalClass="mt-2" />
                                  </Col>
                                </Row>
                                <Row className="answers-container">
                                  <Col md={{ size: 12 }}>
                                    <button onClick={() => this.nextQuestion()} className="answer-button survey-answer-button" style={{ margin: 0, borderColor: this.state.groupColor }}>{locals.toContinue}</button>
                                  </Col>
                                </Row>
                              </SurveyContent>
                            ) : (
                                <SurveyContent pose={this.state.changingQuestion ? 'closed' : 'open'}>
                                  <Row className="question-text">
                                    <Col md={{ size: 12 }}>
                                      <h4>{!this.state.currentGroup.questions[this.state.questionIndex].isComment && this.state.currentGroup.questions[this.state.questionIndex].text}</h4>
                                      {/* <TextEditor content={this.state.currentGroup.questions[this.state.questionIndex].text} readOnly={true} /> */}
                                    </Col>
                                  </Row>

                                  <Row className="answers-container">
                                    <Col md={{ size: 12 }}>
                                      {this.state.currentGroup.questions[this.state.questionIndex].isDateQuestion &&
                                        <Row style={{ justifyContent: 'center' }}>
                                          <Col>
                                            <DayPickerSingleDateController
                                              date={this.state.date}
                                              isDayBlocked={this.isDayBlocked}
                                              firstDayOfWeek={1}
                                              onDateChange={this.onDateChange}
                                              onFocusChange={({ focused }) => this.setState({ focused })}
                                              focused={this.state.focused}
                                              hideKeyboardShortcutsPanel={true}
                                            />
                                            <button onClick={() => this.onAnswer()} style={{ padding: "1rem", margin: "0", marginTop: '1rem', borderColor: this.state.groupColor }} disabled={this.state.date ? false : true} className="answer-button survey-answer-button">{this.state.date ? `${locals.confirmDate}: ${moment(this.state.date._d).local('fi').format('L')}` : locals.selectDate}</button>
                                          </Col>
                                        </Row>
                                      }
                                      {this.state.currentGroup.questions[this.state.questionIndex].isFreetextQuestion &&
                                        <>
                                          <TextEditor id="freetext" content={this.state.freetextAnswer} readOnly={false} additionalClass="freetext-answer" onTextChange={editorState => this.setState({freetextAnswer:editorState})} placeholder={this.state.currentGroup.questions[this.state.questionIndex].placeholder ? this.state.currentGroup.questions[this.state.questionIndex].placeholder : locals.freetextPlaceholder} textAlignment='left' allowFileUpload={true} documentId={this.state.sentSurveyId} sender={this.props.surveyObj.enterpriseId} groupColor={this.state.groupColor}/>
                                          {/* <textarea className="freetext-answer" placeholder={this.state.currentGroup.questions[this.state.questionIndex].placeholder ? this.state.currentGroup.questions[this.state.questionIndex].placeholder : locals.answerPlaceHolder} value={this.state.freetextAnswer} onChange={(e) => this.setState({ freetextAnswer: e.target.value })}></textarea> */}
                                          <button pose={this.state.changingQuestion ? 'closed' : 'open'} onClick={() => this.onAnswer()} style={{ padding: "1rem", margin: "0", borderColor: this.state.groupColor }} className="answer-button survey-answer-button">{locals.answerButton}</button>
                                        </>
                                      }

                                      {this.state.currentGroup.questions[this.state.questionIndex].isComment &&
                                        <>
                                          <TextEditor content={this.state.currentGroup.questions[this.state.questionIndex].comment} readOnly={true} onTextChange={() => false} />
                                          <button pose={this.state.changingQuestion ? 'closed' : 'open'} onClick={() => this.nextQuestion()} style={{ padding: "1rem", marginTop: "1em", borderColor: this.state.groupColor }} className="answer-button survey-answer-button">{locals.continue}</button>
                                        </>
                                      }

                                      {
                                        this.state.currentGroup.questions[this.state.questionIndex].isNPS &&
                                        <>
                                          <div className="NPS-container">
                                            <div className="amazing-number">
                                              {this.state.npsNumber > 0 &&
                                                <>
                                                  <NPS style={{ opacity: 0 }} pose={this.state.numberChanged ? 'animateClose' : ''} x={this.state.npsNumber > this.state.npsOld ? -45 : 45} ><i style={{ color: this.state.oldNpsColor }} className={this.npsIcon(this.state.npsOld)}></i></NPS>
                                                  <NPS pose={this.state.numberChanged ? 'animate' : ''} x={this.state.npsNumber > this.state.npsOld ? 45 : -45} > <i style={{ color: this.state.newNpsColor }} className={this.npsIcon(this.state.npsNumber)}></i> </NPS>
                                                </>
                                              }
                                              <NPStext className="slider-text" pose={this.state.npsNumber > 0 ? 'exit' : 'show'} key="NPStext">{locals.slideBar}</NPStext>
                                            </div>
                                            {!this.state.reloadSlider && !this.state.changingQuestion &&
                                              <Slider
                                                min={10} max={100}
                                                steps={1}
                                                className="nps-slider"
                                                defaultValue={this.state.NPSval}
                                                onChange={val => this.npsFormatter(val)}
                                                onAfterChange={(val) => this.setState({ NPSval: val })}
                                                railStyle={{ backgroundColor: '#dedede', height: '1em' }}
                                                trackStyle={{ backgroundColor: this.state.groupColor || '#b5e413', height: '1em' }}
                                                handleStyle={{ backgroundColor: this.state.groupColor || '#b5e413', borderColor: this.state.groupColor || '#b5e413', height: '2.2em', width: '2.2em', marginTop: '-0.6em', boxShadow: `${this.state.groupColor} 0px 0px 4px`, display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', buttonColor: btnColor }}
                                                handle={handle}
                                                marks={{ 10: '1', 100: '10' }}
                                                number={this.state.npsNumber}
                                              />
                                            }
                                          </div>
                                          <button pose={this.state.changingQuestion ? 'closed' : 'open'} onClick={() => this.onAnswer()} disabled={this.state.NPSval ? false : true} style={{ padding: "1rem", marginTop: "1em", borderColor: this.state.groupColor }} className="answer-button survey-answer-button">{locals.continue}</button>

                                        </>
                                      }

                                      {this.state.currentGroup.questions[this.state.questionIndex].isBusinessId &&
                                        <span>
                                          <textarea className="freetext-answer" placeholder="Esimerkiksi 1234567-1" value={this.state.businessId} onChange={(e) => this.setState({ businessId: e.target.value })}></textarea>
                                          <button className="dialog-answer-button" disabled={!checkYTunnus(this.state.businessId)} style={{ padding: "1rem", margin: "0", borderColor: this.state.groupColor || '#b5e413' }} onClick={(e) => this.onAnswer(e)}>{this.props.locals.answerButton}</button>
                                        </span>
                                      }

                                      {this.state.currentGroup.questions[this.state.questionIndex].isAttachmentQuestion &&
                                       <Attachment
                                          setSelectedFiles={this.handleFile}
                                          onAnswer={this.onAnswer}
                                          sentSurveyId={this.state.sentSurveyId}
                                          enterpriseId={this.props.surveyObj}
                                        />
                                      }

                                      {(!this.state.currentGroup.questions[this.state.questionIndex].isDateQuestion && !this.state.currentGroup.questions[this.state.questionIndex].isFreetextQuestion && !this.state.currentGroup.questions[this.state.questionIndex].isComment && !this.state.currentGroup.questions[this.state.questionIndex].isNPS) && !this.state.currentGroup.questions[this.state.questionIndex].isBusinessId && !this.state.currentGroup.questions[this.state.questionIndex].isAttachmentQuestion &&
                                        <div>
                                          <Col>
                                            <p className="mx-auto"><small>{locals.answerOptionText}</small></p>
                                            {this.state.currentGroup.questions[this.state.questionIndex].questionOptions.length > 0 && this.state.currentGroup.questions[this.state.questionIndex].questionOptions.map((obj, index) => {
                                              let styles = {}

                                              if (typeof this.state.currentGroup.groupColor !== 'undefined' && this.state.currentGroup.groupColor !== null && typeof this.state.currentGroup.colorInUse !== 'undefined' && this.state.currentGroup.colorInUse) {
                                                // styles.backgroundColor = this.state.currentGroup.groupColor
                                                styles.borderColor = this.state.currentGroup.groupColor
                                              }

                                              /*
                                              TÄSSÄ YKSI VEDOS SIITÄ, ETTÄ NÄYTETÄÄN KÄYTTÄJÄLLE TÄMÄN ANTAMA VASTAUS SIINÄ TAPAUKSESSA ETTÄ NAVIGOIDAAN KYSYMYKSEEN JOHON ON JO ANNETTU VASTAUS
                                              if(typeof this.state.groupAnswers[this.state.questionIndex] !== undefined && parseInt(this.state.groupAnswers[this.state.questionIndex], 10) === parseInt(index, 10)) {
                                                styles.backgroundColor = this.state.currentGroup.groupColor
                                              }
                                              */

                                              return <button onClick={() => this.onAnswer(index)} key={index} className="answer-button survey-answer-button align-self-end" style={styles}>
                                                {obj.text}
                                              </button>
                                            })}
                                          </Col>
                                        </div>
                                      }

                                    </Col>
                                  </Row>
                                </SurveyContent>
                              )
                          )
                      )
                  )}

              </Col>
            </InnerContainer>


            {!this.state.surveyEnded &&
              <Row style={{ paddingBottom: '3rem' }}>
                <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                  {this.props.surveyObj.questionGroups.map((group, index) => {

                    let classes = ""
                    let styles = { zIndex: '50', backgroundColor: '#fff', borderColor: '#000' }
                    let stylesAsked = { zIndex: '50', backgroundColor: this.state.groupColor, borderColor: this.state.groupColor }

                    if (index < this.state.groupIndex) {
                      classes += " progress-asked"
                      styles = stylesAsked
                    }
                    if (index === this.state.groupIndex) {
                      classes += " progress-active"
                      styles = stylesAsked
                    }
                    if (this.state.surveyEnded === true) {
                      classes += " progress-asked"
                      styles = stylesAsked
                    }

                    return <span className={"survey-nav-ball" + classes} title={group.title} style={styles} key={index}></span>
                  })}
                </Col>
              </Row>
            }
          </div>
        }
      </div>
    )
  }
}


const handle = (props) => {
  const { value, dragging, index } = props;

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={Math.round(value / 10) > 1 ? Math.round(value / 10) : 1}
      visible={dragging}
      placement="bottom"
      key={index}
    >
      <Handle {...props}><i className="fas fa-grip-horizontal" style={{ color: props.style.buttonColor }}></i></Handle>
    </Tooltip>
  );
};

const TopRibbon = posed.div({
  visible: { height: 'auto' },
  notVisible: { height: 0 }
})

const SurveyContent = posed.div({

})

const InnerContainer = posed.div({
  open: {
    height: 'auto',
    transition: {
      ease: 'linear'
    }
  },
  closed: {
    height: 0,
    transition: {
      ease: 'linear'
    }
  }
})

const NPStext = posed.div({
  exit: {
    opacity: 0,
    display: 'none',
    transition: { duration: 300 }
  },
  show: {
    opacity: 1,
    display: 'block',
    transition: { delay: 300 }
  }
})

const NPS = posed.div({
  animate: {
    applyAtStart: { opacity: 0, },
    x: 0,
    opacity: 1,
    transition: { duration: 300 }
  },
  animateClose: {
    applyAtStart: { opacity: 1, x: 0 },
    //x: ({ x }) => x,
    opacity: 0,
    transition: { duration: 300 }
  }
})