import React, { useEffect, useState }from 'react'
import { NotificationManager } from 'react-notifications';
import { Row, Col, Container, FormGroup, Input, InputGroupAddon, InputGroup, Button, Form, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { confs } from '../../bootstrap/config';

let baseUrl = confs.url

const INITIAL_STATE = {
    businessName: "",
   
}
const INITIAL_ERRORS = {
    businessName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsCheck: ""
}

export const RegisterNewEnterprise = ({locals}) => {
    const [newEnterprise, setNewEnterprise] = useState(INITIAL_STATE)
    const [errors, setErrors] = useState(INITIAL_ERRORS)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [termsCheck, setTermsCheck] = useState(false)
    const [termsOfUseModal, setTermsOfUseModal] = useState(false)
    const [enterpriseType, setEnterpriseType] = useState("business")

    useEffect(()=>{
        if(password === confirmPassword){
            setErrors({
                ...errors,
                confirmPassword: false
            })
        } else {
            setErrors({
                ...errors,
                confirmPassword: true
            })
        }
       
    },[confirmPassword, password])
    useEffect(()=>{
       if(newEnterprise.businessName.length > 0){
        if(errors.businessName){
            setErrors({
                ...errors,
                businessName:false
            })
        }
       }
    },[newEnterprise.businessName])

    const handleChange = (props) => {
        const {id, value} = props
       
        // if(id === "businessID"){
        //     checkBusinessId(value)
        // }
        setNewEnterprise({
            ...newEnterprise,
            [id]:value
        }) 
    }

    const handleEmail = (value) => {
        const emailCheck = /[^@]+@[^\.]+\..+/;
        if(email && !emailCheck.test(value)){
            setErrors({
                ...errors,
                email:true
            })
        } else {
            setErrors({
                ...errors,
                email:false
            })
        }
        setEmail(value)
    }

    const handlePassword = (value) => {
        
        if(value.length > 1){
            setErrors({
                ...errors,
                password: false
            })
        } else {
            setErrors({
                ...errors,
                password: true
            })
        }   
        setPassword(value)
    }

    const createNewEnterprise = () => {

    }

    const checkBusinessId = (value) => {
        const yTunnusCheck = /^[0-9]{6,7}\-[0-9]{1}$/;
        if(value && yTunnusCheck.test(value)){
            setErrors({
                ...errors,
                businessID:false
            })
        } else {
            setErrors({
                ...errors,
                businessID:true
            })
        }
    }

    const checkRegisterData = () => {
        let finalErrors = {...errors}

        if(newEnterprise.businessName.length < 1){
            
            finalErrors = {
                ...finalErrors,
                businessName: true
            }
        }
        // if(enterpriseType === "business" && newEnterprise.businessID.length < 1){
        //     finalErrors = {
        //         ...finalErrors,
        //         businessID: true
        //     }
        // }
        if(email.length < 1){
            finalErrors = {
                ...finalErrors,
                email: true
            }
        }
        if(password.length < 1){
            finalErrors = {
                ...finalErrors,
                password: true
            }
        }
        if(termsCheck === false){
            finalErrors = {
                ...finalErrors,
                termsCheck: true
            }
        }
        setErrors({...finalErrors})
        let numberOfErrors = Object.keys(finalErrors).map((obj) => {
            if(finalErrors[obj] === true){
                return finalErrors[obj]
            } else {
                return null
            }
        })
        if(numberOfErrors.filter(error => error === true).length === 0){
            createEnterprise()
        }
    }

    const createEnterprise = async() => {
        let payload = {
            enterpriseType: enterpriseType,
            enterpriseData : newEnterprise,
            userData : {
                email: email,
                password: password
            }
        }
        try {
            const responseJson = await fetch(baseUrl + 'register/newEnterprise', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                        
                if(response.success){
                    try{
                        NotificationManager.success(locals.enterpriseRegister.createSuccess, locals.notice, 2000);
                        window.location.href = "/"
                    } catch {}
                } else {
                    if(response.businessExists){
                        NotificationManager.error(locals.enterpriseRegister.businessExists, locals.error ,7000)
                    }
                    if(response.userExists){
                        NotificationManager.error(locals.enterpriseRegister.userExists, locals.error ,7000)
                    }
                    if(response.error){
                        NotificationManager.error(locals.enterpriseRegister.createError, locals.error ,7000)
                    }
                }

            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    const handleTermsCheck = (e) => {
        setTermsCheck(!termsCheck)
        if(!termsCheck === true){
            setErrors({
                ...errors,
                termsCheck: false
            })
        }
    }
    const toggleTermsOfUse = () => {
        // setTermsOfUseModal(!termsOfUseModal)
       let url = "https://app.everbetter.fi/plan/646719db0a68be77d03e090b"
       window.open(url)

    }
    return(
        <Container className="h-100">
            <TermsOfUseModal locals={locals} isOpen={termsOfUseModal} toggle={toggleTermsOfUse}/>
            <Row className="justify-content-center align-items-center h-100">
                <div className="col-md-6 login-box">
                    <div className="login-inner-container">
                        <div className="login-box-content">
                            <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                            <Row className="h-100">
                                <Col xs="12">
                                    <div style={{ display:'flex', justifyContent:'space-between', marginTop:'1.5em' }}>
                                        <button className={"small-white-button " + (enterpriseType === "business"? " confirm-button" : "")} style={{width:'48%'}} onClick={()=> setEnterpriseType("business")}>{locals.enterpriseRegister.businessUserButton}</button>
                                        <button className={"small-white-button " + (enterpriseType === "private"? " confirm-button" : "")} style={{width:'48%'}} onClick={()=> setEnterpriseType("private")}>{locals.enterpriseRegister.privateUserButton}</button>
                                    </div>
                                </Col>
                                {enterpriseType === "business" ?
                                    <Col xs="12">
                                        <div style={{marginTop:'1em'}}>
                                            <div style={{fontWeight:'bold'}}>{locals.enterpriseRegister.newEnterpriseDetails}</div>
                                        </div>
                                        <div style={{marginTop:'0.25em'}}>
                                            <Label>{locals.businessName}</Label>
                                            <Input className={errors.businessName ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} value={newEnterprise.businessName} onChange={(e)=> handleChange({id: "businessName", value: e.target.value})} />
                                        </div>
                                        {/* <div style={{marginTop:'1em'}}>
                                            <Label>{locals.yTunnus}</Label>
                                            <Input className={errors.businessID ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} value={newEnterprise.businessID} onChange={(e)=> handleChange({id: "businessID", value: e.target.value})} />
                                        </div> */}
                                    </Col>
                                    :
                                    <Col xs="12">
                                        <div style={{marginTop:'1em'}}>
                                            <div style={{fontWeight:'bold'}}>{locals.enterpriseRegister.privateUserDetails}</div>
                                        </div>
                                        <div style={{marginTop:'0.25em'}}>
                                            <Label>{locals.enterpriseRegister.privateUserName}</Label>
                                            <Input className={errors.businessName ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} value={newEnterprise.businessName} onChange={(e)=> handleChange({id: "businessName", value: e.target.value})} />
                                        </div>
                                    </Col>
                                }
                                <Col xs="12">
                                    <div style={{marginTop:'2em'}}>
                                        <div style={{fontWeight:'bold'}}>{locals.enterpriseRegister.loginDetails}</div>
                                    </div>
                                    <div style={{marginTop:'0.25em'}}>
                                        <Label>{locals.email}</Label>
                                        <Input className={errors.email ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} value={email} onChange={(e) => handleEmail(e.target.value)} />
                                    </div>
                                    <div style={{marginTop:'1em'}}>
                                        <Label>{locals.password}</Label>
                                        <Input className={errors.password ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} type="password" value={password} onChange={(e)=> handlePassword(e.target.value)} />
                                    </div>
                                    <div style={{marginTop:'1em'}}>
                                        <Label>{locals.confirmPassword}</Label>
                                        <Input className={errors.confirmPassword ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} type="password" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop:'1em'}}>
                                <Col>
                                    <label className="form-check-label" htmlFor="termsCheck" style={{marginLeft:'1.5em'}}>
                                        <Input onClick={(e) => {handleTermsCheck(e)}} style={{cursor:'pointer'}} className="form-check-input" type="checkbox" checked={termsCheck} name="termsCheck" required />
                                        <div style={errors.termsCheck?{color:'red'}:{}}>{locals.enterpriseRegister.termsOfUse1}<span className="go-back-link" style={{ cursor:'pointer'}} onClick={()=> toggleTermsOfUse()}>{locals.enterpriseRegister.termsOfUse2}</span></div>
                                        {/* {errors.termsCheck && <i className="fas fa-exclamation-circle" style={{ color: '#dc3545', marginLeft: '1rem', fontSize: '1.1rem' }}></i> } */}
                                    </label>
                                    <div style={{marginLeft:'1.5em'}}>
                                        
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop:"2em"}}>
                                <Col>
                                    <button className="small-white-button confirm-button" style={{width:'100%'}} onClick={()=> checkRegisterData()}>{locals.enterpriseRegister.registerButton}</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    )
}

const TermsOfUseModal = ({locals, isOpen, toggle}) => {
    const [header,setHeader] = useState("")
    useEffect(()=>{
        
        if(locals && locals.enterpriseRegister && locals.enterpriseRegister.termsOfUse2){
            let str = locals.enterpriseRegister.termsOfUse2.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            setHeader(str)
        }
    },[locals,isOpen])
    
    return(
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>{header}</ModalHeader>
            <ModalBody>
                {locals.enterpriseRegister.termsText}
            </ModalBody>
            <ModalFooter>
                <div className="go-back-link" onClick={toggle} style={{marginLeft:'0.25em', marginRight:'auto', cursor:'pointer'}}>{locals.back}</div>
            </ModalFooter>
        </Modal>
    )
}