
let url
let pathrul
let host
let beta_host
let frontPage

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  url = "http://localhost:9001/api/homepage/";
  host = "http://localhost:9001";
  pathrul = "";
  beta_host = "http://localhost:9001";
  frontPage = "http://localhost:3008/"
} else {
  url = "https://app.everbetter.fi/api/homepage/";
  host = "https://app.everbetter.fi";
  beta_host = "https://portal.everbetter.fi";
  pathrul = "";
  frontPage = "https://app.everbetter.fi/"
}
export let confs = {
  url: url,
  pathurl: pathrul,
  host: host,
  beta_host: beta_host,
  frontPage: frontPage
};

