import React from 'react'
import Spinner from '../LoadingSpinner'
import './codeInput.css'

/** Only a sketch, waiting for proper specs */

class CodeInput extends React.Component {
  
  constructor() {
    super()

    this.state = {
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      input4: ''
    }

    this.codeInputs = []
  }

  onCodeInput = (e) => {
    let inputIndex = this.codeInputs.indexOf(e.target)

    if(e.target.value.length === 1) {
      const stateInput = `input${inputIndex}`
    
      this.setState({
        [stateInput]: e.target.value
      }, () => {
        if(this.codeInputs[inputIndex + 1]) {
          this.codeInputs[inputIndex + 1].focus()
        } else {
          if(this.isCodeReady()) {
            this.getCodeAndSubmit()
          }
        }
      })
    } 
  }

  getCodeAndSubmit = () => {
    let code = ''
    this.codeInputs.map(input => {
      if(input) {
        return code += input.value.toUpperCase()
      }
    })

    this.setState({
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      input4: ''
    }, () => {
      this.codeInputs[0].focus()
      this.handleSubmit(code)
    })
  }

  handleBackspace = (e) => {
    if(e.key === 'Backspace') {
      const inputIndex = this.codeInputs.indexOf(e.target)
      const stateInput = `input${inputIndex}`
      const perseStateInput = `input${inputIndex - 1}`

      this.setState({
        [stateInput] : '',
        [perseStateInput] : ''
      }, () => {
        if(this.codeInputs[inputIndex - 1]) {
          this.codeInputs[inputIndex - 1].focus()
        }
      })

    }
  }

  handleSubmit(code) {
    this.setState({submitted: true})

    this.props.onSubmit(code)
  }

  componentDidMount() {
    if(this.codeInputs.length > 0){
      this.codeInputs[0].focus()
    }
  }

  onCodePaste = (e) => {
    const startIndex = this.codeInputs.indexOf(e.target)
    const pastedCode = e.clipboardData.getData('Text').split('')
    let updatedInputs = {}
    let lastIndex = 0

    pastedCode.map(( char, index ) => {
      if(this.codeInputs[ startIndex + index ]) {
        updatedInputs[`input${startIndex + index}`] = char
        this.codeInputs[ startIndex + index ].value = char
        lastIndex = startIndex + index
      }
    })

    this.setState(updatedInputs, () => {
      setTimeout( () => {
        if( this.isCodeReady() ) {
          this.codeInputs[ lastIndex ].focus()
          this.getCodeAndSubmit()
        }
      }, 200)
    })
  }

  isCodeReady = () => {
    let codeIsReady = true

    this.codeInputs.map(( input ) => {
      if( codeIsReady && input && input.value.trim() === '' ) {
        codeIsReady = false
        input.focus()
      }
    })

    return codeIsReady
  }

  handleFocus = ( e ) => {
    e.target.select()
  }

  render() {
    return (
      <div className="container-fluid codeinput-container">
        {this.props.codeLoginInProgress ? (
          <div className="row">
            <Spinner class='block-loader' locals={this.props.locals}/>
          </div>
        ) : (
          <div className='row codeinput-row'>
            <form className="form-inline">
              <div className="input-group">
                <input value={this.state.input0} ref={(input) => {this.codeInputs[0] = input}} onKeyDown={(e) => this.handleBackspace(e)} onChange={(e) => this.onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex="1" onPaste={this.onCodePaste} onFocus={this.handleFocus} />
                <input value={this.state.input1} ref={(input) => {this.codeInputs[1] = input}} onKeyDown={(e) => this.handleBackspace(e)} onChange={(e) => this.onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex="2" onPaste={this.onCodePaste} onFocus={this.handleFocus} />
                <input value={this.state.input2} ref={(input) => {this.codeInputs[2] = input}} onKeyDown={(e) => this.handleBackspace(e)} onChange={(e) => this.onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex="3" onPaste={this.onCodePaste} onFocus={this.handleFocus} />
                <input value={this.state.input3} ref={(input) => {this.codeInputs[3] = input}} onKeyDown={(e) => this.handleBackspace(e)} onChange={(e) => this.onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex="4" onPaste={this.onCodePaste} onFocus={this.handleFocus} />
                <input value={this.state.input4} ref={(input) => {this.codeInputs[4] = input}} onKeyDown={(e) => this.handleBackspace(e)} onChange={(e) => this.onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex="5" onPaste={this.onCodePaste} onFocus={this.handleFocus} />  
              </div>
            </form>
          </div>
        )}
        
      </div>
    )
  }
}

export default CodeInput;