import React from 'react'
import './spinner.css';

/**
 * Possible classes:
 * block-loader
 * spinner-loader
 */
export default function Spinner(props) {
  return (
    <div className={props.class}>{props.locals.loading}</div>
  )
}
