import React, { useState, useRef, forwardRef } from 'react'
import {
  Row,
  Col
} from 'reactstrap'

import {confs} from '../../../../bootstrap/config';
import {NotificationManager} from 'react-notifications';

import {ResizedScrollBar} from './hocs/ResizedScrollBar';

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem', alignSelf: 'center' }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}


const ListBlock = ({item, itemId, planId}) => {
    const isCancelled = useRef(false);
    const [ data, setData] = useState({});
    const [ isLoading, setIsLoading ] = useState( false )
    const [size, setSize] = useState(item.size || {height: 500, width: '100%'});

    React.useEffect(() => {
        let payload = {
            planId: planId,
            target: itemId,
            type: 'list'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    
    }, []);

    const fetchData = async payload => {
        try {
            const response = await fetch( confs.url + 'embedItem', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                if(!isCancelled.current)
                    setData(responseJson);
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current)
            setIsLoading(false);
    }

    return (
        <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className="plan-object" id={item.id}>
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
                <div className="dashboard-medium-box" style={{marginTop: 0, ...size}}>
                    {isLoading ? 
                        <div style={{height: '100%', width: '100%'}}>
                            <MegaLoaderSpinner />
                        </div>
                    : <ResizedScrollBar size={size} title={data.name}>
                            { 
                                [...Array(10).keys()].map(number => {

                                    let oddEvenClass = number % 2 === 0 ? 'even-row' : 'odd-row'

                                    return (
                                        Object.keys(data).length > 0 && 
                                        <div key={number} className={oddEvenClass} style={{ padding: '1em' }}>
                                            <Row>
                                                {Object.keys(data.data).map((key, index) => {
                                                    if (data.data[key] !== '') {
                                                        return <Col key={index}>{data.data[key]}</Col>
                                                    }
                                                })}

                                            </Row>
                                        </div>
                                    )
                                })
                            }
                        </ResizedScrollBar>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default ListBlock

