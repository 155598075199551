import * as ActionTypes from '../actions/planActions/actionTypes'

const initialState = {
  selectedPlan: null,
  isLocked: true
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case ActionTypes.GET_PLAN:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: {...action.payload}
      }
    case ActionTypes.SET_SELECTED_PLAN_ITEM:
      return {
        ...state,
        selectedPlanItem: {...action.payload}
      }
    default:
      return state
  }
}

export default reducer