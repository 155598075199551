import React, { useEffect, useState } from 'react';
import { Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, InputGroup, Button } from 'reactstrap';
import TextEditor from '../../modules/TextEditor/texteditor';
import './sheetsurvey.css';
import Slider from 'rc-slider'
import posed from 'react-pose';
import { NotificationManager } from 'react-notifications'
import { Attachment } from './subComponents/Attachment';
import { DayPickerSingleDateController } from 'react-dates'
import { detect } from 'detect-browser'
import Tooltip from 'rc-tooltip';
import { SheetNav } from './subComponents/SheetNav';
import { confs } from '../../../bootstrap/config';
import { convertFromRaw, EditorState } from 'draft-js'
import { checkYTunnus } from '../../modules/yTunnusHelper';

let baseUrl = confs.url

const Handle = Slider.Handle;

let browser = detect();

export const SheetSurvey = ({surveyObj, locals, answerSessionId, clearAnswerSession, endSurvey, getEndTextParams, handleAutomation, sentSurvey}) => {
    const {id} = surveyObj
    const [answerSession, setAnswerSession] = useState([])
    const [questionInfo, setQuestionInfo] = useState({})
    const [questionInfoModal, setQuestionInfoModal] = useState(false)
    const [hasBusinessId, setHasBusinessId] = useState(false)
    const [businessIdAnswered, setBusinessIdAnswered] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [navPoints, setNavPoints] = useState([])
    const [finalFeedback, setFinalFeedback] = useState(null)
    const [automationFeedback, setAutomationFeedback] = useState({url:"", text:""})
    const [surveyEnded, setSurveyEnded] = useState(null)
    const [surveyEndText, setSurveyEndText] = useState(locals.surveyThanks)
    const [sendEmailModal, setSendEmailModal] = useState(false)
    const [givenEmail, setGivenEmail] = useState("")
    const [emailOk, setEmailOk] = useState(false)
    const [oldAnswerSessionId, setOldAnswerSessionId] = useState("")
    const [logoUrl, setLogoUrl] = useState("http://192.168.1.109:9001/api/business/getSenderLogo/5bed15bef4a9436fea223ddc")
    const [showEmailButton, setShowEmailButton] = useState(false)
    const [hasEmptyOptionAnswer,setHasEmptyOptionAnswer] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    let questionNumber = 0

    const handleAnswerSession = (groupId, questionId, answer) => {
        let session = answerSession
        
        session.map((group, groupKey) => {
            if(groupKey === groupId){
                group.map((question, answerKey)=>{
                    if(answerKey === questionId){                       
                        if(question.data.isBusinessId){
                            let businessId = (answer && answer.length> 0) ? answer.trim() : "" 
                            question.answer = businessId
                            if(checkYTunnus(businessId)){
                                setBusinessIdAnswered(true)
                            }

                        } else {
                            question.answer = answer
                        }
                    }
                })
            }
        })
        setAnswerSession([...session])
    }


    const checkEmailSendingAllowed = async() => {
        let payload = {
            surveyId: surveyObj._id
        }
        try {
            const responseJson = await fetch(baseUrl + 'sheetSurveyAllowEmail', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                if(response && response.success){
                    setShowEmailButton(response.success)
                } else {
                    setShowEmailButton(false)
                }
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    useEffect(()=>{
        let groups = []
        setOldAnswerSessionId(answerSessionId)
        let navGroups = []
        surveyObj.questionGroups.map((group, groupKey) => {
            let questions = surveyObj.questionGroups[groupKey].questions || []
            let objects = [] 
            let navPoints = []
            let groupColor = surveyObj.questionGroups[groupKey].groupColor || '#FFCC00'
            questions.map((question, questionId) => {
                if(question){
                    if(question.isDateQuestion){
                        objects = [...objects, {groupId: group.id, answer:{date:"",focused:null}, data:question }] 
                    } else if(question.isNPS){
                        objects = [...objects, {groupId: group.id, answer:{npsNumber:0, npsOld:0}, data:question, numberChanged:false, color:{oldNpsColor:"", newNpsColor:""}}] 
                    } else if(question.isFreetextQuestion){
                        objects = [...objects, {groupId: group.id, answer:"", data:question, }] 
                    } else if (question.isComment){
                        objects = [...objects, {groupId: group.id, answer:"", data:question}]
                    } else if (question.isBusinessId){
                        objects = [...objects, {groupId: group.id, answer:"", data:question}]          
                    }    else if (question.isAttachmentQuestion){
                        objects = [...objects, {groupId: group.id, answer:"", data:question}]          
                    } else { 
                        objects = [...objects, {groupId: group.id, answer:"", data:question, isMultiSelection: true}]                     
                    }
                    
                    if(question.isBusinessId){
                        setHasBusinessId(true)
                    }

                    navPoints = [...navPoints, {title: question.text , id:(groupKey+"-"+questionId)}]
                }
                
            })
           groups = [...groups, [...objects]]
           navGroups = [...navGroups, {groupTitle: group.title, groupColor: groupColor, navPoints:[...navPoints]}]
        })
        setAnswerSession(groups)
        setNavPoints(navGroups)
        checkEmailSendingAllowed()
    },[])


    const toggleQuestionInfo = (questionInfo = null) => {
        if(questionInfo){
            setQuestionInfoModal(true)
            setQuestionInfo(questionInfo)
        } else {
            setQuestionInfoModal(false)
            setQuestionInfo({})
        }
    }

    const submitAnswers = () => {
        let emptyOption = false
        let answers = answerSession
        for(let group of answers){
            for(let answer of group){
                if(answer.isMultiSelection){
                    if(!answer.answer || (answer.answer && !answer.answer.id)){
                       setHasEmptyOptionAnswer(true)
                       emptyOption = true;
                       break
                    } 
                }
            }
        }

        if(hasBusinessId && !businessIdAnswered){
            NotificationManager.error(locals.businessIdRequired, locals.error, 3000);
        } else{ 
            if (emptyOption){
                NotificationManager.error(locals.optionRequired, locals.error, 10000);
            }else {
                setConfirmationModal(!confirmationModal)
            } 
        }
    }

    const handleSubmit = async() => {
        if (!browser) {
            browser = "not detectable"
        }

        let payload = {
            answerSession: answerSession,
            surveyId: surveyObj._id,
            sentSurveyId: surveyObj.sentSurveyId,
            answerSessionId: answerSessionId,
            browser: browser
        }

        try {
            const responseJson = await fetch(baseUrl + 'sheetSurveyAnswer', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                if(response && response.success){
                    setConfirmationModal(!confirmationModal)
                    clearAnswerSession() 
                    checkAutomation()
                    let fetchFeedback = await getSurveyEndedTextWithParameters()
                    setSurveyEndText(fetchFeedback)
                    setSurveyEnded(true)
                } else {
                    NotificationManager.error(locals.errorOccurred, locals.error, 3000)
                }
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
       
    }
const checkAutomation = async() => {
    if (sentSurvey.automationSettings) {
        let params = {
            answerSessionId: answerSessionId,
            sentSurveyId: surveyObj.sentSurveyId
        }

        if (!sentSurvey.automationSettings.showOnAnswer && !sentSurvey.automationSettings.sendOnAnswer) {
            const response = await handleAutomation(params)
        }

        if (sentSurvey.automationSettings.showOnAnswer || sentSurvey.automationSettings.sendOnAnswer) {
            let generated = await handleAutomation(params)

            if (generated.published) {
                let planUrl = confs.frontPage + 'plan/' + generated.publishedId
                let text = sentSurvey.automationSettings.linkText || 'Voit katsoa automaattisesti luodun lomakkeen tästä'

                setAutomationFeedback({ url: planUrl, text: text })
            }
        }
    }
}

    const handleDateObjects = (groupId, questionId, data, type) => {
        let session = answerSession
        
        session.map((group, groupKey) => {
            if(groupKey === groupId){
                group.map((question, answerKey)=>{
                    if(answerKey === questionId){
                        question.answer = {
                            ...question.answer, 
                            [type]: data
                        }
                    }
                })
            }
        })
        setAnswerSession([...session])
    }

    const handleNPSChnge = (groupId, questionId, val) => {
        let session = answerSession
        if (!isNaN(val)) {
            let oldValue = answerSession[groupId][questionId].answer.npsNumber;
            let newValue = Math.round(val / 10);
      
            if (newValue === 0) {
              newValue = 1;
            }
            if (oldValue === 0) {
                oldValue = newValue;
            }
           
            session.map((group, groupKey) => {
                if(groupKey === groupId){
                    group.map((question, answerKey)=>{
                        if(answerKey === questionId){
                            if ( question.answer.npsNumber !== newValue) {
                                //numberchanged here
                                question.answer = {
                                    ...question.answer, 
                                    npsOld: oldValue,
                                    npsNumber:newValue
                                }  
                            }
                            question.color = {
                                ...question.color, 
                                oldNpsColor: question.color.newNpsColor
                            }

                            if (oldValue < 7 && newValue >= 7) {
                                question.numberChanged = true
                            
                            } else if ((oldValue >= 7 && oldValue < 8) && (newValue < 7 || newValue >= 8)) {
                                question.numberChanged = true
                            
                            } else if (oldValue >= 8 && newValue < 8) {
                                question.numberChanged = true
                            }

                            if (newValue < 7) {
                                question.color = {
                                    ...question.color, 
                                    newNpsColor: '#FF0000'
                                }
                            } else if (newValue >= 7 && newValue < 9) {
                                question.color = {
                                    ...question.color, 
                                    newNpsColor: '#fc0'
                                }
                            } else if (newValue >= 9) {
                                question.color = {
                                    ...question.color, 
                                    newNpsColor: '#b5e413'
                                }
                            }
                        }
                    })
                }
            })
            setAnswerSession([...session])
        }
    }

    const navPointClicked = (id) => {
        if(id){
            let element = document.getElementById(id)
            element.scrollIntoView({
                block: "center", 
                behavior: 'smooth'
            })
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left:0,
            behavior: 'smooth'
        });
    }

    const scrollToBottom = () => {
        let scrollHeight = document.body.scrollHeight
        window.scrollTo({
            top: scrollHeight,
            left:0,
            behavior: 'smooth'
        });
    }

    const getSurveyEndedTextWithParameters = async () => {
        const survey = surveyObj
    
        return new Promise(async (resolve) => {
          if (typeof survey.surveyEndedText === 'object') {
            let { surveyEndedText } = survey
    
            const entities = getEntitiesFromText(surveyEndedText)
    
            // send the list to server and get the answer values in response
            const params = {
              sentSurveyId: surveyObj.sentSurveyId,
              answerSessionId: oldAnswerSessionId,
              entities: entities,
              survey: survey,
              surveyEndedText: surveyEndedText
            }

            const parameterValues = await getEndTextParams(params)

            if (parameterValues && parameterValues.text) {
              resolve(parameterValues.text)
            } else if (parameterValues.error){
                resolve(parameterValues.error)
            } else{
                NotificationManager.error(locals.errorOccurred, locals.error, 4000)
            }
          }
          resolve(surveyEndText)
        })
    }
    
    const getEntitiesFromText = text => {
        if(text !== undefined){
          const editorState = EditorState.createWithContent(convertFromRaw(text))
          const contentState = editorState.getCurrentContent()
          const blocks = contentState.getBlocksAsArray()
    
          const entities = []
    
          // find all parameters tagged with @kysymys from the text and collect them to a list
          blocks.forEach((block) => {
            if (block.getText().indexOf('@kysymys') !== -1) {
              block.findEntityRanges(
                (char) => {
                  if (char.getEntity() !== null) {
                    const entity = contentState.getEntity(char.getEntity())
                    let { mention } = entity.getData()
    
                    entities.push(mention)
                  }
                }
              )
            }
            return null
          })
    
          return entities
        } else {
          return null
        }
    }

    const handleEmail = (email) => {
        // TODO - REGEX check for email to ease the checkup for user 
        setGivenEmail(email)

        const emailRegex = /[^@]+@[^\.]+\..+/

        if (emailRegex.test(email)) {
           setEmailOk(true)
        } else{
            setEmailOk(false)
        }
    }

    const sendFinalFeedbackToEmail = async() => {
        if(emailOk){
            const entities = getEntitiesFromText(surveyObj.finalFeedback)
            let payload = {
                surveyId: surveyObj._id,
                sentSurveyId: surveyObj.sentSurveyId,
                entities: entities,
                email:givenEmail,
                answerSessionId:oldAnswerSessionId
            }

            try {
                const responseJson = await fetch(baseUrl + 'sendSheetFeedback', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                })
        
                if(responseJson) {
                    const response = await responseJson.json()
                    if(response && response.success){
                    // setSendEmailModal(!sendEmailModal)
                    setEmailSent(true)
                    NotificationManager.success(locals.emailSentSuccess, "", 3000)
                    } else {
                        NotificationManager.error(locals.emailSentError, locals.error, 3000)
                    }
                    return response;
                } else {
                    return false;
                }
            } catch(error) {
                return Promise.reject(error);
            }
        }
    }

    const copyPlanLink = (value) => {
        var tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        NotificationManager.success(locals.linkCopied, "", 3000);
    }
    
    return(
        <>
            <Modal isOpen={confirmationModal} toggle={() => setConfirmationModal(!confirmationModal)} className="infoModal">
                <ModalHeader toggle={() =>setConfirmationModal(!confirmationModal)}>{locals.confirmSubmission}</ModalHeader>
                <ModalBody>
                    <span>{locals.sheetConfirmationText}</span>
                </ModalBody>
                <ModalFooter>
                    <div style={{ minWidth: '100%' }}>
                        <Row>
                            <Col>
                                <button className="small-white-button" onClick={()=> setConfirmationModal(!confirmationModal)} style={{ width: '100%' }}>{locals.goBack}</button>
                            </Col> 
                            <Col> 
                                <button className="small-white-button" onClick={()=> handleSubmit()} style={{ width: '100%' }}>{locals.surveySubmitButton}</button>
                            </Col>
                        </Row>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={questionInfoModal} toggle={() => toggleQuestionInfo()} className="infoModal">
                <ModalHeader toggle={() => toggleQuestionInfo()}>{locals.moreInfo}</ModalHeader>
                <ModalBody>
                    {questionInfo &&
                        <TextEditor content={questionInfo} readOnly={true} onTextChange={() => { return }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <button className="small-white-button" onClick={()=> toggleQuestionInfo()} style={{ width: '100%' }}>{locals.close}</button>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={sendEmailModal} toggle={() => setSendEmailModal(!sendEmailModal)} className="infoModal">
                <ModalHeader toggle={() => setSendEmailModal(!sendEmailModal)}>{locals.sendFeedbackToEmail}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <h5>{locals.emailRequestMessage}</h5>
                        </Col>
                    </Row>
                    <Row className="answers-container">
                        <Col>
                            <Input className="freetext-answer" placeholder={locals.emailAnswerPlaceHolder} value={givenEmail} onChange={(e) => handleEmail(e.target.value)}/>
                                {givenEmail.length > 0 && 
                                    <div className="email-check-box">
                                        {emailOk?
                                            <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                            :
                                            <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                        }
                                    </div>
                                }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div style={{ minWidth: '100%' }}>
                        <Row>
                            <Col>
                                <button className="small-white-button" onClick={() => setSendEmailModal(!sendEmailModal)} style={{ width: '100%' }}>{locals.goBack}</button>
                            </Col> 
                            <Col> 
                                <button className="small-white-button" onClick={()=> sendFinalFeedbackToEmail()} disabled={!emailOk} style={{ width: '100%' }}>{locals.sendFeedback}</button>
                            </Col>
                        </Row>
                    </div>
                </ModalFooter>
            </Modal>
            {surveyEnded ? 
                <>
                    <Col xs="12" className="survey-canvas survey-main-" style={{ borderRadius:'10px', backgroundColor: '#fefefe', width: '100%', marginTop: '1em', paddingTop: '2em'}}>
                        {/* <Col size='12' className="sheet-survey-ended-container feedback-header">
                            <h4 className="survey-ended-header">{locals.surveyThanks}</h4>
                            {surveyObj.finalFeedback &&
                                <div className="send-feedback-button">
                                    <i style={{ fontSize: '3rem' }} onClick={() => setSendEmailModal(!sendEmailModal)} className="fas fa-envelope-square sheet-email-sender"></i>
                                    <div className="triangle"></div>
                                    <div className="feedback-info">{locals.sendFeedbackToEmail}</div>
                                </div>
                            } 
                        </Col> */}
                        <Col size='12' style={{ paddingTop: '2em' }} className="sheet-survey-ended-container">
                            {showEmailButton &&
                                <div style={{ marginTop:'1em', marginBottom:'3em' }}> 
                                    <Label><b>{locals.surveyEndedEmailInfo}</b></Label>
                                    <div style={{display:'flex'}}>
                                        <InputGroup>
                                            <Input className="form-control form-control-lg" placeholder={locals.emailAnswerPlaceHolder} value={givenEmail} onChange={(e) => handleEmail(e.target.value)} />
                                            
                                            <Button style={{ backgroundColor: emailOk?'#acd811':'#fff', color:'#404040', fontWeight:'bold'}} onClick={()=> sendFinalFeedbackToEmail()} disabled={!emailOk}>
                                                {locals.sendFeedbackToEmail}
                                            </Button>
                                        </InputGroup>

                                        {/* {givenEmail.length > 0 && 
                                                <div className="email-check-box">
                                                    {emailOk?
                                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                                    }
                                                </div>
                                        } */}
                                    </div>
                                    {emailSent && <p style={{}}><i className="fas fa-check" style={{ color: '#B5E413' }}></i>{locals.emailSent}</p>}
                                    {/* <button style={{ marginTop: '2em', fontWeight: 'bold' }}  onClick={() => setSendEmailModal(!sendEmailModal)} className="survey-ended-button">{locals.sendFeedbackToEmail}</button> */}
                                </div>
                            }
                            <TextEditor content={surveyEndText?surveyEndText : (surveyObj.surveyEndedText? surveyObj.surveyEndedText : locals.surveyThanks)} readOnly={true} onTextChange={() => { return }} textAlignment='center' />
                            { sentSurvey && sentSurvey.automationSettings && sentSurvey.automationSettings.showOnAnswer &&
                                <div style={{ marginTop: '2em', fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                                    <a href={automationFeedback.url} target="_blank" rel="noopener noreferrer">{automationFeedback.text}</a>
                                    <button className="copy-automation-link" title={locals.copyLink} onClick={() => document.queryCommandSupported('copy') && copyPlanLink(automationFeedback.url)}>
                                        <i className="far fa-copy" style={{ zIndex:10}}></i>  
                                    </button>
                                </div>
                            }
                        </Col>
                        <Col size='12' style={{ paddingTop: '4em' }} className="sheet-survey-ended-container">
                            <p style={{ marginTop: '1em' }}>{locals.endingDirections}</p>
                            <button style={{  fontWeight: 'bold' }} onClick={endSurvey} className="survey-ended-button">{locals.surveyHomePageButton}</button>
                        </Col>
                    </Col>
                </> 
                :
                <>
                    {answerSession &&
                        <>
                            <SheetNav
                                navPoints={navPoints}
                                pointClicked={navPointClicked}
                                locals={locals}
                                hasEmptyOptionAnswer={hasEmptyOptionAnswer}
                                answerSession={answerSession}
                                businessIdAnswered={businessIdAnswered}
                            />
                            <Col xs="12" className="survey-canvas survey-main-" style={{ borderRadius:'10px', backgroundColor: '#fefefe', width: '100%', marginTop: '1em' }}>
                                <Row style={{ padding: '2em 1em 2em 1em' }}>
                                    <Col className="center-in-sheet" style={{justifyContent: 'start' }}>
                                        <div className='sheet-logo-div' style={logoUrl? { backgroundImage: `url(${confs.host}/api/homepage/getLogo/${surveyObj.enterpriseId})`, minWidth:'70px', height:'70px', backgroundSize: 'contain', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat' }: {}}></div>
                                    </Col>
                                    <Col xs="10" className="center-in-sheet" style={surveyObj.title.length > 40 ?  { justifyContent: 'start' } : {}}>
                                        <h2 className="sheet-header">{surveyObj.title}</h2>
                                    </Col>
                                    <Col className="sheet-header-balance"></Col>
                                    {/* <div className='sheet-logo-div' style={logoUrl? { backgroundImage: `url(${confs.host}/api/homepage/getLogo/${surveyObj.enterpriseId})`, width:'170px', height:'70px', backgroundSize: 'contain', backgroundPosition: 'left center', backgroundRepeat: 'no-repeat' }: {}}></div> */}
                                    <Col xs="12" className="center-in-sheet" >
                                        <TextEditor content={surveyObj.text || ""} readOnly={true} onTextChange={() => { return }} additionalClass="mt-2" />
                                        {/* {surveyObj.plaintextCategoryExplanation || "" } */}
                                    </Col>
                                </Row>
                            </Col>
                            
                            {answerSession && answerSession.length > 0 && surveyObj && surveyObj.questionGroups && surveyObj.questionGroups.map((group, groupId) => {
                                let groupColor = surveyObj.questionGroups[groupId].groupColor || '#FFCC00'
                                let questions = surveyObj.questionGroups[groupId].questions || []
                                return(
                                    <Col key={groupId} xs="12" className="survey-canvas" style={{ borderRadius:'10px', backgroundColor: '#fefefe', width: '100%', marginBottom:'1em', marginTop: '1em'}}>
                                        <Row xs="12" className="center-in-sheet" style={{fontSize:'1.6rem', fontWeight: 'bold', backgroundColor: groupColor, color: '#fff', borderLeft: '3px solid ' + groupColor, borderRight: '3px solid ' + groupColor, borderRadius:'10px 10px 0 0'}}><p style={{ margin:0 }}>{group.title}</p></Row>
                                        <Row className="sheet-group-col" key={groupId} style={{borderBottom: '1em solid '+ groupColor, borderLeft: '3px solid ' + groupColor, borderRight: '3px solid ' + groupColor, borderRadius: '0 0 10px 10px', }}>
                                            <Col xs="12" className="sheet-item-col center-in-sheet">
                                                <TextEditor content={group.text || ""} readOnly={true} onTextChange={() => { return }} additionalClass="mt-2" />
                                                {/* <span className="sheet-group-description">{group.plaintextGroupDescription || ""}</span> */}
                                            </Col>
                                            {questions && questions.map((question, questionId)=>{
                                                let questionText = question.text? question.text : ''
                                                questionNumber+=1
                                                return(
                                                    <Col xs="12" key={questionId} id={groupId + "-" +questionId} style={questionId % 2 === 0 ?{backgroundColor:groupColor+'14'}:{}} className={'oddEvenClass' + " sheet-item-col " + ((hasEmptyOptionAnswer && answerSession[groupId][questionId] && answerSession[groupId][questionId].isMultiSelection && (!answerSession[groupId][questionId].answer || !answerSession[groupId][questionId].answer.id )) ? "empty-answer" : "")} > 
                                                        <div style={{}}><p>{questionNumber}</p></div>
                                                        <div className="sheet-question-header-row center-in-sheet" style={{ textAlign: 'center' }}>
                                                            <h5 style={{fontWeight:'bold', color: '#404040' }}>
                                                                {questionText}
                                                            </h5>
                                                        </div>
                                                        {question.information && question.information.blocks && (question.information.blocks.length > 1 || question.information.blocks[0].text.length > 0) &&
                                                            <div className="sheet-question-info" onClick={() => toggleQuestionInfo(question.information)} title="info" style={{ borderColor: groupColor, cursor: 'pointer', color: '#404040', marginLeft: '2em', boxShadow: '0 0 0 '+groupColor}}>
                                                                <i className="fas fa-info"></i>
                                                            </div>
                                                        }
                                                        {
                                                            (question.isFreetextQuestion && question.isFreetextQuestion === true) ? 
                                                                <>
                                                                    <TextEditor id="freetext" content={(answerSession && answerSession[groupId] && answerSession[groupId][questionId] && answerSession[groupId][questionId].answer) ? answerSession[groupId][questionId].answer : ''} readOnly={false} additionalClass="freetext-answer sheet-survey-editor sheet-editor" onTextChange={editorState => handleAnswerSession(groupId,questionId,editorState, question)} placeholder={questions[questionId].placeholder ? questions[questionId].placeholder : locals.freetextPlaceholder} textAlignment='left' allowFileUpload={true} documentId={surveyObj.sentSurveyId} sender={surveyObj.enterpriseId} groupColor={groupColor}/>
                                                                    {/* <textarea className="freetext-answer" placeholder={this.state.currentGroup.questions[this.state.questionIndex].placeholder ? this.state.currentGroup.questions[this.state.questionIndex].placeholder : locals.answerPlaceHolder} value={this.state.freetextAnswer} onChange={(e) => this.setState({ freetextAnswer: e.target.value })}></textarea> */}
                                                                    {/* pose={this.state.changingQuestion ? 'closed' : 'open'} */}
                                                                    {/* <button  onClick={() => {return}} style={{ padding: "1rem", margin: "0", borderColor: groupColor }} className="answer-button survey-answer-button">{locals.answerButton}</button> */}
                                                                </>
                                                            :
                                                            (question.isBusinessId && question.isBusinessId === true)  ? 
                                                                <>
                                                                    <textarea className="freetext-answer" placeholder="Esimerkiksi 1234567-1" value={answerSession[groupId][questionId].answer} onChange={(e) => handleAnswerSession(groupId,questionId, e.target.value, question)}></textarea>
                                                                    {/* <button className="dialog-answer-button" disabled={!checkYTunnus(this.state.businessId)} style={{ padding: "1rem", margin: "0", borderColor: this.state.groupColor || '#b5e413' }} onClick={(e) => this.onAnswer(e)}>{this.props.locals.answerButton}</button> */}
                                                                </>
                                                            :
                                                            (question.isVariableQuestion && question.isVariableQuestion === true) ?
                                                                <div>
                                                                    <Col>
                                                                        {/* <p className="mx-auto" style={{ textAlign: 'left'}}><small>{locals.answerOptionText}</small></p> */}
                                                                        {question && question.questionOptions && question.questionOptions.map((obj, index) => {                                                       
                                                                            return( 
                                                                                <div key={index} className="sheet-option-row">                                                              
                                                                                    <button onClick={() => handleAnswerSession(groupId, questionId, obj,question)} key={index} className={"sheet-option-button answer-button"} style={(answerSession[groupId][questionId].answer.text === obj.text)? {backgroundColor:groupColor, color:'#fff', fontWeight:'bold'}:{borderColor:groupColor}}>    
                                                                                    </button>  
                                                                                    <span className="sheet-option-text">{obj.text}</span>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </Col>
                                                                </div>
                                                            :
                                                            (question.isDateQuestion && question.isDateQuestion === true ) ?
                                                                <Row style={{ justifyContent: 'center' }}>
                                                                    <Col>
                                                                        <DayPickerSingleDateController
                                                                            date={answerSession[groupId][questionId].answer.date || null}
                                                                            // isDayBlocked={this.isDayBlocked}
                                                                            firstDayOfWeek={1}
                                                                            onDateChange={(date) => handleDateObjects(groupId,questionId,date, "date", question )}
                                                                            onFocusChange={({ focused }) => handleDateObjects(groupId,questionId,focused, "focused", question)}
                                                                            focused={answerSession[groupId][questionId].answer.focused? answerSession[groupId][questionId].answer.focused: null}
                                                                            hideKeyboardShortcutsPanel={true}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            (question.isNPS && question.isNPS === true) ?
                                                                <>
                                                                    <div className="NPS-container">
                                                                        <div className="amazing-number">
                                                                            { answerSession[groupId][questionId].answer.npsNumber > 0 &&
                                                                            <>
                                                                                <NPS style={{ opacity: 0 }} pose={answerSession[groupId][questionId].numberChanged ? 'animateClose' : ''} x={answerSession[groupId][questionId].answer.npsNumber > answerSession[groupId][questionId].answer.npsOld ? -45 : 45} ><i style={{ color: answerSession[groupId][questionId].color.oldNpsColor }} className={npsIcon(answerSession[groupId][questionId].answer.npsOld)}></i></NPS>
                                                                                <NPS pose={answerSession[groupId][questionId].numberChanged ? 'animate' : ''} x={answerSession[groupId][questionId].answer.npsNumber > answerSession[groupId][questionId].answer.npsOld ? 45 : -45} > <i style={{ color:answerSession[groupId][questionId].color.newNpsColor }} className={npsIcon(answerSession[groupId][questionId].answer.npsNumber)}></i> </NPS>
                                                                            </>
                                                                            }
                                                                            <NPStext className="slider-text" pose={answerSession[groupId][questionId].answer.npsNumber > 0 ? 'exit' : 'show'} key="NPStext">{locals.slideBar}</NPStext>
                                                                        </div>
                                                                        {/* {!this.state.reloadSlider && !this.state.changingQuestion && */}
                                                                            <Slider
                                                                                min={10} max={100}
                                                                                steps={1}
                                                                                className="nps-slider"
                                                                                defaultValue={0}
                                                                                onChange={val => handleNPSChnge(groupId,questionId, val)}
                                                                                // onAfterChange={(val) => {return}}
                                                                                railStyle={{ backgroundColor: '#dedede', height: '1em' }}
                                                                                trackStyle={{ backgroundColor: groupColor || '#b5e413', height: '1em' }}
                                                                                handleStyle={{ backgroundColor: groupColor || '#b5e413', borderColor: groupColor || '#b5e413', height: '2.2em', width: '2.2em', marginTop: '-0.6em', boxShadow: `${groupColor} 0px 0px 4px`, display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', buttonColor: groupColor }}
                                                                                handle={handle}
                                                                                marks={{ 10: '1', 100: '10' }}
                                                                                number={answerSession[groupId][questionId].answer.npsNumber}
                                                                            />
                                                                        {/* } */}
                                                                    </div>
                                                                    {/* <button pose={this.state.changingQuestion ? 'closed' : 'open'} onClick={() => this.onAnswer()} disabled={this.state.NPSval ? false : true} style={{ padding: "1rem", marginTop: "1em", borderColor: this.state.groupColor }} className="answer-button survey-answer-button">{locals.continue}</button> */}
                                                                </>
                                                            :
                                                            (question.isComment && question.isComment === true )? 
                                                                <div className="center-in-sheet" > 
                                                                    <TextEditor content={questions[questionId].comment} readOnly={true} onTextChange={() => false} />
                                                                </div>
                                                            :
                                                            (question.isAttachmentQuestion && question.isAttachmentQuestion === true) ? 
                                                            <Attachment
                                                                isSheet={true}
                                                                handleAnswerSession={fileList => handleAnswerSession(groupId,questionId, fileList, question)}
                                                                sentSurveyId={surveyObj.sentSurveyId}
                                                            />
                                                            :
                                                            <div>
                                                                <Col>
                                                                    {/* <p className="mx-auto" style={{ textAlign: 'left' }}><small>{locals.answerOptionText}</small></p> */}
                                                                    {question && question.questionOptions && question.questionOptions.map((obj, index) => {
                                                                        let questionUpdated ={...question, isNormalQuestion:true}
                                                                        return( 
                                                                            <div key={index} className="sheet-option-row">                                                              
                                                                                <button onClick={() => handleAnswerSession(groupId, questionId, obj, questionUpdated)} key={index} className={"sheet-option-button answer-button"} style={(answerSession[groupId][questionId].answer.text === obj.text)? {backgroundColor:groupColor, color:'#fff', fontWeight:'bold'}:{borderColor:groupColor}}>    
                                                                                </button>  
                                                                                <span className="sheet-option-text">{obj.text}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Col>
                                                            </div>
                                                        }
                                                        {/* <div className="bottom-row"><div className="bottom-separator" style={{backgroundColor: groupColor}}></div></div> */}
                                                    </Col>
                                                )
                                            })}
                                        </Row> 
                                    </Col>
                                )
                            })}
                            <div className="sheet-submit-div"><button className="small-white-button wide-button submit-sheetData-button" title={(hasBusinessId && !businessIdAnswered)? locals.businessIdRequired : ""} onClick={()=> submitAnswers()} style={(hasBusinessId && !businessIdAnswered)? { width: '100%', cursor: 'not-allowed' } : { width: '100%' }}>{locals.surveySubmitButton}</button></div>
                            <div className="scroller-container">
                                <div className="scroll-button to-top-button" onClick={() => scrollToTop()}><i className="fas fa-angle-up"></i></div>
                                {/* <div style={{ borderBottom: '1px solid #404040' }}></div> */}
                                <div className="scroll-button to-bottom-button" onClick={() => scrollToBottom()}><i className="fas fa-angle-down"></i></div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

const handle = (props) => {
    const { value, dragging, index } = props;
  
    return (
        <>
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={Math.round(value / 10) > 1 ? Math.round(value / 10) : 1}
                visible={dragging}
                placement="bottom"
                key={index}
            >
                <Handle {...props}><i className="fas fa-grip-horizontal" style={{ color: props.style.buttonColor }}></i></Handle>
            </Tooltip>
        </>
    );
};

const NPStext = posed.div({
    exit: {
      opacity: 0,
      display: 'none',
      transition: { duration: 300 }
    },
    show: {
      opacity: 1,
      display: 'block',
      transition: { delay: 300 }
    }
})
  
  const NPS = posed.div({
    animate: {
      applyAtStart: { opacity: 0, },
      x: 0,
      opacity: 1,
      transition: { duration: 300 }
    },
    animateClose: {
      applyAtStart: { opacity: 1, x: 0 },
      //x: ({ x }) => x,
      opacity: 0,
      transition: { duration: 300 }
    }
})

const npsIcon = (val) => {
    if (val <= 6) {
        return 'far fa-frown'
    }
    if (val >= 7 && val <= 8) {
        return 'far fa-meh'
    }
    if (val >= 9) {
        return 'far fa-smile'
    }
}


