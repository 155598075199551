import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {NotificationManager} from 'react-notifications';
import locals from '../../../locals/locals'

export const ImageButton = ({setFile, buttonClassName=""}) => {
    const {
        getRootProps, 
        getInputProps, 
        acceptedFiles,
        fileRejections
    } = useDropzone({ 
        maxFiles:1,
        maxSize: 16000000,
        accept: 'image/*'
    })
    
    useEffect(() => {
        if(acceptedFiles && acceptedFiles.length > 0){
            setFile(acceptedFiles[0])
            NotificationManager.success("Liite hyväksytty", "Hyväksytty", 2000);
        }
        if (fileRejections && fileRejections.length > 0) {
            let rejectedFile = fileRejections[0]
            rejectedFile.errors.map(e => {
                if(e.code === "file-too-large"){
                    NotificationManager.error("Tiedosto liian suuri", "Virhe", 4000);
                }
                if(e.code === "file-invalid-type"){
                    NotificationManager.error("Tiedostomuoto ei ole hyväksytty", "Virhe", 4000);
                }
            })
        }
    },[acceptedFiles, fileRejections])
    return ( 
        <div {...getRootProps({className: buttonClassName})}>
            <i className="fas fa-camera">
                <input {...getInputProps()} />
            </i>
        </div> 
    )
}