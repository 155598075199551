import React, {useState} from 'react';
import { useInView } from 'react-intersection-observer'
import {
    convertFromRaw,
} from 'draft-js';
import {motion} from 'framer-motion';

const getPlainTopic = rawEditorState => {
    //console.log(rawEditorState);
    if(typeof rawEditorState !== 'string') {
        let plainTopic = convertFromRaw(rawEditorState).getPlainText();
        plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        return plainTopic;
    } else {
        return rawEditorState;
    }
}

const variants = {
    visible: i => ({
        opacity: 1,
        x: 0,
        transition: {ease: 'easeIn', duration: 0.2}
    }),
    hidden: {
        opacity: 0
    }
}

const checkType = (item, topic) => {
    switch(item.type) {
        case 'iFrame':
            return (
                <div className="summary-topic"><i className="far fa-file-code"></i> {topic}</div>
            )
        case 'report':
            return (
            <div className="summary-topic"><i className="fas fa-chart-pie"></i> {topic}</div>
            )
        case 'Excel':
            return (
            <div className="summary-topic"><i className="far fa-chart-bar"></i> {topic}</div>
            )
        case 'Trello':
            return (
                <div className="summary-topic"><i className="fab fa-trello"></i> {topic}</div>
            )
        case 'list':
            return (
                <div className="summary-topic"><i className=" fas fa-list"></i> {topic}</div>
            )
        case 'product':
            return (
                <div className="summary-topic"><i className="fas fa-tags" aria-hidden="true" style={{paddingRight: '0.5em'}}></i>{topic}</div>
            )  
        case 'attachment':
            return (
                <div className="summary-topic"><i className="far fa-file-alt" style={{paddingRight: '0.5em'}}></i>{topic}</div>
            )   
        case 'signature':
            return (
                <div className="summary-topic"><i className="fas fa-signature" style={{paddingRight: '0.5em'}}></i>{topic}</div>
            )   
        default:
            return (
                <div className="summary-topic">{topic + (item.answered? (" " + item.answered) : "")}</div>
            )
    }
}

const SummaryRow = ({item, index, summaryOpen, startScrolling}) => {

    const [topic, setTopic] = useState(getPlainTopic(item.topicContent));
    const [ref, inView, entry] = useInView({
        threshold: 0,
    })

    return (
        <motion.div
            initial={{opacity: 0, x: 0}}
            animate={inView ? "visible": "hidden"}
            variants={variants}
            exit={{opacity: 0, x: -100}}
            ref={ref}
        >
            {(item.visible && inView) &&
                <div className="summary-item" onClick={() => startScrolling(item.id)}>
                    <div className="summary-list-index" style={{ marginRight: '0.5em' }}>{index + 1}</div>
                    {checkType(item, topic)}
                    <div className="summary-list-index">{index + 1}</div>
                </div>
            }
        </motion.div>
        
    )
}

export default SummaryRow;
