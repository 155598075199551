import React from 'react'
import {
  Container
} from 'reactstrap';
import QibbieRobot from '../../modules/QibbieRobot';
import CodeInput from '../../modules/CodeInput';
import { Link } from 'react-router-dom';
import '../login.css';

export default function LoginCode(props) {
  const {locals} = props
  return (
    <span>
      <Container className="h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-8 text-center login-box">
            <div className="login-inner-container">
              <div className="login-box-content">
                <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                <h2 className="text-center">{locals.insertCode}</h2>
                <CodeInput handleCodeSubmit={props.handleCodeSubmit} locals={locals}/>
                <Link to='/login'><button className="small-white-button confirm-button" style={{ width:'100%', marginTop:'1.5em'}}>{locals.start}</button></Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
        
      <QibbieRobot top="75%" left="20%" />
    </span>
  )
}
