import React, { Component } from 'react'
import TextEditor from '../../modules/TextEditor/texteditor'
import {
    Row,
    Col
} from 'reactstrap'
import posed, { PoseGroup } from 'react-pose'
import { detect } from 'detect-browser'
const browser = detect();


export default class Feedback extends Component {
    constructor() {
        super()
        this.state = {
            surveyEnded: false,
            answered: false,
            surveyEndedText: 'Kiitos vastauksesta.'
        }
    }


    async answer(icon) {
        if (!browser) {
            browser = "not detectable"
        }
        const answerObj = {
            surveyId: this.props.feedbackObj._id,
            sentSurveyId: this.props.feedbackObj.sentSurveyId,
            answer: icon,
            groupId: '',
            questionId: '',
            type: 'feedback',
            answerSessionId: this.props.answerSessionId,
            url: window.location.pathname,
            browser: browser
        }

        await this.props.onSurveyAnswer(answerObj)
        let fbobj = JSON.parse(JSON.stringify(this.props.feedbackObj))
        let endText = { plaintextSurveyEndedText: fbobj.plaintextSurveyEndedText, surveyEndedText: fbobj.surveyEndedText }
        endText = await this.getFeedbackParams(endText, icon);
        this.setState({ surveyEndedText: endText.surveyEndedText })

        let endTextSize = endText.plaintextSurveyEndedText.length || 300
        let endTextTimeout =  endTextSize * 10 >= 8000 ? 8000 : endTextSize * 10;
        endTextTimeout = endTextTimeout <= 3000 ? 3000 : endTextTimeout;

        this.setState({ answered: true, surveyEnded: true }, () => {
            setTimeout(() => {
                if (!this.props.getNextSurvey()) {
                    this.setState({ surveyEnded: false, answered: false, surveyEndedText: this.props.locals.feedbackEnded });
                }
            }, endTextTimeout);
        })
    }

    getFeedbackParams = (endText, icon) => {
        return new Promise(async function (resolve) {
            if (endText.plaintextSurveyEndedText) {
                // regex that finds all words that begin with an @ -sign
                const regex = /@\w*/g
                const matches = endText.plaintextSurveyEndedText.match(regex)

                if (matches && matches.length > 0) {
                    const params = {
                        sentSurveyId: this.props.feedbackObj.sentSurveyId,
                        answerId: icon.id,
                        feedbackParams: matches
                    }
                    const feedbackParams = await this.props.getFeedbackParams(params);

                    if (feedbackParams) {
                        matches.map(matchType => {
                            // add different types of feedbackparameter types to this switch case, if needed
                            switch (matchType) {
                                case '@prosentti':
                                    endText.surveyEndedText.blocks.map((block, index) => {
                                        if (block.text.indexOf('@prosentti') !== -1) {
                                            block.text.replace('@prosentti', feedbackParams.sameAnswersPercentage)
                                            block.text = block.text.replace("@prosentti", Math.round(feedbackParams.sameAnswersPercentage) + '%')
                                            endText.surveyEndedText.blocks[index] = block
                                            return endText.surveyEndedText.blocks.splice(index, 1, block)
                                        }
                                        return null
                                    })
                                    break;
                                default:
                                    return null
                            }
                        })
                        resolve(endText)
                    }
                }
            }
            resolve(endText)
        }.bind(this))
    }

    render() {
        const surveyEndedText = this.state.surveyEndedText;
        const surveyEnded = this.state.surveyEnded;

        return (
            <Col className="survey-container" sm="12" md="12" lg="11" xl="12" style={{ position: 'relative' }}>
                <Row className=" h-100.mobile feedback-inner-container text-center icon-box" style={{ position: 'relative' }}>
                    <PoseGroup key="FeedbackGroup" animateOnMount={false}>
                        {surveyEnded ?
                            <FeedbackTextItem key="feedbacktext" className="col-md-12">
                                <Row className="feedback-row">
                                    <Col>
                                        <TextEditor content={surveyEndedText} editorId="ended" readOnly={true} onTextChange={() => { return }} additionalClass="instafb-editor" />
                                        {/* <button style={{ marginTop: '5em' }} onClick={this.props.endSurvey} className="survey-ended-button">Etusivulle</button> */}
                                        <small style={{ marginTop: '2em' }}>{this.props.locals.siteRefresh}</small>
                                    </Col>
                                </Row>
                            </FeedbackTextItem>
                            :
                            <FeedbackTextItem key="feedbackquestion" className="col-md-12">
                                <Row>
                                    <Col>
                                        <TextEditor content={this.props.feedbackObj.text} readOnly={true} editorId="start" onTextChange={() => { return }} additionalClass="instafb-editor" />
                                    </Col>
                                </Row>
                            </FeedbackTextItem>
                        }
                            <FeedbackItem key="feedbackanswers" afterChildren className="icon-answers col-md-12" pose={surveyEnded ? 'hide' : 'show'}>
                                <Row className="feedback-row" >
                                    {this.props.feedbackObj.iconList && this.props.feedbackObj.iconList.length > 0 && this.props.feedbackObj.iconList.map((icon, i) => {
                                        let color = icon.color ? icon.color : '#000';

                                        return (
                                            <IconCol key={i} className="col" style={{ marginTop: '1em', marginBottom: '1em', position: 'relative' }} i={i} pose={surveyEnded ? 'hide' : 'show'}>
                                                <i style={{ fontSize: this.props.feedbackObj.iconList.length > 9 ? '4em' : '6em', color: color, cursor: 'pointer' }} className={icon.name + " selectable-icon"} onClick={() => this.answer(icon)} ></i>
                                            </IconCol>
                                        )
                                    })}
                                </Row>
                            </FeedbackItem>
                    </PoseGroup>
                </Row>
            </Col>
        )
    }
}

const IconCol = posed.div({
    hoverable: true,
    init: {
        scale: 1,
    },
    hover: {
        scale: 1.2,
    },
    show: {
        y: 0,
        opacity: 1,
        position: 'relative',
        transition: ({ i }) => ({ delay: i * 50, ease: 'backOut' })
    },
    hide: {
        opacity: 0,
        position: 'relative',
        y: '200px',
        transition: ({ i }) => ({ delay: i * 50, ease: 'anticipate' })
    }

})

const FeedbackItem = posed.div({
    init: {
        height: 'auto'
    },
    show: {
        y: 0,
        beforeChildren: true,
        height: 'auto',
        transition: {
            ease: 'linear'
        }
    },
    hide: {
        height: 0,
        afterChildren: true
    }
})

const FeedbackTextItem = posed.div({
    enter: {
        x: 0,
        overflow: 'show',
        transition: {
            ease: 'linear'
        }
    },
    exit: {
        x: '-100%',
        overflow: 'auto',
        transition: {
            ease: 'linear',
        }
    }
})