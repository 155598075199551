import React from 'react'
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import TextEditor from '../../modules/TextEditor/texteditor'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fi';

import 'react-dates/lib/css/_datepicker.css';
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip';

import { confs } from '../../../bootstrap/config';

import { detect } from 'detect-browser'
import { NotificationManager } from 'react-notifications';
import posed, { PoseGroup } from 'react-pose'
import { EditorState, convertFromRaw } from 'draft-js'
import { checkYTunnus } from '../../modules/yTunnusHelper'
import { Attachment } from './subComponents/Attachment';
import locals from '../../../locals/locals';

let browser = detect();

const Handle = Slider.Handle;

class Dialog extends React.Component {
  constructor() {
    super()

    this.state = {
      dialog: null,
      scrollBarsHeight: "",
      groupIndex: 0,
      questionIndex: 0,
      dialogHistory: [],
      aiWriting: false,
      currentAnswers: [],
      surveyId: null,
      sentSurveyId: null,
      dialogEnded: false,
      askedSurveys: [],
      categoryCount: 0,
      windowHeight: null,
      infoMaximized: false,
      freetextAnswer: "",
      answerSessionId: null,
      date: null,
      showDatePicker: false,
      finalFeedbackAnswer: '',
      finalFeedbackHasBeenAsked: false,
      askFinalFeedbackQuestion: false,
      infoDone: false,
      dialogTextModal: false,
      hideAnswers: true,
      inLinkedSurvey: null,
      NPSval: undefined,
      npsNumber: 0,
      npsOld: 0,
      numberChanged: false,
      oldNpsColor: null,
      newNpsColor: null,
      businessId: '',
      selectedFiles: []
    }

    this.dialogContainerRef = React.createRef()
    this.answersContainerRef = React.createRef()
  }

  componentWillMount() {
    if (this.props.dialogObj) {
      if (this.props.previousAnswerSession) {
        const prevSession = this.props.previousAnswerSession

        this.setState({
          dialog: this.props.dialogObj,
          sentSurvey: this.props.sentSurvey,
          groupIndex: prevSession.groupIndex,
          questionIndex: prevSession.questionIndex,
          answerSessionId: prevSession.answerSessionId,
          currentAnswers: [],
          dialogEnded: false,
          inLinkedSurvey: prevSession.inLinkedSurvey,
          sentSurveyId: prevSession.sentSurveyId
        }, async function () {
          await this.welcomeBack()

          if (typeof prevSession.startFromFirstQuestion === 'undefined') {
            await this.setNextQuestion()
          }

          this.startDialog()
        })
      } else {
        this.setState({
          dialog: this.props.dialogObj,
          sentSurvey: this.props.sentSurvey,
          groupIndex: 0,
          questionIndex: 0,
          currentAnswers: [],
          dialogEnded: false,
          sentSurveyId: this.props.sentSurvey._id,
          answerSessionId: this.props.answerSessionId
        }, () => this.startDialog())
      }
    }
  }

  componentDidUpdate(prevState) {
    if (this.props.dialogObj !== this.state.dialog) {
      this.setState({
        dialog: this.props.dialogObj,
        groupIndex: 0,
        questionIndex: 0,
        currentAnswers: [],
        dialogEnded: false
      }, () => this.startDialog())
    }
    if (this.state.numberChanged) {
      this.setState({ numberChanged: false })
    }
    if (this.state.reloadSlider) {
      this.setState({ reloadSlider: false })
    }

    if(this.props.sentSurvey && this.props.sentSurvey._id !== this.state.sentSurvey._id){
      this.setState({ sentSurvey: this.props.sentSurvey})
      this.setState({sentSurveyId: this.props.sentSurvey._id})
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.calculateScrollbarsHeight)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateScrollbarsHeight)
  }

  async welcomeBack() {
    const { dialog } = this.state
    return new Promise(async function (resolve) {
      this.calculateScrollbarsHeight()
      const dialogObj = {
        text: this.props.locals.welcomeBack,
        type: 'question',
        dialogImage: dialog.dialogImage
      }
      await this.conversate(dialogObj)
      resolve()
    }.bind(this))
  }

  async startDialog() {
    // await this.loadDialog(code)
    if (this.state.dialog) {
      this.calculateScrollbarsHeight()
      // await this.describeDialog()
      if (!this.props.previousAnswerSession) {
        await this.describeGroup()
      }

      if (!this.props.previousAnswerSession || !this.state.dialogEnded) {
        this.askQuestion()
      }
    } else {
      this.setState({ dialogNotFound: true })
    }
  }

  // prints out dialog description
  async describeDialog() {
    const { dialog } = this.state

    return new Promise(async function (resolve) {
      // const dialogDescription = dialog.plaintextCategoryExplanation ? dialog.plaintextCategoryExplanation : null
      const dialogDescription = dialog.text ? dialog.text : null
      const trimmedDescription = dialog.plaintextCategoryExplanation.trim()

      if (dialogDescription && trimmedDescription !== '') {
        await this.conversate({ type: 'question', text: dialogDescription, categoryTitle: true, dialogImage: dialog.dialogImage })
      }
      resolve()
    }.bind(this))
  }

  // prints out question group description
  async describeGroup() {
    const { dialog, groupIndex } = this.state

    return new Promise(async function (resolve) {
      const currentGroup = dialog.questionGroups[groupIndex]
      // const groupDescription = currentGroup.plaintextGroupDescription ? currentGroup.plaintextGroupDescription : null
      const groupDescription = currentGroup.text ? currentGroup.text : null
      const trimmedDescription = currentGroup.plaintextGroupDescription ? currentGroup.plaintextGroupDescription.trim() : ''

      if (groupDescription && trimmedDescription !== '') {
        await this.conversate({ type: 'group', text: groupDescription, categoryTitle: true, dialogImage: dialog.dialogImage })
      }
      resolve()
    }.bind(this))
  }

  /**
   * Pushes the given conversation object to the dialog history array
   */
  conversate = (conversationObj) => {
    const { text, type, dialogImage, color, url } = conversationObj
    const categoryTitle = conversationObj.categoryTitle || false
    // console.log(conversationObj);
    return new Promise(function (resolve) {
      if (type === 'question' || type === 'group' || type === 'info' || type === 'comment' || type === 'plan') {
        const textLength = text.length
        let writeTime;

        if (type === 'group') {
          let numbers = 0;
          text['blocks'].forEach(item => {
            if (!isNaN(item.text.length)) {
              numbers = numbers + item.text.length;
            }
          })

          // nää ajotukset on hieman nyt väärinpäin. eli tavoitteena olis, että seuraava teksti heti kysymysryhmän jälkeen tulee hitaammin. tässä tapauksessa kysymysryhmän teksti tuli hitaammin.
          // writeTime = numbers * 250 > 7000 ? 7000 : numbers * 250
          // writeTime = (writeTime < 4000 || isNaN(writeTime)) ? 4000 : writeTime
          writeTime = textLength * 10 > 7000 ? 7000 : textLength * 10
          writeTime = (writeTime < 2000 || isNaN(writeTime)) ? 2000 : writeTime
        } else {
          writeTime = textLength * 10 > 7000 ? 7000 : textLength * 10
          writeTime = (writeTime < 2000 || isNaN(writeTime)) ? 2000 : writeTime
        }

        this.setState({
          aiWriting: true
        }, () => {
          setTimeout(() => {
            this.setState(prevState => ({
              dialogHistory: [
                ...prevState.dialogHistory,
                { type: type, text: text, categoryTitle: categoryTitle, dialogImage: dialogImage, url: url }
              ],
              aiWriting: false
            }))
            this.handleScrollUpdate()
            resolve()
          }, writeTime)
        })

      } else {
        this.setState(prevState => ({
          dialogHistory: [
            ...prevState.dialogHistory,
            { type: type, text: text, color: color, dialogImage: dialogImage }
          ],
          currentAnswers: [],
          freetextAnswer: ''
        }), async function () {
          this.handleScrollUpdate()
          resolve()
        })
      }
    }.bind(this))
  }

  async askQuestion() {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]
    
    if (currentQuestion.isComment) {
      
      await this.conversate({ type: 'comment', text: currentQuestion.comment || "", dialogImage: dialog.dialogImage })
    } else {
      
      await this.conversate({ type: 'question', text: currentQuestion.text || "", dialogImage: dialog.dialogImage })
    }

    if ((typeof currentQuestion.questionOptions === 'undefined' || currentQuestion.questionOptions.length === 0) && dialog.questionGroups) {
      
      await this.setNextQuestion()
      this.askQuestion()
    } else {
      this.setState({
        currentAnswers: currentQuestion.questionOptions,
        hideAnswers: false
      }, () => this.calculateScrollbarsHeight())
    }
  }

  async giveAnswer(e) {
    e.persist()
    let answerObj = {}
    const { dialog, groupIndex, questionIndex, answerSessionId, sentSurveyId } = this.state
    const currentDialog = dialog
    const currentGroup = currentDialog.questionGroups[groupIndex]
    const currentQuestion = currentGroup.questions[questionIndex]
    const isFreetextQuestion = currentQuestion.isFreetextQuestion
    const isNpsQuestion = currentQuestion.isNPS
    const isVariableQuestion = currentQuestion.isVariableQuestion
    const isDateQuestion = currentQuestion.isDateQuestion
    const isComment = currentQuestion.isComment
    const isBusinessId = currentQuestion.isBusinessId
    const isAttachmentQuestion = currentQuestion.isAttachmentQuestion
    const isFinalFeedbackQuestion = (e.target && e.target.getAttribute("data-is-finalfeedback")) ? e.target.getAttribute("data-is-finalfeedback") : false
    let targetSurvey = null;
    if (!browser) {
      browser = "not detectable"
    }

    this.setState({ hideAnswers: true }, () => {
      setTimeout(async () => {
        // finalfeedbackit pakko handlata täysin eri tavalla kuin aiemmat, eli omaan iffiin ja loput rojut elseen

        if (isFinalFeedbackQuestion) {
          this.setState({ askFinalFeedbackQuestion: false, finalFeedbackHasBeenAsked: true, aiWriting: true })
          // tallennetaan annettu sähköposti ja lähetetään siihen finalfeedbacki
          let text = this.state.finalFeedbackAnswer

          const { finalFeedback } = this.state.dialog
          const entities = this.getEntitiesFromText(finalFeedback)

          answerObj = {
            surveyId: currentDialog._id,
            sentSurveyId: sentSurveyId,
            groupId: '',
            questionId: '',
            answerSessionId: answerSessionId,
            answer: {
              isFinalFeedbackQuestion: true,
              text: text,
              dialogImage: currentDialog.dialogImage,
              entities: entities
            },
            browser: browser
          }

          await this.props.onSurveyAnswer(answerObj)

          const answer = { type: 'answer', text: text }
          await this.conversate(answer)

        } else if (isComment) {

        } else if(isAttachmentQuestion){
          answerObj = {
            surveyId: currentDialog._id,
            sentSurveyId: sentSurveyId,
            groupId: currentGroup.id,
            questionId: currentQuestion.id,
            answerSessionId: answerSessionId,
            answer: {
              isAttachmentQuestion: true,
              files:this.state.selectedFiles
            },
            browser: browser
          }
          let text = this.props.locals.onFileSubmit
          await this.props.onSurveyAnswer(answerObj)

          const answer = { type: 'question', text: text }
          await this.conversate(answer)
          this.handleScrollUpdate()
        } else {
          let text = isFreetextQuestion ? this.state.freetextAnswer : e.target.getAttribute("data-text")

          if (isDateQuestion) {
            text = this.state.date ? this.state.date._d : "Ei valittua päivämäärää"
          }

          const answerIndex = e.target.getAttribute("data-index")

          // store an answer session stack, so it's possible for the user to continue the survey they have left unfinished
          const answerSession = {
            groupIndex: groupIndex,
            questionIndex: questionIndex,
            answerSessionId: answerSessionId,
            inLinkedSurvey: this.state.inLinkedSurvey,
            sentSurveyId: sentSurveyId
          }

          // maybe do something if storing fails? :))
          await this.props.storeAnswerSession(answerSession)

          if (isFreetextQuestion) {
            answerObj = {
              surveyId: currentDialog._id,
              sentSurveyId: sentSurveyId,
              groupId: currentGroup.id,
              questionId: currentQuestion.id,
              answerSessionId: answerSessionId,
              answer: {
                isFreetextQuestion: true,
                text: text? text : "",
                dialogImage: currentDialog.dialogImage
              },
              browser: browser
            }
          } else if (isDateQuestion) {
            answerObj = {
              surveyId: currentDialog._id,
              sentSurveyId: sentSurveyId,
              groupId: currentGroup.id,
              questionId: currentQuestion.id,
              answerSessionId: answerSessionId,
              answer: {
                isDateQuestion: true,
                text: text,
                dialogImage: currentDialog.dialogImage
              },
              browser: browser
            }
          }
          else if (isNpsQuestion) {
            const npsval = this.state.npsNumber > 0 ? this.state.npsNumber : 1;
            answerObj = {
              surveyId: currentDialog._id,
              sentSurveyId: sentSurveyId,
              groupId: currentGroup.id,
              questionId: currentQuestion.id,
              answerSessionId: answerSessionId,
              answer: {
                isNpsQuestion: true,
                value: npsval,
              },
              browser: browser
            }
          } else if (isBusinessId) {
            const busID = this.state.businessId
            text = busID;
            let businessId = busID.trim()
            answerObj = {
              surveyId: currentDialog._id,
              sentSurveyId: sentSurveyId,
              groupId: currentGroup.id,
              questionId: currentQuestion.id,
              answerSessionId: answerSessionId,
              answer: {
                isBusinessId: true,
                value: businessId,
              },
              browser: browser
            }
          } else {
            if (currentQuestion.questionOptions[answerIndex].targetSurvey !== undefined) {
              targetSurvey = currentQuestion.questionOptions[answerIndex].targetSurvey;

              const answerSessionz = {
                groupIndex: 0,
                questionIndex: 0,
                answerSessionId: answerSessionId,
                inLinkedSurvey: targetSurvey,
                startFromFirstQuestion: true, // not proud of this but fuuuuu...
                sentSurveyId: sentSurveyId
              }

              // maybe do something if storing fails? :))
              await this.props.storeAnswerSession(answerSessionz)
            }

            answerObj = {
              surveyId: currentDialog._id,
              sentSurveyId: sentSurveyId,
              groupId: currentGroup.id,
              questionId: currentQuestion.id,
              answer: currentQuestion.questionOptions[answerIndex],
              answerSessionId: answerSessionId,
              isVariableQuestion: isVariableQuestion,
              browser: browser
            }
          }

          await this.props.onSurveyAnswer(answerObj)

          if (isDateQuestion) {
            this.setState({ showDatePicker: false, date: null });
            if (text !== this.props.locals.noDateChosen) {
              text = JSON.stringify(moment(text).local('fi').format('L'));
            }
          }
          let answer = {}
          if (isNpsQuestion) {
            answer = { type: 'nps', text: this.npsIcon(this.state.npsNumber), color: this.state.newNpsColor, answerIndex: answerIndex, dialogImage: currentDialog.dialogImage }

            this.setState({
              NPSval: undefined,
              npsNumber: 0,
              npsOld: 0,
              numberChanged: false,
              oldNpsColor: null,
              newNpsColor: null,
              reloadSlider: true
            })
          } else {
            answer = { type: 'answer', text: text, answerIndex: answerIndex, dialogImage: currentDialog.dialogImage }
          }


          await this.conversate(answer)

          this.setState({ infoDone: false });

          if (answerIndex) {
            await this.giveFeedback(answerIndex)
            this.handleScrollUpdate()
          }
        }

        if (targetSurvey !== null) {
          if (targetSurvey.value === "endSurvey") {
            await this.endDialog(true)
            NotificationManager.success(this.props.locals.becauseOfAnswerDialogDone, "", 2000)
          } else {
            await this.props.onTargetSurveyChange({ targetSurvey: targetSurvey, sessionId: answerSessionId, sentSurveyId: this.state.sentSurveyId })
            await this.setNextDialog()
            this.setState({ inLinkedSurvey: targetSurvey })
            NotificationManager.success(this.props.locals.becauseOfAnswerDialogChange, "", 2000)
          }

        } else {
          const askNextQuestion = await this.setNextQuestion()
          if (askNextQuestion) { this.askQuestion() }
        }
      }, 100)
    })
  }

  async giveFeedback(answerIndex) {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]
    let givenAnswer = currentQuestion.questionOptions[answerIndex]

    return new Promise(async function (resolve) {
      if (typeof givenAnswer.dialogFeedback !== 'undefined' && typeof givenAnswer.dialogFeedback.blocks !== 'undefined' && (givenAnswer.dialogFeedback.blocks[0].text.trim() !== '' || givenAnswer.dialogFeedback.blocks.length > 1)) {

        givenAnswer = await this.getFeedbackParams(givenAnswer)

        await this.conversate({ type: 'question', text: givenAnswer.dialogFeedback, dialogImage: dialog.dialogImage })
        resolve()
      } else {
        resolve()
      }
    }.bind(this))
  }

  getFeedbackParams = (givenAnswer) => {
    const { dialog, questionIndex, groupIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]

    return new Promise(async function (resolve) {
      if (givenAnswer.dialogPlaintextFeedback) {
        // regex that finds all words that begin with an @ -sign
        const regex = /@\w*/g
        const matches = givenAnswer.dialogPlaintextFeedback.match(regex)

        if (matches && matches.length > 0) {
          const params = {
            sentSurveyId: this.state.sentSurveyId,
            questionId: currentQuestion.id,
            answerId: givenAnswer.id,
            feedbackParams: matches
          }

          const feedbackParams = await this.props.getFeedbackParams(params)

          if (feedbackParams) {
            matches.map(matchType => {
              // add different types of feedbackparameter types to this switch case, if needed
              switch (matchType) {
                case '@prosentti':
                  givenAnswer.dialogFeedback.blocks.map((block, index) => {
                    if (block.text.indexOf('@prosentti') !== -1) {
                      block.text.replace('@prosentti', feedbackParams.sameAnswersPercentage)
                      block.text = block.text.replace("@prosentti", Math.round(feedbackParams.sameAnswersPercentage) + '%')
                      givenAnswer.dialogFeedback.blocks[index] = block

                      return givenAnswer.dialogFeedback.blocks.splice(index, 1, block)
                    }
                    return null
                  })
                  break;
                default:
                  return null
              }
            })

            resolve(givenAnswer)
          }
        }
      }

      resolve(givenAnswer)
    }.bind(this))
  }

  setNextQuestion = () => {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentGroup = dialog.questionGroups[groupIndex]

    return new Promise(async function (resolve) {
      if (typeof currentGroup.questions !== 'undefined' && currentGroup.questions[questionIndex + 1]) {
        // move to next question in group
        this.setState(prevState => ({
          questionIndex: prevState.questionIndex + 1
        }), () => resolve(true))
      } else {
        if (dialog.questionGroups[groupIndex + 1]) {
          // move to next group in dialog
          await this.setNextGroup()
          resolve(true)
        } else {
          // if survey has final feedback toggled on
          if (typeof dialog.askFinalFeedbackEmail !== 'undefined' && dialog.askFinalFeedbackEmail && !this.state.finalFeedbackHasBeenAsked) {
            await this.askFinalFeedbackEmail()
            resolve(false)
          } else {
            if (this.props.checkNextSurveyType()) {
              // move to next dialog
              await this.endDialog(true)
              resolve(false)
            } else {
              // end the dialog
              this.endDialog()
            }
          }
        }
      }
    }.bind(this))
  }

  setNextGroup = () => {
    const { dialog } = this.state

    return new Promise(async function (resolve) {
      this.setState(prevState => ({
        questionIndex: 0,
        groupIndex: prevState.groupIndex + 1
      }), async function () {
        if (typeof dialog.questionGroups[this.state.groupIndex].questions !== 'undefined') {
          await this.describeGroup()
          resolve()
        } else {
          this.setNextQuestion()
          resolve()
        }
      })
    }.bind(this))
  }

  talkInfo = async () => {
    const { dialog } = this.state
    await this.conversate({ type: 'info', text: this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].information, dialogImage: dialog.dialogImage });
    this.setState({ infoDone: true });
  }

  async setNextDialog() {
    const hasNextSurvey = await this.props.getNextSurvey()

    return new Promise(async (resolve) => {
      if (hasNextSurvey) {
        this.setState(prevState => ({
          questionIndex: 0,
          groupIndex: 0,
          // dialogIndex: prevState.dialogIndex + 1
        }), async () => {
          await this.describeDialog()
          await this.describeGroup()
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  // prints out dialog ending text and ends the dialog
  async endDialog(toNextSurvey = false) {
    const { dialog, sentSurvey } = this.state
    return new Promise(async function (resolve) {
      if (!toNextSurvey) {
        this.props.clearAnswerSession()
      }

      let dialogEndText = typeof dialog.surveyEndedText !== 'undefined' ? dialog.surveyEndedText : 'Kiitos vastauksistasi!'

      // this is where we find all question parameters from the end text and replace them with answer values
      if (typeof dialogEndText === 'object') {
        const entities = this.getEntitiesFromText(dialogEndText)

        // send the list to server and get the answer values in response
        const params = {
          sentSurveyId: this.state.sentSurveyId,
          answerSessionId: this.state.answerSessionId,
          entities: entities,
          survey: dialog,
          surveyEndedText: dialogEndText
        }

        const parameterValues = await this.props.getEndTextParams(params)

        if (parameterValues && parameterValues.text) {
          dialogEndText = parameterValues.text
        }
      }


      // write out the end text and do the doings
      await this.conversate({ type: 'question', text: dialogEndText, dialogImage: dialog.dialogImage })

      // handle automatic plan stuff here
      if (!toNextSurvey) {
        if (sentSurvey.automationSettings) {
          let params = {
            sentSurveyId: this.state.sentSurveyId,
            answerSessionId: this.state.answerSessionId
          }
          if (!sentSurvey.automationSettings.showOnAnswer && !sentSurvey.automationSettings.sendOnAnswer) {
            this.props.handleAutomation(params)
          }
          if (sentSurvey.automationSettings.showOnAnswer || sentSurvey.automationSettings.sendOnAnswer) {
            let generated = await this.props.handleAutomation(params)

            if (generated.published) {
              let planUrl = confs.frontPage + 'plan/' + generated.publishedId
              let text = sentSurvey.automationSettings.linkText || 'Voit katsoa automaattisesti luodun lomakkeen tästä'

              await this.conversate({ type: 'plan', url: planUrl, text: text, dialogImage: dialog.dialogImage })
            }
          }

        }
      }

      if (!toNextSurvey) {
        await this.conversate({ type: 'question', text: "Voit nyt sulkea selaimen tai siirtyä etusivulle alla olevasta painikkeesta.", dialogImage: dialog.dialogImage })
      }
      this.setState({ dialogEnded: true, toNextSurvey: toNextSurvey, hideAnswers: false }, () => {
        this.calculateScrollbarsHeight()
        resolve()
      })
    }.bind(this))
  }

  async askFinalFeedbackEmail() {
    const { dialog } = this.state
    return new Promise(async function (resolve) {
      await this.conversate({ type: 'question', text: 'Anna vielä sähköpostiosoitteesi, jotta voin lähettää sinulle henkilökohtaisen loppupalautteen keskustelustamme.', dialogImage: dialog.dialogImage })

      this.setState({ askFinalFeedbackQuestion: true, hideAnswers: false }, () => {
        this.calculateScrollbarsHeight()
        resolve()
      })
    }.bind(this))
  }

  calculateScrollbarsHeight = () => {
    if (this.dialogContainerRef && this.answersContainerRef) {
      const containerHeight = this.dialogContainerRef.current.clientHeight;
      const answersHeight = this.answersContainerRef.current.clientHeight;
      const minHeight = containerHeight / 3;

      // set scrollbars height to containerHeight - answersContainerHeight - another 10% of containerHeight (to make the fit a little bit better)
      let height = containerHeight - answersHeight - (containerHeight * 0.10);
      height = height < minHeight ? minHeight : height;

      this.setState({ scrollBarsHeight: height, windowHeight: window.innerHeight }, () => {
        this.handleScrollUpdate()
      });
    }
  }

  handleScrollUpdate() {
    this.scrollEnd.parentElement.scroll({ top: this.scrollEnd.offsetTop, left: 0, behavior: 'smooth' })
  }

  toggleInfo = () => {
    this.setState(prevState => ({
      infoMaximized: !prevState.infoMaximized
    }))
  }

  isDayBlocked = (day) => {
    if (this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].allowWeekends !== 'undefined' && !this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].allowWeekends) {
      if (day.day() === 6 || day.day() === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleDialogTextModal = () => {
    this.setState(state => ({
      dialogTextModal: !state.dialogTextModal
    }))
  }

  getEntitiesFromText = text => {
    const editorState = EditorState.createWithContent(convertFromRaw(text))
    const contentState = editorState.getCurrentContent()
    const blocks = contentState.getBlocksAsArray()

    const entities = []

    // find all parameters tagged with @kysymys from the text and collect them to a list
    blocks.forEach((block) => {
      if (block.getText().indexOf('@kysymys') !== -1) {
        block.findEntityRanges(
          (char) => {
            if (char.getEntity() !== null) {
              const entity = contentState.getEntity(char.getEntity())
              let { mention } = entity.getData()

              entities.push(mention)
            }
          }
        )
      }
      return null
    })

    return entities
  }

  tipFormatter = (val) => {
    if (!isNaN(val)) {
      let oldval = this.state.npsNumber;
      let newval = Math.round(val / 10);
      if (newval === 0) {
        newval = 1;
      }
      if (oldval === 0) {
        oldval = newval;
      }
      if (this.state.npsNumber !== newval) {
        this.setState({ npsNumber: newval, numberChanged: true, npsOld: oldval });
      }
      return `${newval}`;
    }
  }

  npsFormatter = (val) => {
    if (!isNaN(val)) {
      let oldval = this.state.npsNumber;
      let newval = Math.round(val / 10);
      if (newval === 0) {
        newval = 1;
      }
      if (oldval === 0) {
        oldval = newval;
      }

      if (this.state.npsNumber !== newval) {
        this.setState({ npsNumber: newval, npsOld: oldval });  //numberchanged here
      }
      this.setState({ oldNpsColor: this.state.newNpsColor })
      if (oldval < 7 && newval >= 7) {
        this.setState({ numberChanged: true })
      }
      else if ((oldval >= 7 && oldval < 8) && (newval < 7 || newval >= 8)) {
        this.setState({ numberChanged: true })
      }
      else if (oldval >= 8 && newval < 8) {
        this.setState({ numberChanged: true })
      }

      if (newval < 7) {
        this.setState({ newNpsColor: '#FF0000' })
      }
      else if (newval >= 7 && newval < 9) {
        this.setState({ newNpsColor: '#fc0' })
      }
      else if (newval >= 9) {
        this.setState({ newNpsColor: '#b5e413' })
      }
    }
  }

  colorLuminance = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  npsIcon = (val) => {
    if (val <= 6) {
      return 'far fa-frown'
    }
    if (val >= 7 && val <= 8) {
      return 'far fa-meh'
    }
    if (val >= 9) {
      return 'far fa-smile'
    }
  }

  handleFile = (files, e) => {
    this.setState({selectedFiles:files})
    this.giveAnswer(e)
  }  

  render() {
    const currentDialog = this.state.dialog || null
    const currentGroup = currentDialog ? currentDialog.questionGroups[this.state.groupIndex] : null
    const currentQuestion = (currentGroup && typeof currentGroup.questions !== 'undefined') ? currentGroup.questions[this.state.questionIndex] : null
    const groupColor = (currentGroup && typeof currentGroup.groupColor !== 'undefined' && (typeof currentGroup.colorInUse !== 'undefined' && currentGroup.colorInUse === true)) ? currentGroup.groupColor : '#FFCC00'
    const btnColor = this.colorLuminance(groupColor, 30)

    const { showDatePicker } = this.state;


    return (
      <div className="dialog-container col-12 col-xl-7 col-lg-8 col-md-12 col-sm-12" ref={this.dialogContainerRef}>
        <Modal isOpen={this.state.dialogTextModal} toggle={this.toggleDialogTextModal} size="xl">
          <ModalHeader toggle={this.toggleDialogTextModal}>
            <span>{this.state.dialog.title}</span>
          </ModalHeader>
          <ModalBody>
            <TextEditor content={this.state.dialog.text} readOnly={true} onTextChange={() => { return }} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleDialogTextModal}>Sulje</Button>
          </ModalFooter>
        </Modal>

        <Row className="dialog-info-box" onClick={this.toggleDialogTextModal} style={{ cursor: 'pointer' }}>
          {/* colored ribbon in the top */}
          <div className="top-ribbon d-none-768" style={{ borderColor: groupColor }}></div>

          <Col xs={{ size: 10 }} sm={{ size: 11 }} className="dialog-info-box-general">
            <h5 className="dialog-title" style={{ marginBottom: '0' }} title={this.state.dialog.title}>{this.state.dialog.title}</h5>
          </Col>
          <Col xs={{ size: 2 }} sm={{ size: 1 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="infoblock" style={{ color: groupColor }}>
              <i className="fas fa-caret-right"></i>
            </div>
          </Col>
        </Row>

        {this.state.dialog !== null &&
          <>
            <Row>
              <div className="top-ribbon" style={{ borderColor: groupColor }}></div>
              {/* <h3 className="dialog-title">{this.state.dialog.title}</h3> */}
              <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: this.state.scrollBarsHeight, marginBottom: "1em" }}
                className="scrollbars-container"
                ref={(scrollBars) => this.scrollBars = scrollBars}
              >
                <Row style={{ marginRight: 0 }}>
                  <Col md={{ size: 12 }} className="dialog-inner-container">
                    <PoseGroup>
                      {this.state.dialogHistory.map((dialog, index) => {
                        let style = {}
                        //Dialog image comes from history objects
                        if (dialog.dialogImage) {
                          style = { backgroundImage: `url(${confs.host}/api/getDialogImage/${dialog.dialogImage})`, backgroundSize: 'cover' }
                        }

                        switch (dialog.type) {
                          case 'group':
                          case 'question':
                          case 'info':
                          case 'comment':
                            return (
                              <Question className="row" key={index}>
                                <Col style={{ display: 'flex' }}>
                                  <div className="picture-circle question-picture" style={style} />
                                  <div className={"dialog-line dialog-question " + (dialog.categoryTitle || dialog.type === 'info' || dialog.type === 'comment' ? "category-title" : "")}>
                                    <TextEditor content={dialog.text} readOnly={true} onTextChange={() => { return }} additionalClass="dialog-freetext-question" />
                                    {this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].information && dialog.type === 'question' && index + 1 === this.state.dialogHistory.length && !this.state.infoDone && !this.state.aiWriting &&
                                      <i className="fas fa-info-circle dialogInfoBlock" onClick={this.talkInfo}></i>
                                    }
                                  </div>
                                </Col>
                              </Question>
                            )
                          case 'plan':
                            return (
                              <Question className="row" key={index}>
                                <Col style={{ display: 'flex' }}>
                                  <div className="picture-circle question-picture" style={style} />
                                  <div className="dialog-line dialog-question ">
                                    <a href={dialog.url} target="_blank" rel="noopener noreferrer">{dialog.text}</a>
                                  </div>
                                </Col>
                              </Question>
                            )

                          case 'answer':
                            return (
                              <Answer className="row" key={index} style={{ paddingLeft: '2rem' }}>
                                <Col style={{ display: 'flex' }}>
                                  <div className="dialog-line dialog-answer">
                                    {dialog.type === 'nps' ?
                                      <i style={{ color: dialog.color, fontSize: '3em' }} className={dialog.text}></i>
                                      :
                                      <TextEditor content={dialog.text} readOnly={true} additionalClass="" onTextChange={() => { return }} textAlignment='left' groupColor={this.state.groupColor}/>
                                      // <p>{dialog.text}</p>
                                    }
                                  </div>
                                  {/* <div className="picture-circle answer-picture" /> */}
                                </Col>
                              </Answer>
                            )
                          case 'nps':
                            return (
                              <Answer className="row" key={index} style={{ paddingLeft: '2rem' }}>
                                <Col style={{ display: 'flex' }}>
                                  <div className="dialog-line dialog-answer">
                                    {dialog.type === 'nps' ?
                                      <i style={{ color: dialog.color, fontSize: '3em' }} className={dialog.text}></i>
                                      :
                                      <p>{dialog.text}</p>
                                    }
                                  </div>
                                  {/* <div className="picture-circle answer-picture" /> */}
                                </Col>
                              </Answer>
                            )
                          default:
                            return false;
                        }

                      })}
                    </PoseGroup>
                  </Col>
                </Row>
                <div ref={(el) => { this.scrollEnd = el; }}></div>
              </Scrollbars>
              {this.state.aiWriting && <p style={{ marginLeft: '1rem' }}>{this.props.locals.dialogBotText}<span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></p>}
            </Row>

            <AnswersContainer pose={this.state.hideAnswers ? 'closed' : 'open'} className="row dialog-outer-answers-container" ref={this.answersContainerRef} style={showDatePicker ? { maxHeight: '100%' } : {}}>
              <Col>
                <Row className="dialog-answers-container">
                  {!this.state.dialogEnded ? (
                    this.state.askFinalFeedbackQuestion ? (
                      <Col md={{ size: 12 }}>
                        <textarea className="freetext-answer" placeholder={this.props.locals.emailAnswerPlaceHolder} value={this.state.finalFeedbackAnswer} onChange={(e) => this.setState({ finalFeedbackAnswer: e.target.value })}></textarea>
                        <button data-is-finalfeedback={true} disabled={this.state.hideAnswers} className="dialog-answer-button" style={{ padding: "1rem", margin: "0", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{this.props.locals.answerButton}</button>
                      </Col>
                    ) : (
                        <Col md={{ size: 12 }} style={currentQuestion.isFreetextQuestion? {marginTop: '1.7em'} : {marginTop: '0'}}>
                          {currentQuestion.isFreetextQuestion &&
                            !this.state.aiWriting &&
                            <span style={{ marginTop:'2em'}}>
                               <TextEditor content={this.state.freetextAnswer} readOnly={false} additionalClass="freetext-answer freetext-dialog" onTextChange={editorState => this.setState({freetextAnswer:editorState})} placeholder={currentQuestion.placeholder ? currentQuestion.placeholder :locals.freetextPlaceholder} textAlignment='left' allowFileUpload={true} documentId={this.state.sentSurveyId} sender={this.props.dialogObj.enterpriseId} groupColor={this.state.groupColor}/>
                              {/* <textarea className="freetext-answer" placeholder={currentQuestion.placeholder ? currentQuestion.placeholder : this.props.locals.answerPlaceHolder} value={this.state.freetextAnswer} onChange={(e) => this.setState({ freetextAnswer: e.target.value })}></textarea> */}
                              <button className="dialog-answer-button" disabled={this.state.hideAnswers} style={{ padding: "1rem", margin: "0", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{this.props.locals.answerButton}</button>
                            </span>
                          }

                          {currentQuestion.isNPS &&
                            <>
                              <div className="NPS-container NPS-dialog">
                                <div className="amazing-number">
                                  {this.state.npsNumber > 0 &&
                                    <>
                                      <NPS style={{ opacity: 0 }} pose={this.state.numberChanged ? 'animateClose' : ''} x={this.state.npsNumber > this.state.npsOld ? -45 : 45} ><i style={{ color: this.state.oldNpsColor }} className={this.npsIcon(this.state.npsOld)}></i></NPS>
                                      <NPS pose={this.state.numberChanged ? 'animate' : ''} x={this.state.npsNumber > this.state.npsOld ? 45 : -45} > <i style={{ color: this.state.newNpsColor }} className={this.npsIcon(this.state.npsNumber)}></i> </NPS>
                                    </>
                                  }
                                  <NPStext className="slider-text" pose={this.state.npsNumber > 0 ? 'exit' : 'show'} key="NPStext">{this.props.locals.slideBar}</NPStext>
                                </div>
                                {!this.state.reloadSlider &&
                                  <Slider
                                    min={10} max={100}
                                    steps={1}
                                    className="nps-slider"
                                    defaultValue={this.state.NPSval}
                                    onChange={val => this.npsFormatter(val)}
                                    onAfterChange={(val) => this.setState({ NPSval: val })}
                                    railStyle={{ backgroundColor: '#dedede', height: '1em' }}
                                    trackStyle={{ backgroundColor: groupColor || '#b5e413', height: '1em' }}
                                    handleStyle={{ backgroundColor: groupColor || '#b5e413', borderColor: groupColor || '#b5e413', height: '2.2em', width: '2.2em', marginTop: '-0.6em', boxShadow: `${groupColor} 0px 0px 4px`, display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', buttonColor: btnColor }}
                                    handle={handle}
                                    marks={{ 10: '1', 100: '10' }}
                                    number={this.state.npsNumber}
                                  />
                                }
                              </div>
                              <button disabled={this.state.npsNumber ? false : true} className="dialog-answer-button" style={{ padding: "1rem", margin: "4em 0 0 ", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{this.props.locals.answerButton}</button>
                            </>
                          }

                          {currentQuestion.isDateQuestion &&
                            !this.state.aiWriting &&
                            <Row>
                              <Col md={{ size: 12 }}>
                                {showDatePicker &&
                                  <Row style={{ justifyContent: 'center' }}>
                                    <Col>
                                      <DayPickerSingleDateController
                                        isDayBlocked={this.isDayBlocked}
                                        firstDayOfWeek={1}
                                        date={this.state.date}
                                        onDateChange={date => this.setState({ date })}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        focused={this.state.focused}
                                        hideKeyboardShortcutsPanel={true}
                                      />
                                    </Col>
                                  </Row>
                                }
                              </Col>
                              <Col>
                                {this.state.date && this.state.date._d ?
                                  <button className="dialog-answer-button" disabled={this.state.hideAnswers} style={{ padding: "1rem", margin: "0", marginTop: '0.5em', borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{`${this.props.locals.confirmDate}: ${moment(this.state.date._d).local('fi').format('L')}`}</button>

                                  :
                                  <button className="dialog-answer-button" disabled={this.state.hideAnswers} style={{ padding: "1rem", margin: "0", marginTop: '0.5em', borderColor: groupColor }} onClick={() => this.setState({ showDatePicker: !showDatePicker })}>{this.props.locals.selectDate}</button>
                                }
                              </Col>
                            </Row>
                          }

                          {currentQuestion.isComment &&
                            !this.state.aiWriting &&
                            <button className="dialog-answer-button" style={{ padding: "1rem", margin: "0", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{this.props.locals.continue}</button>
                          }

                          {currentQuestion.isBusinessId &&
                            !this.state.aiWriting &&
                            <span>
                              <textarea className="freetext-answer" placeholder="Esimerkiksi 1234567-1" value={this.state.businessId} onChange={(e) => this.setState({ businessId: e.target.value })}></textarea>
                              <button className="dialog-answer-button" disabled={!checkYTunnus(this.state.businessId)} style={{ padding: "1rem", margin: "0", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>{this.props.locals.answerButton}</button>
                            </span>
                          }

                          {/* if question is normal or variable type */}
                          {!currentQuestion.isFreetextQuestion && !currentQuestion.isNPS && !currentQuestion.isDateQuestion && !currentQuestion.isComment && !currentQuestion.isBusinessId && !currentQuestion.isAttachmentQuestion &&
                            !this.state.aiWriting && this.state.currentAnswers.length > 0 && this.state.currentAnswers.map((obj, index) => {
                              return <button onClick={(e) => this.giveAnswer(e)} disabled={this.state.hideAnswers} style={{ whiteSpace: 'normal', borderColor: groupColor }} key={index} data-index={index} data-text={obj.text} className="dialog-answer-button">
                                {obj.text}
                              </button>
                            })}
                          {currentQuestion.isAttachmentQuestion &&
                            <>
                              <Attachment
                                  onAnswer={this.handleFile}
                                  sentSurveyId={this.state.sentSurveyId}
                                  dialog={true}
                                />
                            </>
                          }
                        </Col>
                      )

                  ) : (
                      <Col>
                        {this.state.toNextSurvey ? (
                          <button className="dialog-answer-button" disabled={this.state.hideAnswers} style={{ borderColor: groupColor }} onClick={() => this.props.getNextSurvey()}>{`${this.props.locals.toContinue}!`}</button>
                        ) : (
                            <button className="answer-button dialog-end-button" disabled={this.state.hideAnswers} style={{ borderColor: groupColor }} onClick={this.props.endDialog}>{this.props.locals.surveyHomePageButton}</button>
                          )}
                      </Col>
                    )}
                </Row>
              </Col>
            </AnswersContainer>
          </>
        }

      </div>
    )
  }
}

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={Math.round(value / 10) > 1 ? Math.round(value / 10) : 1}
      visible={dragging}
      placement="bottom"
      key={index}
    >
      <Handle {...props}><i className="fas fa-grip-horizontal" style={{ color: props.style.buttonColor }}></i></Handle>
    </Tooltip>
  );
};

const AnswersContainer = posed.div({
  open: {
    y: 0,
    opacity: 1
  },
  closed: {
    y: 30,
    opacity: 0,
    transition: {
      duration: 100
    }
  }
})

const Answer = posed.div({
  enter: { x: 0 },
  exit: { x: 100 }
})

const Question = posed.div({
  enter: { x: 0 },
  exit: { x: -100 }
})

// const NPS = posed.div({
//   animate: {
//     applyAtStart: { opacity: 0, x: ({ x }) => x },
//     x: 0,
//     opacity: 1,
//     transition: { duration: 500, delay: 25 }
//   },
//   animateClose: {
//     applyAtStart: { opacity: 1, x: 0 },
//     x: ({ x }) => x,
//     opacity: 0,
//     transition: { duration: 300 }
//   }
// })

const NPStext = posed.div({
  exit: {
    opacity: 0,
    display: 'none',
    transition: { duration: 300 }
  },
  show: {
    opacity: 1,
    display: 'block',
    transition: { delay: 300 }
  }
})

const NPS = posed.div({
  animate: {
    applyAtStart: { opacity: 0, },
    x: 0,
    opacity: 1,
    transition: { duration: 300 }
  },
  animateClose: {
    applyAtStart: { opacity: 1, x: 0 },
    //x: ({ x }) => x,
    opacity: 0,
    transition: { duration: 300 }
  }
})

export default Dialog;