import * as ActionTypes from '../actions/taskActions/actionTypes';

const initialState = {
    taskStatus: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.TASK_STATUS:
          return {
            ...state,
            taskStatus: action.payload
          }
        default:
          return state;
      }
}

export default reducer;