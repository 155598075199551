import React, { useState, useEffect } from 'react';
import {Row, Col } from 'reactstrap';
import RiskMatrice from './RiskMatrice';
import RiskListModal from '../Risks/RiskListModal';
import { riskLevelValues, riskOddsValues } from '../Risks/Riskvalues'


export const RiskBlock = (props) => {
    const {item} = props
    const {riskList, matrice, matriceResults, newMatrice} = props.riskItems
    const [modifiedMatrice, setModifiedMatrice] = useState([])
    const [riskListOpen, setRiskListOpen] = useState(false)
    const [filterRiskLevel, setFilterRiskLevel] = useState([])
    const [filterRiskOdds, setFilterRiskOdds] = useState([])
    const [squareColor, setSquareColor] = useState("")

    
    useEffect(()=> {
        if (newMatrice !== undefined && modifiedMatrice !== newMatrice) {
            // reorder the matrice to match the order amchart wants it to be
            const reOrderedRiskMatrice = []

            for (let j = 0; j < newMatrice.length; j++) {
                for (let i = 0; i < newMatrice.length; i++) {
                    reOrderedRiskMatrice.push(newMatrice[i][j])
                }
            }

            setModifiedMatrice( reOrderedRiskMatrice )
        }
    },[newMatrice])

    const openRiskListModal = (riskOdds, riskLevel, color) => {   
        setFilterRiskLevel(riskLevelValues[riskLevel])
        setFilterRiskOdds(riskOddsValues[riskOdds])
        setSquareColor(color) 
        setRiskListOpen(true)
    }

    const closeRiskListModal = () => {
        setRiskListOpen(false)
        setFilterRiskLevel([])
        setFilterRiskOdds([])
        setSquareColor('')
    }

    
    return(
        <>
            <RiskListModal 
                isOpen={riskListOpen} 
                riskModal={false} 
                modifyRisk={()=> {return}} 
                riskOdds={filterRiskOdds} 
                riskLevel={filterRiskLevel} 
                riskList={riskList} 
                closed={closeRiskListModal} 
                squareColor={squareColor} 
            />
            <Row style={{paddingBottom: '1.75em'}} className="plan-object" id={item.id}>
                <Col>
                    {(riskList && riskList.length > 0)?
                        <Row> 
                            <Col >
                                <Row style={{ margin: 'auto' }}>
                                    <Col className="col-xs-12 risk-matrice-col" xl="12" style={{ padding: '0 2.5em 0 .5em' }}>
                                        
                                        <RiskMatrice 
                                            risks={riskList} 
                                            riskMatriceResults={matriceResults} 
                                            newRiskMatrice={modifiedMatrice} 
                                            openRiskListModal={openRiskListModal}
                                            riskMatrice={matrice}
                                            showRiskMatrice={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                        : (
                            <div style={{paddingTop: '1.5em'}}>
                                <p>Ei riskiarvioita</p>
                            </div>
                        )}
                </Col>
            </Row>
        </>
    )
}