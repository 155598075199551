import React, { Component } from 'react';
import './App.css';
import NavigationBar from './Components/modules/NavigationBar';
import Login from './Components/Login';
import LoginCode from './Components/Login/modules/loginCode';
import Home from './Components/Home';
import Register from './Components/Register';
import { Route, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import { Row, Col, Container, Nav, NavItem, NavLink, Navbar } from 'reactstrap';
import FirstLogin from './Components/FirstLogin/firstlogin';
import TaskHandler from './Components/Tasks/TaskHandler';
import LinkHandler from './Components/ClientLink/LinkHandler';
import PlanHandler from './Components/Plans/PlanHandler'

import locals from './locals/locals';

import CookieConsent from 'react-cookie-consent';
import { detect } from 'detect-browser';
import { connect } from 'react-redux'
import { SignatureVerification } from './Components/Plans/modules/Signature/SignatureVerification/SignatureVerification';
import { BlockInWindow } from './Components/Plans/modules/BlockInWindow';
import { RegisterNewEnterprise } from './Components/Register/RegisterNewEnterprise';

//nämä reduxiin 
const navbarLinks = [
  {
    to: '/home',
    text: locals.toSurvey,
    component: Home
  },
  {
    to: '/login',
    text: locals.logIn,
    component: Login
  },
  // {
  //   to: '/register',
  //   text: locals.register,
  //   component: Register
  // }
]


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: locals.getLanguage()
    }
  }
  
  changeLanguage = language => {
    if(language !== this.state.language) {
      locals.setLanguage(language);
      this.setState({language: language});
    }
  }

  render() {
    
    const browser = detect();
    const style = { color: '#fff', fontSize: '7em', marginBottom: '0.15em' };
    const {hideMenu} = this.props;

    let metadata = document.getElementById('metadata')
    metadata.setAttribute("content",locals.metadataText)  

    if (browser && browser.name === 'ie') {
      return (
        <Container className="home-container h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={{ size: 12 }} className="text-center mainpage-box">
              <h3 className="text-center" style={{ color: '#fff' }}>Jatkaaksesi sivuston käyttöä, lataathan jonkun seuraavista selaimista</h3>
              <Row style={{ marginTop: '2em' }}>
                <Col md={{ size: 4 }}>
                  <a href="https://google.com/chrome"><i style={style} className="fab fa-chrome"></i>
                    <p style={{ color: '#fff' }}>lataa Chrome</p></a>
                </Col>
                <Col md={{ size: 4 }} className="border-right border-left">
                  <a href="https://microsoft.com/fi-fi/windows/microsoft-edge"><i style={ style } className="fab fa-edge"></i>
                    <p style={{ color: '#fff' }}>lataa Edge</p></a>
                </Col>
                <Col md={{ size: 4 }}>
                  <a href="https://mozilla.org/firefox"><i style={ style } className="fab fa-firefox"></i>
                    <p style={{ color: '#fff' }}>lataa Firefox</p></a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <span>
            <header id="frontpageHeader" style={!hideMenu ? {display: 'none'} : {display: 'block'}}>
              <NavigationBar links={navbarLinks} language={this.state.language} changeLanguage={this.changeLanguage} locals={locals}/>
            </header>
            {(hideMenu === undefined || !hideMenu)  &&
              <header id="alternativeHeader" styler={{display: 'block', zIndex:'100', position:'absolute', top:0, right:0}}>
                <Navbar expand="md" light className="alternative-header-nav" >
                  <Nav className="ml-auto" navbar style={{backgroundColor: '#FFF', flexDirection: "row", borderRadius: "0 0 0 10px"}}>
                    <NavItem className="nav-item ml-auto" style={this.state.language === 'fi' ? {fontWeight: 'bold', color:'#000000b3'} : {cursor: 'pointer'}} onClick={() => this.changeLanguage('fi')}>
                      <span className={"nav-link " + (this.state.language === 'fi' && "nav-link-active")}>FI</span>
                    </NavItem>
                    <NavItem className="nav-item ml-auto" style={this.state.language === 'en' ? {fontWeight: 'bold', color:'#000000b3'} : {cursor: 'pointer'}} onClick={() => this.changeLanguage('en')}>
                      <span className={"nav-link " + (this.state.language === 'en' && "nav-link-active")}>EN</span>
                    </NavItem>
                  </Nav>
                </Navbar>
              </header>
            }
          <main id="main-container" role="main">
            <NotificationContainer />
            <Switch>
              <Redirect exact from='/' to='/login' />
              <Route exact path="/home" render={(props)=> 
                  <Home locals={locals} {...props}/>
              } />
              <Route exact path="/login" render={(props) => 
                <Login locals={locals} {...props}/>
              } />
              <Route exact path="/logincode" render={(props) => 
                <LoginCode locals={locals} {...props} />
              } />
              <Route exact path="/register" render={(props) =>
                <RegisterNewEnterprise locals={locals} {...props} />
              } />
              <Route exact path="/task/:id" render={(props) =>
                <TaskHandler locals={locals} {...props} />
              } />
              <Route exact path="/link/:id" render={(props) =>
                <LinkHandler locals={locals} {...props} />
              } />
              <Route exact path="/plan/:id" render={(props) =>
                <PlanHandler locals={locals} {...props} />
              } />
              <Route exact path="/verify/signature/:id" render={(props) =>
                <SignatureVerification locals={locals} {...props} />
              } />
              <Route exact path="/viewPlanItem/:plan/:id" render={(props) =>
                <BlockInWindow locals={locals} {...props} />
              } />
              <Route path="/firstLogin/:userToken" render={(props) =>
                <FirstLogin locals={locals} {...props} />
              } />
              <Route path='/:code' render={(props)=> 
                  <Home locals={locals} {...props}/>
              } />
              <Route render={(props)=> 
                  <Login locals={locals} {...props}/>
              } />
            </Switch>
          </main>

          <CookieConsent
            buttonText="Hyväksy"
            buttonId="ok-button"
            style={{ backgroundColor: '#fff' }}
            buttonStyle={{ color: '#000', backgroundColor: '#B5E413', padding: 12 }}
            contentStyle={{ color: '#000' }}>
            {locals.cookieMessage}
          </CookieConsent>

        </span>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    hideMenu: state.surveys.userDialog ? state.surveys.userDialog.hideMenu : false
  }
}

export default connect(mapStateToProps, null)(App);