import React, { Component } from 'react'
import {
  Row,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroup,
  Button
} from 'reactstrap';
import * as ActionCreators from '../../store';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './register.css';
import { NotificationManager } from 'react-notifications';


class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      business: {
        businessName: "",
        address: "",
        businessID: "",
        postCode: "",
        postOffice: ""
      },
      user: {
        email: "",
        phone: "",
        passwd: "",
        passwd_confirmation: "",
        termsCheck: false
      },
      formValid: false,
      errors: {},
      toLogin: false
    }
  }

  formIsValid() {
    let validation = {
      isValid: true,
      errors: [],
      errorField: {}
    };

    Object.keys(this.state.business).map((objKey) => {
      
      if (objKey === 'businessName'){
        if(this.state.business.businessName === ''){
          validation.isValid = false;
          validation.errorField.businessName = true;
        }
      }
      else if (objKey === 'address'){
        if(this.state.business.address === ''){
          validation.isValid = false;
          validation.errorField.address = true;
        }
      }
      else if (objKey === 'businessID'){
        if(this.state.business.businessID === ''){
          validation.isValid = false;
          validation.errorField.businessID = true;
        }
      }
      else if (objKey === 'postCode'){
        if(this.state.business.postCode === ''){
          validation.isValid = false;
          validation.errorField.postCode = true;
        }
      }
      else if (objKey === 'postOffice'){
        if(this.state.business.postOffice === ''){
          validation.isValid = false;
          validation.errorField.postOffice = true;
        }
      }
    })
    
    Object.keys(this.state.user).map((objKey) => {
      const emailCheck = /[^@]+@[^\.]+\..+/;

      if(objKey === 'passwd' && (this.state.user.passwd === '' || this.state.user.passwd !== this.state.user.passwd_confirmation)) {
        validation.isValid = false;
        validation.errorField.password = true;
      }
      else if (objKey === 'email' && !emailCheck.test(this.state.user.email)){
        validation.isValid = false;
        validation.errorField.email = true;
      }
      
      else if (objKey === 'phone'){
        if (this.state.user.phone === '' || this.state.user.phone.length > 12){
          validation.isValid = false;
          validation.errorField.phone = true;
        }
      }
      else if (objKey === 'termsCheck'){
        if (this.state.user.termsCheck === false){
          // tämä vielä kommentoitu, koska ei ole olemassa käyttöehtoja. voi ottaa kommentin pois kun käyttöehdot lisätään ja halutaan validoida tämäkin
          // validation.isValid = false;
          // validation.errorField.termsCheck = true;
        }
      }
      else if(this.state.user[objKey] === "") {
        validation.isValid = false;
        if(validation.errors.indexOf("Kaikki kentät ovat pakollisia!") === -1) {
          validation.errorField.emptyFields = true;
        }
      }
    })

    if (!validation.isValid){
      this.setState({errors: validation.errorField});
      NotificationManager.error(this.props.locals.missingFields, this.props.locals.error, 3000);
  }
    
    return validation;
  }

  handleInput(e) {
    if(e.target.name === 'termsCheck') {
      e.persist()

      this.setState( prevState => ({
        user: {
          ...prevState.user,
          termsCheck: e.target.checked
        }
      }))
    } else {
      //determine whether the data should go under business or user 
      const stateKey = e.target.getAttribute('data-field');
      const persKey = e.target.name;
      const persVal = e.target.value;

      this.setState(prevState => ({
        [stateKey]: {
          ...prevState[stateKey],
          [persKey]: persVal
        }
      }))
    }
  }

  handleSubmit = (e) => {
    const formValidation = this.formIsValid();

    if(formValidation.isValid) {

      let realPhone = this.state.user.phone.replace(/\D/g,'');
        if (realPhone.startsWith(358)){
          realPhone = realPhone.substring(3);
        }
        if (realPhone[0] === '0' || realPhone[0] === 0){ 
          while(realPhone.charAt(0) === '0'){
            realPhone = realPhone.substring(1);
          }
        }

      let userData = {...this.state.user};
      userData.phone = '+358' + realPhone;

      const registerData = {
        business: this.state.business,
        user: userData
      }
      
      this.props.onRegister(registerData);
      
      this.setState({
        errors: {},
        business: {
          businessName: "",
          address: "",
          businessID: "",
          postCode: "",
          postOffice: ""
        },
        user: {
          email: "",
          phone: "",
          passwd: "",
          passwd_confirmation: "",
          termsCheck: false
        }
      })
      NotificationManager.success(this.props.locals.registrationSuccess, this.props.locals.notice, 3000);
      this.setState({toLogin: true})
    } else {
      formValidation.errors.map(error => {
        //return NotificationManager.error(error, "Ilmoitus", 3000)
      })
    }
  }

  render() {
    const {locals} = this.props
    return (
      <>
      { this.state.toLogin && <Redirect to="/login" /> }
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <div className="col-md-8 greybox">
              <div className="logo">
              </div>
              <Form className="white_input">
                <div className="row">
                  <div className="col-md-12">
                    <h3>{locals.companyInfo}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.businessName}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.businessName ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="business" placeholder="" type="text" name="businessName" required value={this.state.business.businessName} />
                  </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.businessAddress}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.address ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="business" placeholder="" type="text" name="address" required value={this.state.business.address} />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.businessID}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.businessID ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="business" placeholder="" type="text" name="businessID" required value={this.state.business.businessID} />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.businessPostCode}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.postCode ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="business" placeholder="" type="text" name="postCode" required value={this.state.business.postCode} />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.businessPostAddress}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.postOffice ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="business" placeholder="" type="text" name="postOffice" required value={this.state.business.postOffice} />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3>{locals.contactPerson}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.email}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.email ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="user" placeholder="" type="text" name="email" required value={this.state.user.email} />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                      <FormGroup>
                      <label>{locals.phone}</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">+358</InputGroupAddon>
                        <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.phone ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="user" placeholder="" type="text" name="phone" required value={this.state.user.phone} />
                      </InputGroup>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label>{locals.password}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.password ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="user" placeholder="" type="password" name="passwd" required value={this.state.user.passwd} />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                        <label>{locals.confirmPassword}</label>
                      <Input onChange={(e) => {this.handleInput(e)}} className={this.state.errors.password ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} data-field="user" placeholder="" type="password" name="passwd_confirmation" required value={this.state.user.passwd_confirmation} />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check mb-2">
                    <label className="form-check-label" htmlFor="termsCheck">
                      {locals.agreeTerms}
                      <input onClick={(e) => {this.handleInput(e)}} className="form-check-input" type="checkbox" checked={this.state.user.termsCheck} name="termsCheck" required />
                      { this.state.errors.termsCheck && <i className="fas fa-times" style={{ color: '#dc3545', marginLeft: '1rem', fontSize: '1.1rem' }}></i> }
                      </label>
                    </div>
                  </div>
                </div>
                
                <FormGroup>
                  <Button className="btn btn-lg btn-block login-button" onClick={this.handleSubmit}>{locals.register}</Button>
                </FormGroup>
              </Form>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

//Any actions we want to give this component
//onAddUser is optional name, you can choose the name. we use it as this.props.onAddUser in this case
//payload is optional name, you can choose whatever name you want, but you HAVE to use the same name in the reducer!
const mapDispatchToProps = dispatch => {
  return {
      onRegister: (comp) => dispatch(ActionCreators.register({company: comp})),
  }
}

export default connect(null, mapDispatchToProps)(Register);