import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { confs } from '../../../../bootstrap/config';


let baseUrl = confs.url

export const AttachmentBlock = ({item, planId, locals}) => {
    const [fileOptionsModal, setFileOptionsModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState({})

    const handleDownload = async(file, preview = false) => {
        let payload = {
            fileId: file._id,
        }

        try {
            const responseJson = await fetch(baseUrl + 'downloadFile', {
                method: 'POST',
                body: JSON.stringify(payload)
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                           
                if(response.data){
                    try{
                        var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = response.name + "." + response.fileEnd;
                
                        document.body.appendChild(link);
                        
                        if(preview){
                            window.open(link)
                        } else {
                            link.click();
                        }
                
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href)
        
                    } catch {}
                }
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }


    return (
        Object.keys(item).length > 0 ?
        <Row style={{paddingBottom: '1.75em'}} className="plan-object" id={item.id}>
            <Modal isOpen={fileOptionsModal} toggle={()=> setFileOptionsModal(false)}>
                <ModalHeader toggle={()=> setFileOptionsModal(false)}> 
                    <Row className="plan-attachment-row plan-attachment" >
                        <Col xl="12">
                            <p className="modal-name-overflow-handler" style={{ color: '#9a0707' }}>
                                {selectedFile.fileName +"."+selectedFile.fileEnd}
                            </p>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    {selectedFile && (selectedFile.fileEnd === "jpg" || selectedFile.fileEnd === "jpeg" || selectedFile.fileEnd === "png" || selectedFile.fileEnd === "svg" || selectedFile.fileEnd === "bmp" || selectedFile.fileEnd === "pdf") &&
                        <button onClick={() => handleDownload(selectedFile, true)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>{locals.preview}</button>
                    }   
                    <button onClick={() => handleDownload(selectedFile)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>{locals.download}</button>
                </ModalBody>
                <ModalFooter>
                <button onClick={() => setFileOptionsModal(false)} className="small-white-button wide-button " style={{ width: '100%' }}>{locals.back}</button>
                </ModalFooter>
            </Modal>
            <Col xl="12">
            </Col>
            <Col xl="12" style={{paddingTop: '0.8em'}} className="plan-text-col"> 
                {item.files && item.files.map((file,key) => {
                    return(
                        <Row key={key} className="plan-attachment-row plan-attachment" >
                            <Col className="plan-attachment plan-attachment-row " onClick={() => (setFileOptionsModal(true), setSelectedFile(file))}>
                                <i className="far fa-file-alt" style={{ fontSize: '1.2rem', paddingRight: '0.5em' }}></i><p className="planAttachment-name-overflow-handler" title={file.fileName +"."+file.fileEnd}>{" - " +file.fileName +"."+file.fileEnd}</p>
                            </Col>
                        </Row>
                    //     <Row key={key} className="plan-attachment-row" >
                    //     <Col className="plan-attachment plan-attachment-row " onClick={() => handleDownload(file)}>
                    //         <i className="far fa-file-alt" style={{ fontSize: '1.2rem', paddingRight: '0.5em' }}></i><p className="planAttachment-name-overflow-handler" title={file.fileName +"."+file.fileEnd}>{" - " +file.fileName +"."+file.fileEnd}</p>
                    //         {editable&&
                    //             <div className="remove-planAttachment" onClick={() => handleRemove(key, file)}>
                    //                 <i className="fas fa-times"></i>
                    //             </div>
                    //         }
                    //     </Col>
                        
                    // </Row>
                    )
                })}           
            </Col>
        </Row>
        :null
    )

}

