import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import CodeInput from '../modules/CodeInput'
import QibbieRobot from '../modules/QibbieRobot'
import Dialog from './modules/dialog'
import Survey from './modules/survey'
import Feedback from './modules/feedback'
import AskToContinueModal from './modules/askToContinueModal'
import * as ActionCreators from '../../store'
import './home.css'
import { confs } from '../../bootstrap/config'
import { SheetSurvey } from './modules/SheetSurvey'

class Home extends Component {
  constructor() {
    super()

    this.state = {
      dialog: {},
      currentSurveyIndex: 0,
      dialogEnded: false,
      sentSurvey: null,
      previousAnswerSession: null,
      askToContinue: false,
      askCode: false,
      holdStart: false, //this is for continuing a previous answerSession that is in a linked survey. or if we for whatever reason need to hold the starting of a survey/dialog
    }

    this.previousAnswerSession = null
  }

  componentDidMount() {
    if (typeof this.props.match.params.code !== 'undefined' && this.props.match.params.code !== null) {
      let code = this.props.match.params.code || ""

      if (code !== "") {
        code = code.toUpperCase()
        this.props.getSurvey(code)
          .then(() => {
            if (this.props.sentSurvey === null) {
              this.setState({ askCode: true })
            }
          })
      }
    } else {
      this.setState({ askCode: true })
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.sentSurvey !== null && nextProps.sentSurvey !== this.props.sentSurvey) {
      const prevSession = this.checkPreviousSession(nextProps.sentSurvey._id)

      if (nextProps.sentSurvey.changeTarget) {
        this.changeTargetSurveyByAnswer(nextProps.sentSurvey);
      } else {
        if (prevSession) {
          this.previousAnswerSession = prevSession
          this.setState({ askToContinue: true })
        } else {

          this.startNewAnswerSession(nextProps)
        }
      }
    }
  }

  changeTargetSurveyByAnswer = async (sentSurvey) => {
    this.setState({
      sentSurvey: sentSurvey,
      currentSurveyIndex: 0
    });
  }

  checkPreviousSession = (sentSurveyId) => {
    const previousSessionJson = localStorage.getItem(sentSurveyId)

    if (previousSessionJson !== null) {
      return (JSON.parse(previousSessionJson))
    } else {
      return false
    }
  }

  onCodeSubmit = (code) => {
    // this.setState({dialogEnded: false})
    this.props.getSurvey(code)
  }

  onSurveyAnswer = (answerObj) => {
    //do something? and then...
    return this.props.onSurveyAnswer(answerObj)
  }

  endSurvey = () => {
    var url = "https://www.everbetter.fi/"

    // Here check if custom endingpage

    window.location.assign(url) 
       
    this.setState({ currentSurveyIndex: 0, dialogEnded: true, sentSurvey: null })
    this.props.endSurvey()
  }


  // sets next survey in sentsurvey stack active, if has any. returns true if has, false if has not
  // also clears previous answer session from state
  getNextSurvey = () => {
    if (this.props.sentSurvey.surveys[this.state.currentSurveyIndex + 1]) {
      this.setState(prevState => ({
        currentSurveyIndex: prevState.currentSurveyIndex + 1,
        previousAnswerSession: null
      }))
      return true
    } else {
      return false
    }
  }

  checkNextSurveyType = () => {
    const surveyData = this.props.sentSurvey

    if (surveyData.surveys[this.state.currentSurveyIndex + 1]) {
      return surveyData.surveys[this.state.currentSurveyIndex + 1].type
    }
    return false
  }

  storeAnswerSession = (answerSession) => {
    const { currentSurveyIndex } = this.state

    return new Promise(function (resolve) {
      try {
        answerSession.currentSurveyIndex = currentSurveyIndex
        localStorage.setItem(this.props.sentSurvey.orginalId, JSON.stringify(answerSession))
        resolve(true)
      } catch (error) {
        console.error(error)
        resolve(false)
      }
    }.bind(this))
  }

  clearAnswerSession = () => {
    localStorage.removeItem(this.props.sentSurvey.orginalId)
  }

  toggleContinueModal = () => {
    this.setState(prevState => ({
      askToContinue: !prevState.askToContinue,
      askCode: true
    }))
  }

  continuePreviousSession = async () => {
    const holdStart = this.previousAnswerSession.inLinkedSurvey !== null
    this.setState({
      previousAnswerSession: this.previousAnswerSession,
      currentSurveyIndex: this.previousAnswerSession.currentSurveyIndex,
      sentSurvey: this.props.sentSurvey,
      askToContinue: false,
      holdStart: holdStart
    }, async () => {
      if (this.previousAnswerSession.inLinkedSurvey !== null) {
        await this.props.onTargetSurveyChange({ targetSurvey: this.previousAnswerSession.inLinkedSurvey, sessionId: this.previousAnswerSession.answerSessionId, sentSurveyId: this.props.sentSurvey._id })
        this.setState({ holdStart: false })
      }
    })
  }

  startNewAnswerSession = (nextProps = null) => {
    if (nextProps !== null) {
      localStorage.removeItem(nextProps.sentSurvey._id)
      this.setState({
        sentSurvey: nextProps.sentSurvey,
        askToContinue: false
      })
    } else {
      localStorage.removeItem(this.props.sentSurvey._id)
      this.setState({
        sentSurvey: this.props.sentSurvey,
        askToContinue: false
      })
    }
  }

  render() {
    const { locals } = this.props
    let bg = false
    let bgStyle = {}
    if (this.props.sentSurvey && this.props.sentSurvey.background) {
      bg = this.props.sentSurvey.background
      bgStyle = { backgroundImage: `url(${confs.host}/api/getBackground/${bg.id})`, boxShadow: bg.color ? `inset 0 0 0 100vmax rgba(${bg.color.r}, ${bg.color.g}, ${bg.color.b}, ${bg.color.a})` : 'none', filter: `blur(${bg.blur}px)` }
    }
    return (
      <span>
        {bg &&
          <div className="background" style={bgStyle}></div>
        }
        <AskToContinueModal isOpen={this.state.askToContinue} toggle={this.toggleContinueModal} continue={this.continuePreviousSession} startNew={this.startNewAnswerSession} locals={locals} />
        <Container className="home-container h-100">
          <Row className={"justify-content-center h-100 " + (this.state.sentSurvey === null ? "align-items-center" : "")}>
            {/* holdStart happens when we need to get a previousAnswerSession that is in a linked survey */}
            {(this.state.sentSurvey !== null && !this.state.holdStart) ? (
              this.state.sentSurvey.surveys[this.state.currentSurveyIndex].type === 'dialog' ? (
                <Dialog
                  dialogObj={this.state.sentSurvey.surveys[this.state.currentSurveyIndex]}
                  onSurveyAnswer={this.onSurveyAnswer}
                  onTargetSurveyChange={this.props.onTargetSurveyChange}
                  getNextSurvey={this.getNextSurvey}
                  answerSessionId={this.props.answerSessionId}
                  sentSurvey={this.state.sentSurvey}
                  checkNextSurveyType={this.checkNextSurveyType}
                  endDialog={this.endSurvey}
                  storeAnswerSession={this.storeAnswerSession}
                  previousAnswerSession={this.state.previousAnswerSession}
                  clearAnswerSession={this.clearAnswerSession}
                  getFeedbackParams={this.props.getFeedbackParams}
                  activeLogo={this.props.activeLogo}
                  getEndTextParams={this.props.getEndTextParams}
                  locals={locals}
                  handleAutomation={this.props.handleAutomation}
                />
              ) :
                this.state.sentSurvey.surveys[this.state.currentSurveyIndex].type === 'feedback' ? (
                  <Feedback
                    feedbackObj={this.state.sentSurvey.surveys[this.state.currentSurveyIndex]}
                    onSurveyAnswer={this.onSurveyAnswer}
                    answerSessionId={this.props.answerSessionId}
                    storeAnswerSession={this.storeAnswerSession}
                    getNextSurvey={this.getNextSurvey}
                    endSurvey={this.endSurvey}
                    startNewAnswerSession={this.props.endSurvey}
                    getFeedbackParams={this.props.getFeedbackParams}
                    locals={locals}
                  />
                )
                :
                this.state.sentSurvey.surveys[this.state.currentSurveyIndex].type.toUpperCase() === 'LAKANA' ?
                (
                  <SheetSurvey
                    surveyObj={this.state.sentSurvey.surveys[this.state.currentSurveyIndex]}
                    // onSurveyAnswer={this.onSurveyAnswer}
                    endSurvey={this.endSurvey}
                    // onTargetSurveyChange={this.props.onTargetSurveyChange}
                    getNextSurvey={this.getNextSurvey}
                    surveySender={this.state.sentSurvey.sentBy}
                    answerSessionId={this.props.answerSessionId}
                    sentSurvey={this.state.sentSurvey}
                    storeAnswerSession={this.storeAnswerSession}
                    previousAnswerSession={this.state.previousAnswerSession}
                    clearAnswerSession={this.clearAnswerSession}
                    activeLogo={this.props.activeLogo}
                    getEndTextParams={this.props.getEndTextParams}
                    handleAutomation={this.props.handleAutomation}
                    locals={locals}
                  />
                )
                :
                (
                  <Survey
                    surveyObj={this.state.sentSurvey.surveys[this.state.currentSurveyIndex]}
                    onSurveyAnswer={this.onSurveyAnswer}
                    endSurvey={this.endSurvey}
                    onTargetSurveyChange={this.props.onTargetSurveyChange}
                    getNextSurvey={this.getNextSurvey}
                    surveySender={this.state.sentSurvey.sentBy}
                    answerSessionId={this.props.answerSessionId}
                    sentSurvey={this.state.sentSurvey}
                    storeAnswerSession={this.storeAnswerSession}
                    previousAnswerSession={this.state.previousAnswerSession}
                    clearAnswerSession={this.clearAnswerSession}
                    activeLogo={this.props.activeLogo}
                    getEndTextParams={this.props.getEndTextParams}
                    locals={locals}
                    handleAutomation={this.props.handleAutomation}
                  />
                )
            ) : (
                <span style={{ padding: '0 .5em' }}>
                  {(this.state.askCode || this.state.dialogEnded) &&
                    <Col md={{ size: 12 }} className="text-center login-box">
                      <div className="login-inner-container">
                        <div className="login-box-content">
                            <div className="ever-better-logo" style={{ paddingTop:'0.5em'}}>EverBetter.</div>
                            <h2 className="text-center" style={{ color: '' }}>{locals.insertCode}</h2>
                            <CodeInput onSubmit={this.onCodeSubmit} locals={locals} />
                          </div>
                        </div>
                    </Col>
                  }
                  <QibbieRobot top="70%" left="5%" title={locals.robotTitle} text={locals.robotMessage} alignment="right" />
                </span>
              )}

          </Row>
        </Container>

      </span>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSurvey: (code) => dispatch(ActionCreators.getSurvey({ code: code })),
    onSurveyAnswer: obj => dispatch(ActionCreators.onSurveyAnswer(obj)),
    onTargetSurveyChange: obj => dispatch(ActionCreators.fetchTargetSurveyAfterAnswer(obj)),
    endSurvey: () => dispatch(ActionCreators.endUserSurvey),
    getFeedbackParams: (params) => dispatch(ActionCreators.getFeedbackParameterValues(params)),
    getEndTextParams: (params) => dispatch(ActionCreators.getEndTextParameterValues(params)),
    handleAutomation: (params) => dispatch(ActionCreators.handleAutomation(params))
  }
}

const mapStateToProps = state => {
  return {
    sentSurvey: state.surveys.userDialog,
    answerSessionId: state.surveys.answerSessionId,
    activeLogo: state.surveys.activeLogo
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)