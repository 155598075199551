import React from 'react'
import { 
  EditorState, 
  ContentState, 
  convertFromRaw,
  convertToRaw
 } from 'draft-js'
import Editor, { composeDecorators } from 'draft-js-plugins-editor'

/** Draft-js plugins */
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css' 

import createLinkPlugin from 'draft-js-anchor-plugin'
import 'draft-js-anchor-plugin/lib/plugin.css'

import createVideoPlugin from 'draft-js-video-plugin'
import createImagePlugin from 'draft-js-image-plugin'

import createAlignmentPlugin from 'draft-js-alignment-plugin'
import 'draft-js-alignment-plugin/lib/plugin.css'

import createResizeablePlugin from 'draft-js-resizeable-plugin'

import createEmojiPlugin from 'draft-js-emoji-plugin'
import 'draft-js-emoji-plugin/lib/plugin.css'

import './texteditor.css'
import ImageAdd from './addImageButton'

import createHashtagPlugin from 'draft-js-hashtag-plugin';

const inlineToolbarPlugin = createInlineToolbarPlugin()
const videoPlugin = createVideoPlugin()
const alignmentPlugin = createAlignmentPlugin()
const resizePlugin = createResizeablePlugin()

const decorator = composeDecorators(
  alignmentPlugin.decorator,
  resizePlugin.decorator
)

const imagePlugin = createImagePlugin({
  decorator,
  theme: {
    image: 'draft-js-image'
  }
})

const linkPlugin = createLinkPlugin({
  placeholder: 'Linkin osoite',
  linkTarget: '_blank'
})

const emojiPlugin = createEmojiPlugin()

const hashtagPlugin = createHashtagPlugin()

const plugins = [
  inlineToolbarPlugin, linkPlugin, videoPlugin,
  imagePlugin, alignmentPlugin, resizePlugin,
  emojiPlugin, hashtagPlugin
]

class TextEditor extends React.Component {
  constructor(props) {
    super(props)

    const textContent = props.content || undefined
    let contentState = ''

    if(typeof textContent === 'string') {
      contentState = ContentState.createFromText(textContent)
    } else {
      if(textContent !== undefined) {
        contentState = convertFromRaw(textContent)
      } else {
        contentState = ContentState.createFromText("Ei määritelty")
      }
    }

    this.state = {
      editorState: EditorState.createWithContent(contentState),
      arrayOfImages:[]
    }
  }

  componentDidUpdate(prevProps) {    
    if(prevProps.editorId !== this.props.editorId) {
      const textContent = this.props.content
      let contentState = ''
      
      if(typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      this.setState({
        editorState: EditorState.createWithContent(contentState), 
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })
    } else if (prevProps.content !== this.props.content && this.props.needToForceUpdate === true) {
      //This will update incoming change when the whole content needs to be updated 
      // it is important to set the needToForceUpdate false to maintain integrity in the editor afterwards
       const textContent = this.props.content || ''
       let contentState = ''
 
       if (typeof textContent === 'string') {
         contentState = ContentState.createFromText(textContent)
       } else {
         contentState = convertFromRaw(textContent)
       }
 
       let editorState = "";
       // if(this.props.editorId !== undefined && (this.props.editorId.includes("-categoryTitle") || this.props.editorId.includes("-groupTitle") || this.props.editorId.includes("-questionTitle")) && typeof textContent === 'string' && textContent !== "") {
       //   editorState = EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
       // } else {
       //   editorState = EditorState.createWithContent(contentState)
       // }
       editorState = EditorState.createWithContent(contentState)
       this.setState({
         editorState: editorState,
         editorReadOnly: this.props.readOnly || false,
         additionalClass: this.props.additionalClass || ""
       })
    }
  }

  onChange = (editorState, forceSave=false) => {

    let previousState = this.state.editorState.getCurrentContent()
    let newState = editorState.getCurrentContent()
  
    // Handles image resize to be saved
    // Still need to remove the edit option -bar when saved
    let figures = document.getElementsByTagName("figure") 

    if(figures){
      let oldImageState = [...this.state.arrayOfImages]
      let newImageState = []
      let index = 0
      for(var figure of figures){

        let image = figure.firstChild
        if(image){
          let newImageWidth = image.style.width
          newImageState = [...newImageState, newImageWidth]

          if(oldImageState.length > 0){
            let oldImageWidth = oldImageState[index] 

            if(newImageWidth !== oldImageWidth){
              forceSave = true
            }
          }
        }
      }
      index++
      this.setState({arrayOfImages:newImageState})
    }
    //

    if( (previousState !== newState) || forceSave ) {
      
        this.props.onTextChange( convertToRaw( editorState.getCurrentContent() ))
    }
    this.setState({ editorState })

    if(this.props.content === '') {
      editorState = EditorState.createEmpty()
    }
    this.setState({editorState})
    this.props.onTextChange(convertToRaw(editorState.getCurrentContent()))
  }

  onURLChange = (e) => {
    this.setState({urlValue: e.target.value})
  }

  focus = () => {
    this.editor.focus()
  }

  onMediaAdd = ( editorState ) => {
    this.setState({ editorState })
    this.onChange( editorState, true )
  }

  render() {
    const { additionalClass, readOnly, placeholder, textAlignment, groupColor, isSheet=false, sender, documentId } = this.props
    const allowFileUpload = this.props.allowFileUpload || false
    return(
      <div onClick={this.focus} className={"texteditor-container " + (readOnly ? "readonly " : "") + (additionalClass ? additionalClass : "")}>
        <Editor 
          editorState={this.state.editorState} 
          onChange={this.onChange} 
          handleKeyCommand={this.handleKeyCommand}
          readOnly={readOnly}
          ref={(element) => {this.editor = element}}
          plugins={plugins}
          placeholder={placeholder}
          textAlignment={textAlignment}
        />
       {!this.state.readOnly && allowFileUpload &&
          <ImageAdd
            isSheet={isSheet}
            editorState={this.state.editorState}
            modifier={imagePlugin.addImage}
            onChange={this.onMediaAdd}
            groupColor={groupColor}
            enterpriseId={sender}
            documentId={documentId}
          />
       }
        {/* {!this.state.readOnly && 
          <InlineToolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <React.Fragment>
                  <HeadlineThreeButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                </React.Fragment>
              )
            }
          </InlineToolbar>
        } */}
      </div>
    )
  }

}

export default TextEditor