import React, {useEffect, useState} from 'react';
import TextEditor from '../../../modules/TextEditor/texteditor';
import {Table} from 'reactstrap';

export const TableItem = ({table, needToForceUpdate = false}) => {
    const [borderless, setBorderless] = useState(table.borderless? table.borderless : false)
    const {rows} = table;

    useEffect(()=>{
        if(table && table.borderless){
            setBorderless(table.borderless)
        }
    },[table])

    return (
        <div style={{ marginTop:'0.5em'}}>
            {table && table.showAdditionalTextField &&
                <div style={{ marginBottom:'1em' }}>
                    <TextEditor 
                        editorId={`${table.id}`} 
                        content={table.textContent} 
                        onTextChange={() => {return}}
                        additionalClass='plan-paragraph'
                        readOnly={true}
                    />
                </div>
            }
            <Table borderless={borderless? borderless : false} bordered={borderless? !borderless : true} hover style={{tableLayout: 'fixed'}}>
                <thead>
                </thead>
                <tbody>
                    {rows && rows.map((row, rowIndex) => 
                        <TableRowContainer key={rowIndex} row={row} rowIndex={rowIndex} maximumCount={table.selectedNumberOfCols} item={table} needToForceUpdate={needToForceUpdate}/>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

const TableRowContainer = ({row, rowIndex, maximumCount, item, needToForceUpdate}) => {
    const { cols } = row
    const {customWidth} = item
    return (
        <tr key={rowIndex}>
            {cols.map((col, index) =>
                <ColumnEditor 
                    col={col} 
                    key={col.id} 
                    editorId={col.id} 
                    rowIndex={rowIndex} 
                    count={cols.length}
                    maximumCount={maximumCount}
                    colIndex={index}
                    customWidth={(customWidth && customWidth[index])? (customWidth[index] + "%"):"" }
                    needToForceUpdate={needToForceUpdate}
                />
            )}
        </tr>
    )
}

const ColumnEditor = ({col, editorId, maximumCount, count, colIndex, customWidth, needToForceUpdate}) => {

    const getColSpan = () => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

    return (
        <td style={{verticalAlign: 'top', backgroundColor: col.color? col.color : "#ffff"}} width={customWidth} colSpan={getColSpan()}>
            <TextEditor
                editorId={editorId}
                content={col.text}
                additionalClass="plan-input"
                onTextChange={() => { return }}
                readOnly={true}
                needToForceUpdate={needToForceUpdate}
            />
        </td>
    )
}

