import React, { useState } from 'react';
import moment from 'moment';

export const Annotation = ({item, key, handleDelete }) => {
    const {text, color, created, nameTag} = item
    // This is the actual visible annotation that user can see
    return(
        <div key={key} className="annotation" style={{ marginLeft: '0', border: '3px solid '+ color, backgroundColor: color +'30'}}>
           <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="annotation-col">
                    {text}
                </div>
                <div style={{display:'flex', width:'100%'}}>
                    {nameTag && nameTag.length > 0 && <p className="annotation-timestamp" style={{  marginTop: 0, marginBottom: 0 }}>{'-' +nameTag}</p>}
                    <p style={{ marginLeft:'auto', marginTop: 0, marginBottom: 0, marginRight: '-0.5em'}} className="annotation-timestamp">{moment.utc(created, "MMM D, YYYY hh:mm:ss A", "en").local().format('HH:mm DD.MM.YYYY')}</p>
                </div>
            </div>    
            <i className="fas fa-times delete-annotation" onClick={() => handleDelete(item)}></i> 
        </div>
    )
}