export const riskOddsValues = [
    {
        label: "Epätodennäköinen ( 1 )",
        value: 1
    },
    {
        label: "Mahdollinen ( 2 )",
        value: 2
    },
    {
        label: "Todennäköinen ( 3 )",
        value: 3
    },
    {
        label: "Lähes varma ( 4 )",
        value: 4
    }
]

export const riskLevelValues = [
    {
        label: "Vähäinen / ei vaikuta ( 1 )",
        value: 1
    },
    {
        label: "Kohtalainen ( 2 )",
        value: 2
    },
    {
        label: "Merkittävä ( 3 )",
        value: 3
    },
    {
        label: "Kriittinen ( 4 )",
        value: 4
    }
]