import React from 'react';
import robotLeft from '../../../assets/images/qibbierobot-left.png';
import robotRight from '../../../assets/images/qibbierobot-right.png';
import './robot.css';

const QibbieRobot = (props) => {
  return (
    <div id="qibbie-dude" style={{position: "absolute", top: props.top, left: props.left, height: "200px", width: "400px"}}>
    	<div className="wrapper">
	      <div className={props.alignment === 'left' ? ('robot-left') : ('robot')} style={props.alignment === 'left' ? {backgroundImage: `url(${robotLeft})`} : {backgroundImage: `url(${robotRight})`}}></div>
	      <div className={props.alignment === 'left' ? ('robot-dialog-left') : ('robot-dialog')}>
	        <h4 className='robot-dialog-title'>{props.title}</h4>
	        <p className='robot-dialog-text'>{props.text}</p>  
	      </div>
	     </div>
    </div>
  )
  
}

export default QibbieRobot;