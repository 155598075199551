import React, {useState, useEffect} from 'react';
import {Row, Col, Label} from 'reactstrap';
import TextEditor from '../../../modules/TextEditor/texteditor';

export const ProductBlock = ({item, index}) => {
    const [products, setProducts] = useState(item.products)
    const [title, setTitle] = useState(item.topicContent)
    const [totalAmount, setTotalAmount] = useState(0)

    const showSummary = (item && item.showSummary !== undefined) ? item.showSummary : true

    useEffect(()=> {
        if(products && products.length > 0){
            let total = 0
            products.map(product => 
                total += (product.price * product.amount)
            )
            setTotalAmount(total.toFixed(2))
        }
    },[products])

    return (
        Object.keys(item).length > 0 ?
            <>
                <Row style={{paddingBottom: '1.75em'}} className="plan-object" id={item.id}>
                    
                    <Col xl="12" style={{paddingTop: '0.8em'}} className="plan-text-col"> 
                        {products &&     
                            <div className="plan-product-row" style={{display:'flex', flexDirection:'row'}}>
                                <Col className="product-summary-container">
                                    <Row className="product-headers-row">
                                        <Col xs="4">
                                            <Label>Nimi</Label>
                                        </Col>
                                        <Col xs="2" className="center-content">
                                            <Label>Yksikkö</Label>
                                        </Col>
                                        <Col xs="2" className="center-content">
                                            <Label>Hinta (€)</Label>
                                        </Col>
                                        <Col xs="2"className="center-content">
                                            <Label>Määrä (á)</Label>
                                        </Col>
                                        <Col xs="2"className="center-content">
                                            <Label>Yhteensä</Label>
                                        </Col>
                                    </Row>
                                    {products && products.map((product, key) => {
                                        return(
                                            <Row key={key} className="product-content-block">
                                                <Col xs="4">
                                                    <div className={key!== 0? "product-divider" : ""}>{product.label}</div>
                                                </Col>
                                                <Col xs="2" className="center-content">
                                                    <div className={key!== 0? "product-divider" : ""}>{product.unit}</div>
                                                </Col>
                                                <Col xs="2" className="center-content">
                                                    <div className={key!== 0? "product-divider" : ""}>{product.price}</div>
                                                </Col>
                                                <Col xs="2" className="center-content">
                                                    <div className={key!== 0? "product-divider" : ""}>{product.amount}</div>
                                                </Col>
                                                <Col xs="2" className="center-content">
                                                    <div className={key!== 0? "product-divider" : ""}>{( product.price * product.amount).toFixed(2)}</div>
                                                </Col>
                                            </Row> 
                                        )
                                    })}
                                    <div className="product-summary-row">
                                        {showSummary && 
                                            <Row>
                                            <Col xs="10"> 
                                                <p style={{ margin:0 }}>Yhteensä: </p>
                                            </Col>
                                            <Col xs="2">
                                                <p style={{ margin:0 }}><b>{totalAmount}€</b></p>
                                            </Col>
                                            </Row>
                                        }
                                    </div>
                                </Col>
                            </div>
                        }  
                    </Col>
                </Row>
            </>
        :
        null
    )

}