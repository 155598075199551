import React, { useEffect } from 'react'
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'

import Plan from './modules/Plan'
import * as ActionCreator from '../../store/actions/planActions/actionCreator'



class PlanHandler extends React.Component {
  constructor() {
    super()

    this.state = {
      fetchingPlan: true,
      planId: null
    }
  }

  async componentDidMount() {
    if ( typeof this.props.match.params.id !== undefined ) {
      const planId = this.props.match.params.id
      this.setState({ planId: planId })

      const params = {
        planId: planId,
        initial: true
      }

      await this.props.getPlan( params )
    }

    setTimeout( () => {
      this.setState({ fetchingPlan: false })
    }, 1500 )
  }

  getPlanWithPassword = password => {
    return new Promise( async ( resolve ) => {
      const params = {
        planId: this.state.planId,
        password: password
      }

      const passwordCorrect = await this.props.getPlan( params )
      resolve( passwordCorrect )
    })
  }

  render() {
    const { fetchingPlan } = this.state
    const { plan, locals } = this.props

    return (
      <Container fluid className='h-100'>
        { fetchingPlan ? (
          <Modal isOpen={ this.state.fetchingPlan } centered>
            <ModalBody style={ { textAlign: 'center', height: 200 } }>
              <div className="spinner-border text-success" role="status" style={ { width: 50, height: 50, marginTop: 30 } }>
                <span className="sr-only">Loading...</span>
              </div>
              
              <Row style={ { marginTop: 25 } }>
                <Col>
                  <h4>{locals.fetchPlan}</h4>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        ) : (
          <>
            { plan !== null ? (
              <Plan submitPassword={ this.getPlanWithPassword } locals={locals}/>
            ) : (
              <NoPlanAndRedirect history={ this.props.history } locals={locals} />
            )}
          </>
          ) }
      </Container>
    )
  }
}

const NoPlanAndRedirect = props => {
  useEffect( () => {
    setTimeout( () => {
      props.history.push('/')
    }, 5000)
  })

  return (
    <Row className='h-100' style={ { justifyContent: 'center', alignItems: 'center', textAlign: 'center' } }>
      <Col style={{ backgroundColor: '#fff', padding: '3em' }}>
        <h2>{props.locals.noPlansFound}</h2>
        <p>{props.locals.planRedirect}</p>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    plan: state.plan.selectedPlan
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPlan: ( params ) => dispatch( ActionCreator.getPlan( params ) )
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( PlanHandler )