import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


class BarGraph extends React.Component {
   componentDidMount() {
      if (this.props.data) {
         this.generate();
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
         if (this.props.toggledLegend) {
            this.chart.legend.width = 150
            this.chart.legend.show();
         } else {
            this.chart.legend.width = 0
            this.chart.legend.hide();
         }
      }
   }

   generate = () => {
      let chart = am4core.create(`bar-chart- + ${this.props.i}`, am4charts.XYChart);
      
      let { data } = this.props
      chart.data = data

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      chart.zoomOutButton.width = 30
      chart.zoomOutButton.height = 30
      chart.zoomOutButton.y = -50
      chart.zoomOutButton.contentAlign = "center"
      chart.zoomOutButton.contentValign = "middle"
      chart.zoomOutButton.icon.height = 30
      chart.zoomOutButton.contentHeight = 30
      chart.zoomOutButton.icon.width = 30
      
      categoryAxis.showOnInit = true
      categoryAxis.dataFields.category = "id";
      categoryAxis.renderer.grid.template.location = 0

      categoryAxis.renderer.minGridDistance = 20
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9

      categoryAxis.renderer.labels.template.maxWidth = 120
      categoryAxis.renderer.labels.template.truncate = true
      categoryAxis.renderer.labels.template.rotation = -45
      categoryAxis.renderer.labels.template.fontSize = "0.7em"
      categoryAxis.renderer.labels.template.tooltipText = "{category}"
      categoryAxis.tooltip.label.maxWidth = 240
      categoryAxis.tooltip.label.wrap = true;
      categoryAxis.renderer.maxColumns = 3

      categoryAxis.renderer.labels.template.horizontalCenter = "right"
      categoryAxis.renderer.labels.template.verticalCenter = "middle"

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.title.fontSize = '0.8em'
      valueAxis.title.fontWeight = 'bold'
      valueAxis.paddingLeft = 5
      valueAxis.paddingRight = 5
      valueAxis.title.text = this.props.yTitle

      let valueAxisTwo = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxisTwo.title.fontSize = '0.8em'
      valueAxisTwo.title.fontWeight = 'bold'
      valueAxisTwo.renderer.opposite = true;
      valueAxisTwo.renderer.inside = true
      valueAxisTwo.paddingTop = 10
      valueAxisTwo.paddingBottom = 10
      valueAxisTwo.title.text = this.props.xTitle

      chart.scrollbarX = new am4core.Scrollbar()
      chart.scrollbarX.align = "center"
      chart.scrollbarX.width = am4core.percent(100)
      chart.scrollbarX.height = 10
      chart.scrollbarX.isMeasured = true
      chart.scrollbarX.opacity = 0.5
      chart.scrollbarX.padding(0, 20, 0, 0)

      chart.zoomOutButton.background.cornerRadius(50, 50, 50, 50);
      chart.responsive.enabled = true;
      // Create seriesc
      this.chart = chart
      
      let names = this.props.barNames

      if (names) {
         names.map((name, i) => {
            return this.createSeries(name, name, false)
         })
      }


      // data.map((dataItem, i) => {
      //    Object.keys(dataItem).map(key => {
      //       let item = dataItem[key]
      //       return this.createSeries(dataItem[key], dataItem[key], false);
      //    })
      // })


      // this.createSeries("now", "Now", false);
      // this.createSeries("before", "Before", false)



      // Add legend
      chart.legend = new am4charts.Legend()
      chart.legend.position = 'right'
      chart.legend.width = 0
      chart.legend.scrollable = true
      chart.legend.fontSize = '0.7em'
      chart.legend.hide()
   }

   createSeries = (field, name, stacked) => {
      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = field
      series.dataFields.categoryX = "id"
      series.name = name
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]"
      series.columns.template.width = am4core.percent(95)
      series.showOnInit = true

   }

   componentWillUnmount() {
      if (this.chart) { 
         this.chart.dispose()
      }
   }

   render() {
      return (
         <div style={{ height: '100%' }} id={`bar-chart- + ${this.props.i}`}></div>
      )
   }

}


export default BarGraph;