import { NotificationManager } from 'react-notifications';
import * as ActionTypes from './actionTypes';
import { confs } from '../../../bootstrap/config';

const onLogin = obj => {
  return {
    type: ActionTypes.LOGIN,
    payload: obj
  }
}

export const login = (user) => dispatch => {
  let loginUrl

  if(user.loginToBeta) {
    loginUrl = confs.beta_host + 'login'
  } else {
    loginUrl = confs.url + 'login'
  }

  fetch(loginUrl, {
    method: 'POST',
    body: JSON.stringify(user)
  })
  .then(res => res.json())
  .then(data => {
    let obj = {
      loginEmail: user.email,
      loginSuccessful: data.success,
      clientType: data.clientType
    }
    if(!data.success) {
      NotificationManager.error('Kirjautuminen epäonnistui', 'Ilmoitus', 3000);
    } else {
      if( !data.loginSuccess ) {
        NotificationManager.success('Kirjautuminen onnistui. Saat piakkoin sähköpostiisi kirjautumiskoodin, jolla pääset eteenpäin.', 'Hienoa!', 5000)
      }
    }

    if(data.loginSuccess) {
      dispatch(onCodeLogin(data))
    } else {
      dispatch(onLogin(obj))
    }
  })
}

export const register = (company) => dispatch => {
  fetch(confs.url + 'register', {
    method: 'POST',
    body: JSON.stringify(company)
  })
  .then(res => res.json())
  // .then(data => console.log(data))
}

const codeLoginInProgress = (data) => {
  return {
    type: ActionTypes.CODE_LOGIN_PROGRESS,
    payload: data
  }
}
const onCodeLogin = (data) => {
  return {
    type: ActionTypes.CODE_LOGIN,
    payload: data
  }
}

export const codeLogin = (codeObj) => dispatch => {
  dispatch(codeLoginInProgress({codeLoginInProgress: true}));
  return fetch(confs.url + 'codeLogin', {
    method: 'POST',
    body: JSON.stringify(codeObj)
  })
  .then(res => res.json())
  .then(data => {
    if(!data.codeLoginSuccess) {
      NotificationManager.error('Väärä koodi, kirjautuminen epäonnistui', 'Ilmoitus', 3000);
      data.codeLoginInProgress = false;
    }
    dispatch(onCodeLogin(data));
  })
}

export const firstLogin = (loginData) => dispatch => {
  return fetch(confs.url + 'firstLogin', {
    method: 'POST',
    body: JSON.stringify(loginData)
  })
  .then(res => res.json())
  .then(data => {
    if(data.success === true) {
      let obj = {
        loginEmail: data.email,
        loginSuccessful: false,
        clientType: data.clientType
      }
      dispatch(onLogin(obj))
    }
    return data;
  })
}

const setFirstLoginTokenValidity = params => {
  return {
    type: ActionTypes.FIRST_LOGIN_ISVALID,
    payload: params
  }
}

export const checkFirstLoginToken = (token) => dispatch => {
  if(token !== null) {
    return fetch(confs.url + 'checkFirstLoginToken', {
      method: 'POST',
      body: JSON.stringify({token: token})
    })
    .then(res => res.json())
    .then(data => {
      if(data.success === false) {
        NotificationManager.error('Koodisi on vanhentunut tai se on väärin.', 'Ilmoitus', 3000);
      }   
      dispatch(setFirstLoginTokenValidity({firstLoginTokenIsValid: data.success}))
    })
  } else {
    dispatch(setFirstLoginTokenValidity({firstLoginTokenIsValid: false}))
  }
}

export const onPasswordForgotten = (email) => dispatch => {
  return fetch(confs.url + 'passwordForgotten', {
    method: 'POST',
    body: JSON.stringify({email: email})
  })
  .then(res => res.json())
  .then(data => {
    NotificationManager.success('Ohjeet salasanan vaihtoon on lähetetty antamaasi sähköpostiosoitteeseen', 'Ilmoitus', 3000);
  })
}