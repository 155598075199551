import React, { useState } from 'react';
import {Form, FormGroup, Label, Input} from 'reactstrap'
import './checklist.css'

export const TrelloChecklistContainer = ({checklist}) => {
    const {name, checkItems} = checklist
    
    return (
        <div style={{marginBottom: 24}}>
            <div style={{display: 'flex'}}>
                <i className="far fa-check-square" style={{paddingRight: '1.5em'}}></i>
                <h6>{name}</h6>
            </div>
            {checklist && <ProgressBar completed={checkItems.filter(x => x.state !== 'incomplete').length} itemCount={checkItems.length}/>}
            {checklist && <Checklist checkItems={checkItems} />}
        </div>
    )
}

const ProgressBar = ({completed, itemCount}) => {
    return (
        <div className="checklist-progress">
            <span className="percent">{completed / itemCount * 100}%</span>
            <div className="progress-bar-trello">
                <div className={completed === itemCount ? "progress-bar-progress progress-bar-current-complete": "progress-bar-progress"} style={{width: completed / itemCount * 100 + '%'}}></div>
            </div>
        </div>
    )
}

const Checklist = ({checkItems}) => {
    return (
        <div className="checklist-items-list">
            {checkItems.map(checkItem => 
                <CheckItem checkItem={checkItem} key={checkItem.id} />
            )}
        </div>
    )
}

const CheckItem = ({checkItem}) => {
    return (
        <div className="checkItem-main-row">
            <div style={{minWidth: '1.1rem'}}>
                <Input type="checkbox" id="checkbox2" style={{marginLeft: 0}} checked={checkItem.state === "complete"} onChange={() => { return }} disabled/>{'   '}
            </div>
            <div style={{paddingLeft: '1.25rem'}} className={checkItem.state === "complete" ? "checklist-item-state-complete" : ""}>
                <div className="checklist-item-row"><span className="checklist-item-details-text">{checkItem.name}</span></div>
            </div>
        </div>
    )
}